/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from "react";
import Dropdown from "../Dropdown";
import "./auction.css";
import {
  Header,
  SearchableListPicker,
  SearchAbleRangePicker,
  CheckBoxList,
} from "./components"; // libraries
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Labels from "../../services/apiLabels";
import { GET } from "../../constant/apiMethods";
import {
  SET_FILTER,
  SET_SELECTED_FILTER,
  PRODUCT_LIST,
  SET_SEARCH_QUERY_FILTER,
  CLEAR_ALL_SELECTED_FILTER,
} from "../../constant/index";

function Auctionlist(props) {
  const {
    handleDrawer,
    filtersData,
    selectedFilters,
    setSelectedFilters,
    setSelectedQueryFilters,
    filtersQuery,
    filter,
    products,
    getProductList,
    clearAllSelectedFilters,
  } = props;
  const history = useHistory();
  const { search } = useLocation();
  // const [selectedFilters, setFilter] = useState({
  //   pick_up_location: "",
  //   registered_city: "",
  //   make: "",
  //   model: "",
  //   mileage: { from: "", to: "" },
  //   price: { from: "", to: "" },
  //   year: { from: "", to: "" },
  //   engine_size: { from: "", to: "" },
  // });
  const [totalAppliedFilters, setTotalAppliedFilters] = useState(0);
  useEffect(() => {
    if (search && products.data.length < 1) {
      let search_ = search[0] === "?" ? search.substring(1) : search;
      getProductListHandler(search_);
      setSelectedQueryFilters(search);
    }
    return () => {};
  }, []);
  const setTotalAppliedFiltersHandler = () => {
    let array = Object.values(selectedFilters);

    var filtered = array.filter(function (e) {
      return e != null && e != undefined && e != "";
    });
    setTotalAppliedFilters(filtered.length);
  };
  
  
  

  
  const getProductListHandler = (query = filtersQuery) => {
    
    let params = {
      history,
      query,
    };
    getProductList(params);
  };
  const setSelectedFiltersHandler = (e) => {
    setSelectedFilters(e);
    // getProductListHandler();
  };
  // useEffect(() => {
  //   if (products.data.length < 1 || selectedFilters) {
  //     getProductListHandler();
  //   }
  // }, [filtersQuery]);
  useMemo(() => {
    if (selectedFilters) {
      getProductListHandler();
      setTotalAppliedFiltersHandler();
    }
    return () => {};
  }, [filtersQuery]);
  return (
    <div className="w-100 position-relative">
      <Header
        totalAppliedFilters={totalAppliedFilters}
        clearAll={() => clearAllSelectedFilters()}
        handleDrawer={handleDrawer}
      />
      {/* Pick-up location */}
      <div className="auction_dropdown_wrapper ">
        <Dropdown placeholder="Pick-up location" hideDivider>
          <SearchableListPicker
            componentClassId={0}
            data={filtersData.pickUpLocation}
            callBack={(e) => setSelectedFiltersHandler({ pickUpLocation: e })}
            value={selectedFilters.pickUpLocation}
          />
        </Dropdown>
      </div>
      {/* Registered city */}
      <div className="auction_dropdown_wrapper ">
        <Dropdown placeholder="Registered city" hideDivider>
          <SearchableListPicker
            componentClassId={1}
            data={filtersData.registeredCity}
            callBack={(e) => setSelectedFiltersHandler({ registeredCity: e })}
            value={selectedFilters.registeredCity}
          />
        </Dropdown>
      </div>
      {/* Make & model */}
      <div className="auction_dropdown_wrapper ">
        <Dropdown placeholder="Make & model" hideDivider>
          <div>
            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#tabs-1"
                  role="tab"
                >
                  Make
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="tab"
                  href="#tabs-2"
                  role="tab"
                >
                  Model
                </a>
              </li>
            </ul>
            {/* Tab panes */}
            <div className="tab-content">
              <div className="tab-pane active pt-3" id="tabs-1" role="tabpanel">
                <SearchableListPicker
                  componentClassId={2}
                  data={filtersData.make}
                  callBack={(e) => setSelectedFiltersHandler({ make: e })}
                  value={selectedFilters["make"]}
                />
              </div>
              <div className="tab-pane" id="tabs-2" role="tabpanel">
                <button className="p-1 m-1 bg_yellow">2019</button>
              </div>
            </div>
          </div>
        </Dropdown>
      </div>
      {/* mileage */}
      <div className="auction_dropdown_wrapper ">
        <Dropdown placeholder="mileage" hideDivider>
          <SearchAbleRangePicker
            value={{
              min: selectedFilters.minMileage,
              max: selectedFilters.maxMileage,
            }}
            minCallBack={(e) => setSelectedFiltersHandler({ minMileage: e })}
            maxCallBack={(e) => setSelectedFiltersHandler({ maxMileage: e })}
          />
        </Dropdown>
      </div>
      {/* price */}
      <div className="auction_dropdown_wrapper ">
        <Dropdown placeholder="price" hideDivider>
          <SearchAbleRangePicker
            value={{
              min: selectedFilters.minPrice,
              max: selectedFilters.maxPrice,
            }}
            minCallBack={(e) => setSelectedFiltersHandler({ minPrice: e })}
            maxCallBack={(e) => setSelectedFiltersHandler({ maxPrice: e })}
          />
        </Dropdown>
      </div>
      {/* year */}
      <div className="auction_dropdown_wrapper ">
        <Dropdown placeholder="year" hideDivider>
          <SearchAbleRangePicker
            value={{
              min: selectedFilters.minYear,
              max: selectedFilters.maxYear,
            }}
            minCallBack={(e) => setSelectedFiltersHandler({ minYear: e })}
            maxCallBack={(e) => setSelectedFiltersHandler({ maxYear: e })}
          />
        </Dropdown>
      </div>
      {/* Gearbox & Engine */}
      <div className="auction_dropdown_wrapper ">
        <Dropdown placeholder="Gearbox & Engine" hideDivider>
          <div className="dropdown_item_heading">gearbox</div>
          <CheckBoxList data={["Manual", "Automatic"]} />
          <div className="dropdown_item_heading">engine size</div>
          <SearchAbleRangePicker
            value={{
              min: selectedFilters.minEngineSize,
              max: selectedFilters.maxEngineSize,
            }}
            minCallBack={(e) => setSelectedFiltersHandler({ minEngineSize: e })}
            maxCallBack={(e) => setSelectedFiltersHandler({ maxEngineSize: e })}
          />
        </Dropdown>
      </div>
      {/* Color */}
      <div className="auction_dropdown_wrapper ">
        <Dropdown placeholder="Color" hideDivider>
          colors
        </Dropdown>
      </div>
      {/* Fuel & Efficiency */}
      <div className="auction_dropdown_wrapper ">
        <Dropdown placeholder="Fuel & Efficiency" hideDivider>
          <div className="dropdown_item_heading">Fuel Type</div>
          <CheckBoxList
            data={["petrol", "diesel", "electric", "hybrid", "plug-in hybrid"]}
          />
        </Dropdown>
      </div>
      {/* Body Type */}
      {/* <div className="auction_dropdown_wrapper ">
        <Dropdown placeholder="Body Type" hideDivider>
          slfjsdljkfj
        </Dropdown>
      </div> */}
      {/* Drive Type       */}
      <div className="auction_dropdown_wrapper ">
        <Dropdown placeholder="Drive Type" hideDivider>
          <CheckBoxList
            data={["Four wheel drive", "Front wheel drive", "Rear wheel drive"]}
          />
        </Dropdown>
      </div>
      {/* Doors & Seats*/}
      <div className="auction_dropdown_wrapper ">
        <Dropdown placeholder="Doors & Seats" hideDivider>
          <div className="dropdown_item_heading">doors</div>
          <CheckBoxList data={["2-3 doors", "4-5 doors"]} />
          <div className="dropdown_item_heading">Seats</div>
          <CheckBoxList data={["2 seats", "4 seats", "5 seats", "7 seats"]} />
        </Dropdown>
      </div>
      {/* Features*/}
      {/* <div className="auction_dropdown_wrapper ">
        <Dropdown placeholder="Features" hideDivider>
          <CheckBoxList
            data={[
              "Active cruise control",
              "Air con",
              "Alloys",
              "Alloys",
              "CD Player",
              "Cruise control",
              "DAB",
            ]}
          />
        </Dropdown>
      </div> */}
      {/* ----- */}
      <div style={{ height: "6vh", backgroundColor: "var(--info)" }} />
      <div className=" filter_bar_mobile_footer">
        <button
          onClick={handleDrawer}
          className="filter_bar_mobile_footer_button "
        >
          Show Results
        </button>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  products: state.product,
  filter: state.filters,
  filtersData: state.filters.data,
  selectedFilters: state.filters.selected,
  filtersQuery: state.filters.queryString_,
});
const mapDispatchToProps = (dispatch) => ({
  setSelectedFilters: (data) =>
    dispatch({
      type: SET_SELECTED_FILTER,
      payload: data,
    }),
  clearAllSelectedFilters: (data) => {
    dispatch({
      type: CLEAR_ALL_SELECTED_FILTER,
      payload: data,
    });
  },
  setSelectedQueryFilters: (data) =>
    dispatch({
      type: SET_SEARCH_QUERY_FILTER,
      payload: data,
    }),
  // // get product list without filters
  getProductList: (params) =>
    dispatch({
      type: PRODUCT_LIST,
      payload: {
        type: PRODUCT_LIST,
        method: GET,
        label: `${Labels.product_list}?${params.query}`,
        hideOverlayLoader: true,
        params,
      },
    }),
  // //
  // get product list with filters
  // getProductList: (params) =>
  //   dispatch({
  //     type: PRODUCT_LIST,
  //     payload: {
  //       type: PRODUCT_LIST,
  //       method: GET,
  //       label: `${Labels.filter}?${params.query}`,
  //       hideOverlayLoader: true,
  //       params,
  //     },
  //   }),
  //
});
export default connect(mapStateToProps, mapDispatchToProps)(Auctionlist);
