import React, { useState, useEffect } from "react";
import "./FormComponent.css";
import Form from "../../components/Form/index";
import { Breadcrumb } from "antd";
import { Link, useHistory } from "react-router-dom";
import { HiChevronRight, HiCheck } from "react-icons/hi";
import LandingSection from "../../components/LandingSection";
import { ShimmerThumbnail } from "react-shimmer-effects";

import {
  SHOW_INTEREST,
  SHOW_INTEREST_STEPS,
  SHOW_INTEREST_STEPS_CURRENT,
  GET,
  POST,
} from "../../constant";
//
import { connect, useSelector } from "react-redux";
import Labels from "../../services/apiLabels";
import Progress from "../../components/Progress";
//
import { FaSearch, FaCheck } from "react-icons/fa";
import { API_ENDPOINT } from "../../services/api";
import { Loader } from "semantic-ui-react";
import { formateKey } from "../../utils/formating";
import { useDispatch } from "react-redux";
import { useIsRTL } from "react-bootstrap/esm/ThemeProvider";

//
//
const getdata = async (label) => {
  
  const parameters = {
    GET,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await fetch(API_ENDPOINT(label), parameters)
    .then((response) => {
      
      return response.json();
    })
    .then((json) => {
      
      return json;
    });
};
//
//
function FormComponnt(props) {
  const {
    showInterest,
    name,
    nextName,
    setcurrentStep,
    prevName,
    postvendorinterest,
    userId,
    loadingBrands,
    loadingShowInterest,
  } = props;
  const [gettingData, setgettingData] = useState(0);
  const [current_item, setcurrent_item] = useState(-1);
  const titltKey = name === "brands" ? "brand_name" : "display";
  const idKey = name === "brands" ? "id" : "value";
  //
  const { data, selected, isSelected, loading } = showInterest[name];
  //
  const history = useHistory();
  const step1 = useSelector((state) => state.sellMyCar.step1);
  //
  

  const formanimationClassname = "animate__animated animate__backInRight";
  
  //
  const dispatch = useDispatch();
  const [searchQuery, setsearchQuery] = useState("");
  const [searchResult, setsearchResult] = useState();
  const setsearchQueryHandler = (query = "") => {
    if (query) {
      setsearchQuery(query);
      let query_ = query.toLowerCase();
      let arr = [].concat.apply([], Object.values(data));
      // console.log();
      let filteredArr = arr.filter((element) => {
        let element_ = element[titltKey].toLowerCase();

     
        return element_.includes(query_);
      });
      setsearchResult(filteredArr);
    } else {
      setsearchQuery("");
      setsearchResult([]);
    }
  };

  //
  const Sections = ({ heading, data }) => {
    
    return (
      <div className="dropdown_wrapper">
        <div className=" formComponent_searchText formComponent_listSectionHeading">
          {formateKey(heading)}
        </div>
        {data.length > 0
          ? data.map((item, index) => (
              <List heading={heading} item={item} index={index} />
            ))
          : null}
      </div>
    );
  };
  const List = ({ item, heading, index }) => {
    
   
    const [loading, setloading] = useState(false);
    if (item) {
      // onselect item handler call api and get relevant modals
      const selectItem = async (item) => {
        if (gettingData == 0) {
          setgettingData(1);
          setcurrent_item(index);
          let obj = showInterest[name];
          let selected = [...obj.selected, item];
          if (nextName) {
            let nextObj = showInterest[nextName];
            try {
              setloading(true);

              const data = await getdata(
                `${Labels[`car_${nextName}`]}${item[idKey]}/`
              );
              // // update current obj

              setloading(false);

              dispatch({
                type: SHOW_INTEREST_STEPS,
                payload: {
                  type: name,
                  data: {
                    selected,
                    isSelected: true,
                  },
                },
              });
              // // update next obj data
              dispatch({
                type: SHOW_INTEREST_STEPS,
                payload: {
                  type: nextName,
                  data: {
                    data: {
                      ...nextObj.data,
                      [`${name !== "brands" ? heading + "," : ""} ${
                        item[titltKey]
                      }`]: data,
                    },
                  },
                },
              });
            } catch (error) {
              // setgettingData(false);
              setloading(false);

         
            }
          } else {
            // // update current obj
            dispatch({
              type: SHOW_INTEREST_STEPS,
              payload: {
                type: name,
                data: {
                  selected,
                  isSelected: true,
                },
              },
            });
          }
          setgettingData(0);
        }

        // setgettingData(false);
      };
      //
      const unSelectItem = (item) => {
        let obj = showInterest[name];
        let selected = obj.selected.filter(
          (element) => element[idKey] != item[idKey]
        );
        if (nextName) {
          let nextObjData = { ...showInterest[nextName].data };
          delete nextObjData[item[titltKey]];

          //   update data of next obj
          dispatch({
            type: SHOW_INTEREST_STEPS,
            payload: {
              type: nextName,
              data: {
                data: nextObjData,
              },
            },
          });
        } //   remove current obj selected item
        dispatch({
          type: SHOW_INTEREST_STEPS,
          payload: {
            type: name,
            data: {
              selected,
              isSelected: selected.length > 0,
            },
          },
        });
      };
      //
      let obj = showInterest[name];
      let listSelected = obj.selected.indexOf(item) > -1;
      let func = listSelected ? unSelectItem : selectItem;
      return (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <button
            onClick={() => func(item)}
            className="formComponent_searchText formComponent_sectionListCont"
          >
            <span style={{ flex: 1, textAlign: "left" }}>{item[titltKey]}</span>
            {listSelected && !loading ? (
              <FaCheck color="var(--primary)" size={20} />
            ) : null}
            {loading ? <Loader active inline size="mini" /> : null}
          </button>
          <div className="formComponent_sectionListDivider" />
        </div>
      );
    }

    return <></>;
  };
  //
  const submitFormData = () => {
    let selected = showInterest[name].selected;
    let carArr = [];
    for (let i = 0; i < selected.length; i++) {
      let obj = selected[i];
      carArr.push({ id: obj[idKey] });
    }
    postvendorinterest({
      user: userId,
      selectedcars: carArr,
    });
    // // console.log("postvendorinterest data", {
    //   user: userId,
    //   selectedcars: carArr,
    // });
  };
  //
  const formButtonHandler = () => {
    if (showInterest[name].isSelected && nextName) setcurrentStep(nextName);
    if (!nextName) submitFormData();
  };
  //
  if (loadingBrands)
    return (
      <div className="shimer_showintest mt-5 p-5">
        <ShimmerThumbnail height={30} rounded />
        <ShimmerThumbnail height={30} rounded />
        <ShimmerThumbnail height={30} rounded />
        <ShimmerThumbnail height={30} rounded />
        <ShimmerThumbnail height={30} rounded />
      
      </div>
    );
  return (
    <>
      <Form
        loading={loadingShowInterest}
        className={`${formanimationClassname} showInterest_form_wrapper`}
        loadingText="Submitting Interest ..."
        title={`Select Cars ${formateKey(name)}`}
        onSubmit={formButtonHandler}
        buttonText="Next"
        buttonClassName="showInterest_form_button"
        childClassName="showInterest_form_child"
        onBack={prevName ? () => setcurrentStep(prevName) : null}
      >
        <div className="dropdown_wrapper">
          {/* searchable input */}
          <div className="formComponent_searchCont">
            <FaSearch className="formComponent_searchText" />
            <input
              value={searchQuery}
              onChange={(e) => setsearchQueryHandler(e.target.value)}
              placeholder="Search"
              className="formComponent_searchText"
            />
          </div>
          {/*  */}
          {searchQuery ? (
            searchResult.length > 0 ? (
              searchResult.map((item, index) => <List item={item} />)
            ) : (
              <h5>nothing to show</h5>
            )
          ) : Object.keys(data).length > 0 ? (
            Object.keys(data).map((item, index) => (
              <Sections data={data[item]} heading={item} />
            ))
          ) : (
            <h5>nothing to show</h5>
          )}
        </div>
      </Form>
    </>
  );
}

const mapStateToProps = (state) => ({
  loadingShowInterest: state.showInterest.loading,
  loadingBrands: state.showInterest.loadingBrands,
  showInterest: state.showInterest,
  userId: state.auth.id,
});
const mapDispatchToProps = (dispatch) => ({
  setcurrentStep: (data) =>
    dispatch({
      type: SHOW_INTEREST_STEPS_CURRENT,
      payload: data,
    }),
  postvendorinterest: (data) =>
    dispatch({
      type: SHOW_INTEREST,
      payload: {
        type: SHOW_INTEREST,
        data,
        label: Labels.vendorinterest,
        method: POST,
        hideOverlayLoader: true,
      },
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormComponnt);
