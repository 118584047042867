import { useState, React, useEffect } from "react";
import Slider from "react-animated-slider";
import { useLocation, useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import "./style.css";
import { ImCross } from "react-icons/im";
import Step8 from "../Evaluation/Step8";
import { FaEdit } from "react-icons/fa";
import { Button, Collapse } from "react-bootstrap";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Footer from "../../components/Footer";
import { styled } from "@mui/material/styles";

import IconButton from "@mui/material/IconButton";
import { scrollTop } from "../../utils/window";
import { useContext } from "react";
import FeedbackContext from "../../context/cmscontext";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function Myappointmentdetail({ mycardata, appointment_update }) {
   const { feedback, isLoading } = useContext(FeedbackContext);
  var requiredDocuments = feedback.requridocuments_page;
  const [userId, setuserId] = useState("");

  const [expandeda, setExpandeda] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [expanded_a, setExpanded_a] = useState(false);
  const [expanded_b, setExpanded_b] = useState(false);
  const [expanded_c, setExpanded_c] = useState(false);

  const handleExpandClickA = () => {
    setExpandeda(!expandeda);
  };
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleExpandClick_a = () => {
    setExpanded_a(!expanded_a);
  };
  const handleExpandClick_b = () => {
    setExpanded_b(!expanded_b);
  };
  const handleExpandClick_c = () => {
    setExpanded_c(!expanded_c);
  };

  const [open, setOpen] = useState(false);

  const [showResults, setShowResults] = useState(false);

  return (
    <>
      {scrollTop()}

      <div className="showInterest_headingCont ">
        <div className="showInterest_heading">
          {requiredDocuments.Bringing_Documents_heading}
        </div>
      </div>

      <div>
        <div className=" w-100 span-1 my-3">
          <div className="container pt-3">
            <div
              className="d-flex justify-content-end curor_pointer "
              onClick={handleExpandClickA}
            >
              <h3 className="mt-3">
                {requiredDocuments.I_own_my_vehicle_heading}
              </h3>
              <ExpandMore
                expand={expandeda}
                onClick={handleExpandClickA}
                aria-expanded={expandeda}
                aria-label="show more"
              >
                <ExpandMoreIcon fontSize="large" style={{ fill: "white" }} />
              </ExpandMore>
            </div>
            <hr></hr>

            <Collapse in={expandeda}>
              <div>
                <ul>
                  <li>{requiredDocuments.I_own_my_vehicle_doc_1}</li>
                  <li>{requiredDocuments.I_own_my_vehicle_doc_2}</li>
                  <li>{requiredDocuments.I_own_my_vehicle_doc_3}</li>
                  <li>{requiredDocuments.I_own_my_vehicle_doc_4}</li>
                </ul>
              </div>
            </Collapse>

            <div
              className="d-flex justify-content-end curor_pointer"
              onClick={handleExpandClick}
            >
              <h3 className="mt-3">
                {requiredDocuments.some_money_on_my_vehicle_heading}
              </h3>
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon fontSize="large" style={{ fill: "white" }} />
              </ExpandMore>
            </div>
            <hr></hr>

            <Collapse in={expanded}>
              <div>
                <ul>
                  <li>{requiredDocuments.I_own_my_vehicle_doc_1}</li>
                  <li>{requiredDocuments.I_own_my_vehicle_doc_2}</li>
                  <li>{requiredDocuments.I_own_my_vehicle_doc_3}</li>
                  <li>{requiredDocuments.I_own_my_vehicle_doc_4}</li>
                </ul>
              </div>
            </Collapse>

            <div
              className="d-flex justify-content-end curor_pointer"
              onClick={handleExpandClick_a}
            >
              <h3 className="mt-3">
                {requiredDocuments.vehicle_belonging_to_company_heading}
              </h3>
              <ExpandMore
                expand={expanded_a}
                onClick={handleExpandClick_a}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon fontSize="large" style={{ fill: "white" }} />
              </ExpandMore>
            </div>
            <hr></hr>

            <Collapse in={expanded_a}>
              <div>
                <ul>
                  <li>
                    {requiredDocuments.vehicle_belonging_to_company_doc_1}
                  </li>
                  <li>
                    {requiredDocuments.vehicle_belonging_to_company_doc_2}
                  </li>
                  <li>
                    {requiredDocuments.vehicle_belonging_to_company_doc_3}
                  </li>
                  <li>
                    {requiredDocuments.vehicle_belonging_to_company_doc_4}
                  </li>
                </ul>
              </div>
            </Collapse>

            <div
              className="d-flex justify-content-end curor_pointer"
              onClick={handleExpandClick_b}
            >
              <hr></hr>
              <h3>{requiredDocuments.behalf_of_friend_heading}</h3>
              <ExpandMore
                expand={expanded_b}
                onClick={handleExpandClick_b}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon fontSize="large" style={{ fill: "white" }} />
              </ExpandMore>
            </div>
            <hr></hr>

            <Collapse in={expanded_b}>
              <div>
                <ul>
                  <li>{requiredDocuments.behalf_of_friend_doc_1}</li>
                  <li>{requiredDocuments.behalf_of_friend_doc_2}</li>
                  <li>{requiredDocuments.behalf_of_friend_doc_3}</li>
                  <li>{requiredDocuments.behalf_of_friend_doc_4}</li>
                </ul>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  mycardata: state.myAppointment.data,
  appointment_update: state.sellMyCar,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Myappointmentdetail);
