import React, { Fragment, useState, useEffect } from "react";
import { AiTwotoneEye, AiOutlineClockCircle } from "react-icons/ai";
import { BsDot } from "react-icons/bs";
import { GoLocation } from "react-icons/go";
import "./produ.css";
import "./GridCard.css";
import pic2 from "../../assets/svg/car_no_image.jpg";
// import GridCardShimmer from "./GridCardShimmer";
import ProductMain from "../Product View/ProductMain";
import { Card, Container, Row } from "react-bootstrap";
import WishListButton from "../Product list/WishListButton";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
} from "react-router-dom";

function Car_buy_card({Products}) {



  // if (loading) {
  //   return <Grid data={[{}, {}]} Card={GridCardShimmer} />;
  // }
  // console.log("chk product id ===xxxxxx===11111===>>>>>>",Products)
  // console.log("chk product id ==========>......222222222...",Products.product_id)



  return (




    
    <div className="product_grid_card_main_cont_div">
    <WishListButton />

     <Link
      excat
      to={{
        pathname: "/productbuy",
        state: { product_code: Products.product_id },
      }}
      className="imageHover product_grid_card_main_cont"
    > 
      <div className="">
        <div className="product_list_card_image_wrapper">
          <img
            className="product_list_card_image"
            src={Products.main_image}
            alt="banner"
          />
        </div>
      </div>
      {/* body section */}
      <div className="product_grid_card_body_cont">
        <span className="product_list_card_title">
         Gli Car New Model
        </span>
        <div className="mt-2">
      
        {Products.product_feature
              ? Products.product_feature.slice(0, 3).map((item, index) => (
                  <span className="product_list_card_features_text">
                    {item.name}: {item.value}
                  </span>
                ))
              : null}
           
        </div>
     
      </div>
    </Link>
  </div>
  )
}

export default Car_buy_card