/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable default-case */
// importing types from constants definded in constant/ index file
import {
  /*SET_FILTER,*/ SET_SELECTED_FILTER,
  SET_SEARCH_QUERY_FILTER,
  CLEAR_ALL_SELECTED_FILTER,
} from "../constant/index";
import queryString from "query-string";
const INITIAL_STATE = {
  data: {
    pickUpLocation: [
      "karachi",
      "lahore",
      "sahiwal",
      "Faisalabad",
      "Rawalpindi",
      "Multan",
      "islamabad",
      "bhawalpur",
      "sialkot",
      "okara",
      "rahim yar kahn",
      "peshawar",
      "chakwal",
    ],
    registeredCity: [
      "karachi",
      "lahore",
      "sahiwal",
      "Faisalabad",
      "Rawalpindi",
      "Multan",
      "islamabad",
      "bhawalpur",
      "sialkot",
      "okara",
      "rahim yar kahn",
      "peshawar",
      "chakwal",
    ],
    modal: "",
    make: ["Audi", "BMW", "merdiciesAudi", "corola", "chiron"],
    minMileage: "",
    maxMileage: "",
    minPrice: "",
    maxPrice: "",
    minYear: "",
    maxYear: "",
    gearbox: "",
    engineSize: "",
    color: "",
    fuel_type: "",
    driveType: "",
    numDoors: "",
    numSeats: "",
  },
  selected: {
    pickUpLocation: "",
    registeredCity: "",
    modal: "",
    make: "",
    minMileage: "",
    maxMileage: "",
    minPrice: "",
    maxPrice: "",
    minYear: "",
    maxYear: "",
    gearbox: "",
    minEngineSize: "",
    maxEngineSize: "",
    color: [],
    fuel_type: [],
    driveType: "",
    numDoors: "",
    numSeats: "",
  },
  queryData: {},
  queryString_: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SELECTED_FILTER:
      function clean(obj) {
        for (var propName in obj) {
          if (!obj[propName] || !obj[propName]) {
            delete obj[propName];
          }
        }
        return obj;
      }
      let selected = {
        ...state.selected,
        ...action.payload,
      };

      let queryData = {
        ...state.queryData,
        ...action.payload,
      };
      let queryString_ = queryString.stringify(clean(queryData));

      return {
        ...state,
        selected,
        queryData,
        queryString_,
      };
    case SET_SEARCH_QUERY_FILTER:
      let searchedqueryString = action.payload
        ? action.payload[0] === "?"
          ? action.payload.substring(1)
          : action.payload
        : "";

      return {
        ...state,
        selected: {
          ...state.selected,
          ...queryString.parse(searchedqueryString),
        },
        queryData: {
          ...state.queryData,
          ...queryString.parse(searchedqueryString),
        },
        queryString_: searchedqueryString,
      };
    case CLEAR_ALL_SELECTED_FILTER:
      return INITIAL_STATE;
  }
  return state;
};
