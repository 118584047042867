/**
 * Unifies event handling across browsers
 *
 * - Allows registering and unregistering of event handlers
 * - Injects event object and involved DOM element to listener
 *
 * @author Mark Rolich <mark.rolich@gmail.com>
 */
export default function () {
  "use strict";
  this.attach = function (evtName, element, listener, capture) {
    var evt = "",
      useCapture = capture === undefined ? true : capture,
      handler = null;

    if (window.addEventListener === undefined) {
      evt = "on" + evtName;
      handler = function (evt, listener) {
        element.attachEvent(evt, listener);
        return listener;
      };
    } else {
      evt = evtName;
      handler = function (evt, listener, useCapture) {
        element.addEventListener(evt, listener, useCapture);
        return listener;
      };
    }

    return handler.apply(element, [
      evt,
      function (ev) {
        var e = ev ,
          src = e.srcElement || e.target;

        listener(e, src);
      },
      useCapture,
    ]);
  };

  this.detach = function (evtName, element, listener, capture) {
    var evt = "",
      useCapture = capture === undefined ? true : capture;

    if (window.removeEventListener === undefined) {
      evt = "on" + evtName;
      element.detachEvent(evt, listener);
    } else {
      evt = evtName;
      element.removeEventListener(evt, listener, useCapture);
    }
  };

  this.stop = function (evt) {
    evt.cancelBubble = true;

    if (evt.stopPropagation) {
      evt.stopPropagation();
    }
  };

  this.prevent = function (evt) {
    if (evt.preventDefault) {
      evt.preventDefault();
    } else {
      evt.returnValue = false;
    }
  };
};
