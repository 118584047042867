import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { FeedbackProvider } from "./context/cmscontext";
import 'bootstrap/dist/css/bootstrap.min.css';
// import 'semantic-ui-css/semantic.min.css'
import "animate.css";
import {
  // BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";
import "./App.css";
import { useLocation } from 'react-router-dom'
import { AuthRoute, PrivateRoute, PublicRoute, useAuth } from "./router/index";
import CurveNavbar from "./components/Navbar/CurveNavbar";
import Navbar from "./components/Navbar/Navbar";


import * as Pages from "./pages/index";
import Toaster from "./components/Toaster";
import OverLayLoader from "./components/OverLayLoader";
import Magnifier from "./components/Magnifer";
import Footer from "./components/Footer";
import FooterMini from "./components/Footer Social";
import { get } from "./services/localStorage";
import { LOGIN, LOG_OUT } from "./constant";

// import 'semantic-ui-css/semantic.min.css'
//
function App(props) {


// =======================================This is Client Side Project==================


  const { auth, login, logOut,data } = props;
  const localStorage_auth = () => {
    const localStorage_auth_data = get({ key: "auth" });
    return localStorage_auth_data
      ? localStorage_auth_data.token
        ? localStorage_auth_data
        : {}
      : {};
  };
  //
  useEffect(() => {
    const auth_data = localStorage_auth();
    if (!auth.token && auth_data.token) {
      login(auth_data);
    } else if (auth.token && !auth_data.token) {
      logOut();
    }
  }, [auth]);
  //
  const [navbarDimensions, setnavbarDimensions] = useState({
    width: 0,
    height: 72,
  });

  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  }, [location]);
  // // console.log("Location ",location)
//  console.warn = () => {};
  // console.log = console.warn = console.error = () => {};

  return (
    <>
      <FeedbackProvider data={data}>
        {location.pathname != "/carreport" &&
        location.pathname !== "/contactusmobile" ? (
          <div className="fixed-top">
            <CurveNavbar
              setnavbarDimensions={setnavbarDimensions}
              auth={auth}
            />
          </div>
        ) : null}

        <div
          style={{
            maxWidth: navbarDimensions.width,
            height: navbarDimensions.height,
          }}
        />
        <Switch>
          <Route exact path="/evaluation">
            <Pages.Evaluation navbarDimensions={navbarDimensions} />
          </Route>
          <Route exact path="/contactus">
            <Pages.ContactUs navbarDimensions={navbarDimensions} />
          </Route>
          <Route exact path="/contactusmobile">
            <Pages.ContactUs navbarDimensions={navbarDimensions} />
          </Route>
          <Route exact path="/dostprogram">
            <Pages.DostProgram navbarDimensions={navbarDimensions} />
          </Route>
          <Route exact path="/privacypolicy">
            <Pages.PrivacyPolicy navbarDimensions={navbarDimensions} />
          </Route>
          <Route exact path="/faqs">
            <Pages.FAQs navbarDimensions={navbarDimensions} />
          </Route>
          <Route exact path="/requridocuments">
            <Pages.Documents navbarDimensions={navbarDimensions} />
          </Route>
          <Route exact path="/terms">
            <Pages.Terms navbarDimensions={navbarDimensions} />
          </Route>
          <Route exact path="/aboutus">
            <Pages.AboutUs navbarDimensions={navbarDimensions} />
          </Route>
          <Route exact path="/forgetpassword">
            <Pages.Forget navbarDimensions={navbarDimensions} />
          </Route>
          <Route exact path="/shiping">
            <Pages.Shiping navbarDimensions={navbarDimensions} />
          </Route>
          <Route exact path="/carupdate">
            <Pages.CarUpdate navbarDimensions={navbarDimensions} />
          </Route>
          <Route exact path="/carsteps">
            <Pages.Car_Steps navbarDimensions={navbarDimensions} />
          </Route>
          <Route exact path="/carsteps/:id">
            <Pages.Car_Steps navbarDimensions={navbarDimensions} />
          </Route>

          <Route exact path="/bookappointment">
            <Pages.EvaluationNew navbarDimensions={navbarDimensions} />
          </Route>
          <Route exact path="/bookappointmentnew">
            <Pages.EvaluationAppoitmnt navbarDimensions={navbarDimensions} />
          </Route>

          <Route exact path="/carreport">
            <Pages.CarReport navbarDimensions={navbarDimensions} />
          </Route>

          <Route exact path={`/resetpassword/:id/:token`}>
            <Pages.ChagePassword navbarDimensions={navbarDimensions} />
          </Route>

          <Route exact path="/cardamage">
            <Pages.CarDamage navbarDimensions={navbarDimensions} />
          </Route>
          <Route exact path="/myappointment">
            <Pages.MyAppointment navbarDimensions={navbarDimensions} />
          </Route>
          <Route exact path="/myappointmentupdate">
            <Pages.Appoinmentupdate navbarDimensions={navbarDimensions} />
          </Route>

          <Route exact path="/myappointmentdetail">
            <Pages.MyAppointmentDetail navbarDimensions={navbarDimensions} />
          </Route>
          <Route exact path="/cardetail">
            <Pages.CarDetail navbarDimensions={navbarDimensions} />
          </Route>

          <Route exact path="/">
            <Pages.Landingmain navbarDimensions={navbarDimensions} />
          </Route>
          {/* <Redirect from="/" to="/" /> */}
        </Switch>
        <Toaster />
        <OverLayLoader />
        {/* || location.pathname=="/carupdate" */}
        {location.pathname == "/bookappointment" ||
        location.pathname == "/bookappointmentnew" ||
        location.pathname == "/signup" ||
        location.pathname == "/login" ||
        location.pathname === "/contactusmobile" ? null : (
          <>
            <footer
              style={{
                backgroundColor: "#F2F4F5",
                // borderTop
                borderTopWidth: "1px",
                borderTopStyle: "solid",
                borderTopColor: "black",
              }}
            >
              {/* <hr style="height:0px"/> */}
              <Footer />
              <FooterMini></FooterMini>
            </footer>
          </>
        )}
      </FeedbackProvider>
    </>
  );
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch) => ({
  login: (data) =>
    dispatch({
      type: `${LOGIN}_SUCCESS`,
      payload: { ...data },
    }),
  logOut: (data) =>
    dispatch({
      type: LOG_OUT,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
