import React, { useState } from "react";
import { MdInvertColors } from "react-icons/md";
import "./style.css";
//
export default function Tabs({ data }) {
  const [currentIndex, setcurrentIndex] = useState(0);
  return (
    <div className="w-100">
      <div className="w-100 d-flex flex-row align-items-center tabs_header_wrapper ">
        {React.Children.toArray(data.map((item, index) => {
          return (
            <span
              onClick={() => setcurrentIndex(index)}
              className={`tab ${currentIndex === index ? "activeTab" : ""}`}
            >
              {item.label}
            </span>
          );
        }))}
      </div>
      <div className="w-100 tabs_body_wrapper">{data[currentIndex]["component"]}</div>
    </div>
  );
}
