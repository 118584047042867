export const appName = "WFAC";
export const appFullName = "Sell Any Wheel";
export const appLogo_url = "";
export const appContact_number = "+923030303030";
export const colors = {
  primary: "#c33b2a",
  primaryDark: "#88271d",
  primaryLight: "#ffa49b",
  secondary: "#454444",
  secondary2: "#bfd3c5",
  info: "#f4f6f5",
  info_5: "rgb(204, 204, 204,0.5)",
  // infoPlus: '#e9e9e9',
  infoPlus: "#ccc",
  primaryInfo: "#ffd2cd",
  success: "#16c60c",
  error: "#dd0808",
  warning: "#e0bd0f",
  black: "#000",
  white: "#fff",
};
