import React from "react";
import Form from "../../components/Form";
//
import { connect } from "react-redux";
import Labels from "../../services/apiLabels";
import { SELL_MY_CAR_STEPS, EVALUATE_AUDIO } from "../../constant";
import { FaPlus } from "react-icons/fa";
import { GET, POST } from "../../constant/apiMethods";

import { API_ENDPOINT } from "../../services/api";
import IconInput from "../../components/Form/Input";
//
function SellMyCarstep7(props) {
  const {
    audio,
    setaudio,
    postcreataudio_fail,
    postcreataudio_success,
    postcreataudio_request,
    evaluation,
    createAudioLoading,
    audio_description,
    setaudio_description,
  } = props;
  let audioInputRef = React.createRef();
  //
  const postcreataudio = async (data) => {
    const parameters = {
      method: POST,
      body: data,
    };
    return await fetch(API_ENDPOINT(Labels.sellMyCarAudio), parameters)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        return json;
      });
  };
  //
  const setfilesArrHandler = (e) => {
    const filesObj = audioInputRef.current.files || {};
    const arr = Object.values(filesObj);
    // setaudio([...audio, ...arr]);
    setaudio([...arr]);
    
  };
  //
  const postcreataudioHandler = async () => {
    if (audio.length > 0 && audio_description) {
      const bodyFormData = new FormData();
      for (var i = 0; i < audio.length; i++) {
        bodyFormData.append("audio", audio[i]);
      }
      bodyFormData.append("evaluate_audio", evaluation.id);
      // bodyFormData.append("evaluate_audio", 14);
      bodyFormData.append("description", audio_description);
      //
      try {
        postcreataudio_request();
        const response = await postcreataudio(bodyFormData);
        postcreataudio_success(response);
        
      } catch (error) {
        
      }
      //;
    } else {
      
    }
  };
  
  return (
    <div className="w-100 h-auto d-flex justify-content-center">
      <Form
        width="fluid"
        title="Add Car Audio"
        onSubmit={postcreataudioHandler}
        buttonText="Submit Audios"
        loading={createAudioLoading}
        loadingText="Submitting Audios ..."
      >
        <div className="dropdown_wrapper">
          <IconInput
            loading={createAudioLoading}
            type="text"
            label="Description"
            placeholder="Description"
            onChangeText={setaudio_description}
            value={audio_description}
          />
        </div>
        <div className="dropdown_wrapper d-flex flex-wrap">
          <label
            disabled={audio.length > 0}
            className="imgpiker  cardShadow"
            htmlFor="img"
          >
            <FaPlus color="var(--primary)" size={45} />
          </label>

          <input
            disabled={createAudioLoading}
            type="file"
            id="img"
            name="img"
            accept="audio/*"
            className="d-none"
            ref={audioInputRef}
            onChange={setfilesArrHandler}
            // multiple
          />
          <div className="dropdown_wrapper">
            <div
              className="dropdown_wrapper"
              style={{ fontWeight: "bold", fontSize: "1.4rem" }}
            >
              {audio.length > 0 && audio[0].name}
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}
////////
const mapStateToProps = (state) => ({
  audio: state.sellMyCar.audio.audio,
  audio_description: state.sellMyCar.audio.description,
  evaluation: state.sellMyCar.evaluation_create,
  createAudioLoading: state.sellMyCar.audio.audio_loading,
});
const mapDispatchToProps = (dispatch) => ({
  setaudio: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "audio",
        data,
      },
    }),
  setaudio_description: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "audio_description",
        data,
      },
    }),
  postcreataudio_request: (data) =>
    dispatch({
      type: `${EVALUATE_AUDIO}_REQUEST`,
      payload: data,
    }),
  postcreataudio_success: (data) =>
    dispatch({
      type: `${EVALUATE_AUDIO}_SUCCESS`,
      payload: data,
    }),
  postcreataudio_fail: (data) =>
    dispatch({
      type: `${EVALUATE_AUDIO}_FAIL`,
      payload: data,
    }),
});
export default connect(mapStateToProps, mapDispatchToProps)(SellMyCarstep7);
