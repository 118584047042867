import React, { useState, useEffect } from "react";
import Progress from "../../components/Progress";
import { connect } from "react-redux";
import { SHOW_INTEREST_STEPS } from "../../constant";
import Dropdown from "../../components/Form/Dropdown";
import IconInput from "../../components/Form/Input";
import { set } from "../../services/localStorage";
import { useHistory } from "react-router-dom";
import logo from "../../assets/images/logoc.png";
import Form from "../../components/Form";
import OverLayLoader from "../../components/OverLayLoader";
//
function ShowInterestDetails(props) {
  const {
    step1,
    mileage,
    previous_owners,
    services_history,
    setmileage,
    setprevious_owners,
    setservices_history,
    //
    setuser_name,
    setuser_email,
    setuser_postcode,
    setuser_mobile_number,
    //
    user_name,
    user_email,
    user_postcode,
    user_mobile_number,
  } = props;
  const history = useHistory();
  // checks if step one form is not completed
  // useEffect(() => {
  //   if (!step1["variant"].isSelected) history.push("/showInterest");
  // }, [step1]);
  //
  const [currentStep, setcurrentStep] = useState(2);
  //
  const sideColClassName = "col-lg-3 col-xl-3 	d-none d-lg-block ";
  const formanimationClassname = "animate__animated animate__bounceInDown";
  let formateKey = (text = "") => {
    
    let textArr = text.split("_");
    return textArr.join(" ");
  };
  const List = (props) => {
    const { title, value } = props;
    
    return (
      <div className="d-flex ">
        <span
          className="text-capitalize text-right"
          style={{ margin: "0.2rem 0.5rem", fontWeight: "bold" }}
        >
          {formateKey(title)} :
        </span>
        <span className="text-capitalize" style={{ margin: "0.2rem 0.5rem" }}>
          {value}
        </span>
      </div>
    );
  };
  const renderStep1Info = () => {
    
    return (
      <>
        <div className="d-flex justify-content-center align-items-center flex-column">
          <img src={logo} style={{ width: "10rem", objectFit: "contain" }} />
          <div className="d-flex justify-coneten-center align-items-center align-self-center w-75">
            <span
              style={{
                flex: 1,
                margin: "0.5rem",
                fontWeight: "bold",
                textAlign: "center",
                fontSize: 15,
              }}
            >
              {`${step1["brand"].current.brand_name} - ${step1["variant"].current.display}`}
            </span>
          </div>
          {/* <List title="brand" value={step1["brand"].current.brand_name} /> */}
          <List title="model" value={step1["model"].current.display} />
          <List title="fuel type" value={step1["fuel_type"].current.display} />
          <List title="year" value={step1["year"].current.display} />
          {/* <List title="variant" value={step1["variant"].current.display} /> */}
        </div>
      </>
    );
  };
  // if (!step1["variant"].isSelected) return <OverLayLoader alwaysShow={true} />;
  return (
    <>
      <div
        className="d-flex flex-column d-lg-none justify-content-center align-items-center"
        style={{ margin: "2rem 0" }}
      >
        {currentStep === 2 ? (
          <h3 className="animate__animated animate__lightSpeedInLeft">
            Car Details
          </h3>
        ) : null}
        {currentStep === 3 ? (
          <h3 className="animate__animated animate__lightSpeedInLeft">
            Your Details
          </h3>
        ) : null}
        {renderStep1Info()}
      </div>
      <Progress step={currentStep} totalSteps={4} />
      <div className="container-fluid">
        <div className="row">
          <div className={sideColClassName}>
            <div className="d-flex justify-content-center align-items-center">
              {currentStep === 2 ? (
                <h1 className="animate__animated animate__lightSpeedInLeft">
                  Car Details
                </h1>
              ) : null}
              {currentStep === 3 ? (
                <h1 className="animate__animated animate__lightSpeedInLeft">
                  Your Details
                </h1>
              ) : null}
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 d-flex justify-content-center align-items-center flex-column">
            {/* ============================== step 2 form ============================== */}
            {currentStep === 2 ? (
              <Form
                width="fluid"
                onSubmit={() => setcurrentStep(currentStep + 1)}
                buttonText="Continue"
                className={formanimationClassname}
              >
                {/* mileage */}
                <IconInput
                  label="Mileage"
                  placeholder="mileage"
                  onChangeText={(e) =>
                    setmileage({
                      ...mileage,
                      isSelected: e.length > 0,
                      current: { id: "", title: e },
                    })
                  }
                  value={mileage.current.title}
                />
                {/* previous_owners */}
                <Dropdown
                  label="Previous Owners"
                  onSelect={(item) =>
                    setprevious_owners({
                      ...previous_owners,
                      isSelected: true,
                      current: item,
                    })
                  }
                  selectedValue={previous_owners.current}
                  loading={previous_owners.fetching}
                  disabled={false}
                  data={[
                    { title: 0, id: 0 },
                    { title: 1, id: 1 },
                    { title: 2, id: 3 },
                    { title: 3, id: 4 },
                    { title: 4, id: 5 },
                    { title: "5 or more", id: 6 },
                    { title: "Don't know ", id: 7 },
                  ]}
                />
                {/* services_history */}
                <Dropdown
                  label="Services History"
                  onSelect={(item) =>
                    setservices_history({
                      ...services_history,
                      isSelected: true,
                      current: item,
                    })
                  }
                  selectedValue={services_history.current}
                  loading={services_history.fetching}
                  disabled={false}
                  data={[
                    { title: "Full", id: 0 },
                    { title: "Part", id: 1 },
                    { title: "None", id: 2 },
                    { title: "First not Due", id: 3 },
                  ]}
                />
              </Form>
            ) : null}
            {/* ============================== step 3 form ============================== */}
            {currentStep === 3 ? (
              <Form
                width="fluid"
                onSubmit={() => setcurrentStep(currentStep + 1)}
                buttonText="Get my valuation"
                className={formanimationClassname}
              >
                {/* uesr name */}
                <IconInput
                  label="Your Name"
                  placeholder="Your Name"
                  onChangeText={setuser_name}
                  value={user_name}
                />
                {/* uesr email */}
                <IconInput
                  label="Your Email"
                  placeholder="Your Email"
                  onChangeText={setuser_email}
                  value={user_email}
                />
                {/* uesr postcode */}
                <IconInput
                  label="Your Postcode"
                  placeholder="Your Postcode"
                  onChangeText={setuser_postcode}
                  value={user_postcode}
                />
                {/* uesr mobile_number */}
                <IconInput
                  label="Your Postcode"
                  placeholder="Your Postcode"
                  onChangeText={setuser_mobile_number}
                  value={user_mobile_number}
                />
              </Form>
            ) : null}
            {/* ============================== previous step button ============================== */}
            <button
              onClick={() => {
                let newCurrentStep = currentStep - 1;
                if (newCurrentStep < 2) history.goBack();
                if (newCurrentStep > 0) setcurrentStep(currentStep - 1);
              }}
            >
              Back
            </button>
          </div>
          <div className={sideColClassName}>{renderStep1Info()}</div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({
  step1: state.showInterest.step1,
  mileage: state.showInterest.step2.mileage,
  previous_owners: state.showInterest.step2.previous_owners,
  services_history: state.showInterest.step2.services_history,
  // step 3 user info
  user_name: state.showInterest.user.name,
  user_email: state.showInterest.user.email,
  user_postcode: state.showInterest.user.postcode,
  user_mobile_number: state.showInterest.user.mobile_number,
});
const mapDispatchToProps = (dispatch) => ({
  setmileage: (data) =>
    dispatch({
      type: SHOW_INTEREST_STEPS,
      payload: {
        type: "mileage",
        data,
      },
    }),
  setprevious_owners: (data) =>
    dispatch({
      type: SHOW_INTEREST_STEPS,
      payload: {
        type: "previous_owners",
        data,
      },
    }),
  setservices_history: (data) =>
    dispatch({
      type: SHOW_INTEREST_STEPS,
      payload: {
        type: "services_history",
        data,
      },
    }),
  // step 4
  setuser_name: (data) =>
    dispatch({
      type: SHOW_INTEREST_STEPS,
      payload: {
        type: "user_name",
        data,
      },
    }),
  setuser_email: (data) =>
    dispatch({
      type: SHOW_INTEREST_STEPS,
      payload: {
        type: "user_email",
        data,
      },
    }),
  setuser_postcode: (data) =>
    dispatch({
      type: SHOW_INTEREST_STEPS,
      payload: {
        type: "user_postcode",
        data,
      },
    }),
  setuser_mobile_number: (data) =>
    dispatch({
      type: SHOW_INTEREST_STEPS,
      payload: {
        type: "user_mobile_number",
        data,
      },
    }),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowInterestDetails);
