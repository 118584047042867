/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect } from "react";
import { AiTwotoneEye, AiOutlineClockCircle } from "react-icons/ai";
import { BsDot } from "react-icons/bs";
import { GoLocation } from "react-icons/go";
import "./produ.css";
import "./GridCard.css";
import ProductMain from "../Product View/ProductMain";
import { Card, Container, Row } from "react-bootstrap";
import WishListButton from "../Product list/WishListButton";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
} from "react-router-dom";
export default (props) => {
  const { Products } = props;
  const [timer, setTimer] = useState([
    { id: "days", value: "00" },
    { id: "hours", value: "00" },
    { id: "minutes", value: "00" },
    { id: "seconds", value: "00" },
  ]);
  const [bidingExpired, setBidingExpired] = useState(true);

  const formateTimerInt = (e) => {
    return e < 10 ? `0${e}` : `${e}`;
  };
  // ----------------------- timer start

  // Set the date we're counting down to
  let countDownDate = new Date(Products.end_at).getTime();
  const timeFormatter = (e) => {
    return e < 10 ? `0${e}` : `${e}`;
  };
  useEffect(() => {
    const interval = setInterval(function () {
      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      // setTimer(
      //   `${
      //     formateTimerInt(days) +
      //     " d : " +
      //     formateTimerInt(hours) +
      //     " h : " +
      //     formateTimerInt(minutes) +
      //     " m : " +
      //     formateTimerInt(seconds) +
      //     " s"
      //   }`
      // );
      setTimer([
        { id: "days", value: timeFormatter(days) },
        { id: "hours", value: timeFormatter(hours) },
        { id: "minutes", value: timeFormatter(minutes) },
        { id: "seconds", value: timeFormatter(seconds) },
      ]);
      setBidingExpired(false);
      if (distance < 0) {
        clearInterval(interval);
        // setTimer("");
        setBidingExpired(true);
        setTimer([
          { id: "days", value: "00" },
          { id: "hours", value: "00" },
          { id: "minutes", value: "00" },
          { id: "seconds", value: "00" },
        ]);
        // document.getElementById(productListCardId).innerHTML = "EXPIRED";
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countDownDate]);
  // timer end-----------------------
  return (
    <div className="product_grid_card_main_cont_div">
      <WishListButton />

      <Link
        excat
        to={{
          pathname: "/product",
          state: { product_code: Products.id },
        }}
        className="imageHover product_grid_card_main_cont"
      >
        <div className="">
          <div className="product_list_card_image_wrapper">
            <img
              className="product_list_card_image"
              src={Products.main_image}
              alt="banner"
            />
          </div>
        </div>
        {/* body section */}
        <div className="product_grid_card_body_cont">
          <span className="product_list_card_title">
            {Products.product_name}
          </span>
          <div className="mt-2">
            {Products.product_feature
              ? Products.product_feature.slice(0, 3).map((item, index) => (
                  <span className="product_list_card_features_text">
                    {item.name}: {item.value}
                  </span>
                ))
              : null}
          </div>
          {!bidingExpired ? (
            <Fragment>
              <span
                className="product_list_card_title"
                style={{ fontSize: "0.7rem" }}
              >
                Bidding Expires in:
              </span>

              <div className="d-flex justify-content-start align-items-center">
                {timer.map((item, index) => {
                  return (
                    <Fragment>
                      <span className="  p-1 d-flex flex-column justify-content-center align-items-center">
                        <div className="list_card_timer_time">{item.value}</div>
                        <span className="list_grid_card_timer_label">
                          {item.id}
                        </span>
                      </span>
                      <span
                        className="counter_text pt-1 align-self-start "
                        style={{ fontSize: "0.8rem" }}
                      >
                        {index === timer.length - 1 ? null : ":"}
                      </span>
                    </Fragment>
                  );
                })}
              </div>
            </Fragment>
          ) : (
            <span
              className="product_list_card_title"
              style={{ fontSize: "0.9rem" }}
            >
              Sold
            </span>
          )}
        </div>
      </Link>
    </div>
  );
};
