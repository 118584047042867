import React from "react";
import Carpic1 from "../../assets/images/new/3.png";
import Carpic2 from "../../assets/images/new/1.png";
import Carpic3 from "../../assets/images/new/4.png";
import Carpic4 from "../../assets/images/new/2.png";
import { useContext } from "react";
import FeedbackContext from "../../context/cmscontext";
import "./style.css";
function Howwork() {


   const { feedback, isLoading } = useContext(FeedbackContext);
   var home_page = feedback.home_page;

  return (
    <>
      <div
        id="how_work"
        className="containerfluid  how_wrap"
        style={{ backgroundColor: "#F2F2F2" }}
      >
        <div className="container p-5">
          <h2 className="text-center">{home_page.How_Works_heading}</h2>

          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12 ">
              <div className="p-2">
                {/* <img src={Carpic1} /> */}
                <img src={home_page.Profile_your_car_img} />
              </div>
              <span className="work_text my-5">
                {home_page.Profile_your_car_heading}
              </span>
              <p>{home_page.Profile_your_car_desp}</p>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="p-2">
                {/* <img className="img_2" src={Carpic2} /> */}
                <img className="img_2" src={home_page.We_do_img} />
              </div>
              <span className="work_text">{home_page.We_do_heading}</span>
              <p>{home_page.We_do_heading_desp}</p>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="p-2">
                {/* <img src={Carpic3} /> */}
                <img src={home_page.Get_your_best_img} />
              </div>
              <span className="work_text">
                {home_page.Get_your_best_heading}
              </span>
              <p>{home_page.Get_your_best_desp}</p>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="p-2">
                {/* <img className="img_4" src={Carpic4} /> */}
                <img className="img_4" src={home_page.Complete_your_im} />
              </div>
              <span className="work_text">
                {home_page.Complete_your_heading}
              </span>
              <p>{home_page.Complete_your_desp}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Howwork;
