import React from "react";
import "./style.css";
//
export default function (props) {
  const { step, totalSteps } = props;
  return (
    <div className="progressIndicatorcont">
      <span className="progressIndicatorText">
        step {step} of {totalSteps}
      </span>
      <div
        className="progressIndicatorcont"
        style={{ background: "var(--infoPlus)" }}
      >
        <div
          className="progressIndicator"
          style={{ width: `${(step / totalSteps) * 100}%`, padding: 2 }}
        />
      </div>
    </div>
  );
}
