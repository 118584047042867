import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { Input, Select } from "antd";
import { BsCheck2 } from "react-icons/bs";
//
export default function IconInput(props) {
  const { label, value, onChangeText, prefix, placeholder, type, loading } =
    props;
  const onChangeHandler = (e) => {
    // 
    onChangeText(e.target.value);
  };
  return (
    <div className="dropdown_wrapper">
      <div>{label}:</div>
      <Input
        disabled={loading}
        type={type}
        placeholder={placeholder || label}
        prefix={prefix}
        onChange={onChangeHandler}
        value={value}
      />
    </div>
  );
}
