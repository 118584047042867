/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {
  forwardRef,
  useRef,
  useImperativeHandle,
  Fragment,
  useState,
  useEffect,
} from "react";
import { Row, Col } from "react-bootstrap";

import ImageGallery from "react-image-gallery";
// import Magnifier from '../Magnifer/index'
import {
  AiFillCaretUp,
  AiFillCaretDown,
  AiFillHeart,
  AiOutlineHeart,
} from "react-icons/ai";
import { MdTitle, MdLastPage, MdGroups, MdAccountCircle,MdDirectionsCar} from "react-icons/md";
import { ImHammer2 } from "react-icons/im";
import { RiReservedFill } from "react-icons/ri";
import { Divider } from "semantic-ui-react";
import TabExamplePointing from "./tabbar";
import Graph from "../Product View/graph";
import $ from "jquery";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Labels from "../../services/apiLabels";
import ScaleLoader from "react-spinners/ScaleLoader";
import Magnify from "./magnify";
import "./Productview.css";
// import "./style.css";
// import "../../components/Magnifer/magnifier.css";
import Event from "../../components/Magnifer/Event";
import Magnifier from "../../components/Magnifer/Magnifier";
import {
  PLACE_BID,
  PRODUCT_DETAIL,
  TOASTER_SHOW,
  toaster_w,
  APPROXY_BID_NEW,
  TIMER_DATA_UPDATE,
  SHIPING_COST,
} from "../../constant";
import { GET, POST } from "../../constant/apiMethods";
import carSvg from "../../assets/svg/car.svg";
import ProductMainShimmer from "./ProductMainShimmer";
import Tabs from "../TabsComponent";
import SlideButton from "../SlideButton";
import Carousel from "../Carousel";

function ProductMain(props) {
  const {
    product,
    getProductDetail,
    place_bid,
    toasterShow,
    loadingPlaceBid,
    user_id,
    loadingDetails,
    approxy_BidNew,
    shiping_cost_calclate,
    shiping_coast_,
    loading,
    costData,
    updatetimerdata,
    product_new,
  } = props;
  const graphRef = useRef();

  let location = useLocation();
  let history = useHistory();
  useEffect(() => {
   

    // if(location.state){
    //   const { product_code } = location.state;
    // }


  }, [])
  


// // console.log("user id ========>>>>>>>",)




  const [bidInputValue, setBidInputValue] = useState(0);
  const [bidInputFormated, setbidInputFormated] = useState({
    focused: false,
    formated: "0",
  });
  const [graphData, setGraphData] = useState({});
  // console.log("chk graph api data",graphData)
  const [bidingExpired, setBidingExpired] = useState(true);
  const [timer, setTimer] = useState([
    { id: "days", value: "00" },
    { id: "hours", value: "00" },
    { id: "minutes", value: "00" },
    { id: "seconds", value: "00" },
  ]);
  const [images, setImages] = useState([]);
  const [current_image, setcurrent_image] = useState("");
  // place bid inputs functions
  let bidAmountID = 1000;
  let [currentBid, setcurrentBid] = useState(0);
  const imageGalleryDataFormatter = (arr) => {
    let formattedArr = [];

    // arr.map((item) => {
    //   return formattedArr.push({
    //     image: item,
    //   });
    // });
    arr.map((item, index) => {
      return formattedArr.push({
        original: item,
        thumbnail: item,
        id: index + 1,
      });
    });
    

    setImages(formattedArr);
  };
  const [isdone, setisdone] = useState(false);
  // price formater adding commas
  function priceFormater(numb) {
    var str = numb.toString();
    return str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  // car animate handler
  // const carAnimationHandler = () => {
  //   document.getElementById("animating_car_cont").className =
  //     "bid_animating_car_cont";
  //   setTimeout(() => {
  //     document.getElementById("animating_car_cont").className = "d-none";
  //   }, 3000);
  // };

  useEffect(() => {


    if (location.state.product_code) {
      getProductDetail(location.state.product_code);
    }
else{
 history.push("/auction")
}

  }, []);

  useEffect(() => {
    if (product.images) imageGalleryDataFormatter(product.images);
    if (product.start_price) {
      bidInputOnChangeHandler(product.start_price);
      setcurrentBid(product.start_price);
    }
    // 
  }, [product]);
  // graph data handler
  useEffect(() => {

    updatetimerdata({
      end_at:graphData.end_at,
     
     })

  

    let currentBidAmmount = graphData.cur_bid;
    if (currentBidAmmount) {

     
      if (currentBidAmmount > currentBid) {
        bidInputOnChangeHandler(currentBidAmmount);
        setcurrentBid(currentBidAmmount);
        // carAnimationHandler();
      }
    }
  }, [graphData]);
  var evt = new Event();
  var m = new Magnifier(evt, {});
  // useEffect(() => {
  // // console.log(
  //   "tag class ",
  //   document.getElementsByClassName(".center")[0].getElementsByTagName("img")[0]
  // );
  //   if (!isdone) {
  //     setisdone(true);
  //     m = new Magnifier(evt, {
  //       largeWrapper: document.getElementById("zoom-preview"),
  //     });

  //     m.attach({
  //       thumb: document
  //         .getElementsByClassName(".center")[0]
  //         .getElementsByTagName("img")[0],
  //       large: current_image,
  //       largeWrapper: "zoom-preview",
  //       zoom: 2,
  //       mode: "inside",
  //       zoomable: true,
  //       onthumbenter: function () {
  //         // document.getElementById("enter").innerHTML = "Mouse enter";
  //         // document.getElementById("leave").innerHTML = "";
  //         // document.getElementById("zoom").innerHTML = "";
  //         document.getElementById("zoom-preview").classList.remove("d-none");
  //       },
  //       onthumbmove: function () {
  //         // document.getElementById("move").innerHTML = "Mouse move";
  //         document.getElementById("zoom-preview").classList.remove("d-none");
  //       },
  //       onthumbleave: function () {
  //         // document.getElementById("enter").innerHTML = "";
  //         // document.getElementById("move").innerHTML = "";
  //         // document.getElementById("zoom").innerHTML = "";
  //         // document.getElementById("leave").innerHTML = "Mouse leave";
  //         document.getElementById("zoom-preview").classList.add("d-none");
  //       },
  //       // onzoom: function (data) {
  //       //   document.getElementById("zoom").innerHTML = "Zoom: " + data.zoom;
  //       // },
  //     });
  //   } else {
  //     m.setThumb(
  //       document
  //         .getElementsByClassName(".center")[0]
  //         .getElementsByTagName("img")[0]
  //     );
  //   }
  // }, [current_image]);
  // --------------timer
  // let countDownDate = new Date().getTime() + 10000;
  // 
  const timeFormatter = (e) => {
    return e < 10 ? `0${e}` : e;
  };
  const screen_change = (e) => {
    m.leaver();
    document.getElementById("zoom-preview").classList.add("d-none");
    // 
    
    if (e) {
      
      m.attach({
        thumb: ".image-gallery-image",
        // document
        //   .getElementsByClassName("center")[0]
        //   .getElementsByTagName("img")[0],
        // large: current_image,
        largeWrapper: "zoom-preview",
        zoom: 2,
        mode: "inside",
        zoomable: true,
        onthumbenter: function () {
          // document.getElementById("enter").innerHTML = "Mouse enter";
          // document.getElementById("leave").innerHTML = "";
          // document.getElementById("zoom").innerHTML = "";
          // document.getElementById("zoom-preview").classList.remove("d-none");
        },
        onthumbmove: function () {
          // document.getElementById("move").innerHTML = "Mouse move";
          // document.getElementById("zoom-preview").classList.remove("d-none");
        },
        onthumbleave: function () {
          // document.getElementById("enter").innerHTML = "";
          // document.getElementById("move").innerHTML = "";
          // document.getElementById("zoom").innerHTML = "";
          // document.getElementById("leave").innerHTML = "Mouse leave";
          // document.getElementById("zoom-preview").classList.add("d-none");
        },
        // onzoom: function (data) {
        //   document.getElementById("zoom").innerHTML = "Zoom: " + data.zoom;
        // },
      });
    } else {
      //  
      m.attach({
        thumb: ".image-gallery-image",
        // document
        //   .getElementsByClassName("center")[0]
        //   .getElementsByTagName("img")[0],
        // large: current_image,
        largeWrapper: "zoom-preview",
        zoom: 2,
        mode: "inside",
        zoomable: true,
        onthumbenter: function () {
          // document.getElementById("enter").innerHTML = "Mouse enter";
          // document.getElementById("leave").innerHTML = "";
          // document.getElementById("zoom").innerHTML = "";
          document.getElementById("zoom-preview").classList.remove("d-none");
        },
        onthumbmove: function () {
          // document.getElementById("move").innerHTML = "Mouse move";
          document.getElementById("zoom-preview").classList.remove("d-none");
        },
        onthumbleave: function () {
          // document.getElementById("enter").innerHTML = "";
          // document.getElementById("move").innerHTML = "";
          // document.getElementById("zoom").innerHTML = "";
          // document.getElementById("leave").innerHTML = "Mouse leave";
          document.getElementById("zoom-preview").classList.add("d-none");
        },
        // onzoom: function (data) {
        //   document.getElementById("zoom").innerHTML = "Zoom: " + data.zoom;
        // },
      });
    }
    m.set_data();
    //  m = new Magnifier(evt, {
    //    largeWrapper: document.getElementById("zoom-preview"),
    //  });
    // m.setThumb(document
    //         .getElementsByClassName("center")[0]
    //         .getElementsByTagName("img")[0]);
  };
  const set_magnifier = (e) => {
    if (!isdone) {
      setisdone(true);
      // m = new Magnifier(evt, {
      //   largeWrapper: document.getElementById("zoom-preview"),
      // });
      m.attach({
        thumb: ".image-gallery-image",
        // document
        //   .getElementsByClassName("center")[0]
        //   .getElementsByTagName("img")[0],
        // large: current_image,
        largeWrapper: "zoom-preview",
        zoom: 2,
        mode: "inside",
        zoomable: true,
        onthumbenter: function () {
          // document.getElementById("enter").innerHTML = "Mouse enter";
          // document.getElementById("leave").innerHTML = "";
          // document.getElementById("zoom").innerHTML = "";
          document.getElementById("zoom-preview").classList.remove("d-none");
        },
        onthumbmove: function () {
          // document.getElementById("move").innerHTML = "Mouse move";
          document.getElementById("zoom-preview").classList.remove("d-none");
        },
        onthumbleave: function () {
          
          // document.getElementById("enter").innerHTML = "";
          // document.getElementById("move").innerHTML = "";
          // document.getElementById("zoom").innerHTML = "";
          // document.getElementById("leave").innerHTML = "Mouse leave";
          document.getElementById("zoom-preview").classList.add("d-none");
        },
        // onzoom: function (data) {
        //   document.getElementById("zoom").innerHTML = "Zoom: " + data.zoom;
        // },
      });
    }
  };
  useEffect(() => {
    let countDownDate = new Date(product_new.end_at ? product_new.end_at : 0).getTime();
    const interval = setInterval(function () {
      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;
      

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setTimer([
        { id: "days", value: timeFormatter(days) },
        { id: "hours", value: timeFormatter(hours) },
        { id: "minutes", value: timeFormatter(minutes) },
        { id: "seconds", value: timeFormatter(seconds) },
      ]);
      setBidingExpired(false);
      // If the count down is over, write some text
      if (distance < 0) {
        clearInterval(interval);
        setBidingExpired(true);
        setTimer([
          { id: "days", value: "00" },
          { id: "hours", value: "00" },
          { id: "minutes", value: "00" },
          { id: "seconds", value: "00" },
        ]);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [product_new]);
  // --------------timer


// ==========aprocxy bid==========

const approxy_bid= async ()=>{


  let obj = {
    date: new Date(),
    auction_id: product.auction_id,
    user_id,
    bid_amount: bidInputValue,
    bid_type:"2",
  };

  approxy_BidNew(obj);
  await graphRef.current.fetchGraphData();
  return "bid placed";

}





  // place bid handler
  const placeBidHandler = async () => {
    if (bidInputValue > currentBid) {
      let obj = {
        date: new Date(),
        auction_id: product.auction_id,
        user_id,
        bid_amount: bidInputValue,
        bid_type:"1",
      };
      // 
      await place_bid(obj);
      await graphRef.current.fetchGraphData();
      return "bid placed";
    } else {
      toasterShow({
        type: toaster_w,
        description: `Bidding amount must be greater then ${currentBid}`,
      });
      return "bid not placed";
    }
  };
  const bidInputOnChangeHandler = (e) => {
    if (e) {
      setBidInputValue(e);
      setbidInputFormated({ ...bidInputFormated, formated: priceFormater(e) });
    }
  };

// ================shiping valculate=============



const [postCode, setpostCode] = useState('')
// console.log("shiping redux",shiping_coast_)





const calculate_=()=>{

  shiping_cost_calclate({
    position1:product.post_code,
    position2:postCode,
    t_type:"open",
    pickup:false,
    type_of_car:"SUV",
   premium:false,
  })

}



  // document
  //   .getElementByClassName("image-gallery-fullscreen-button")[0]
  //   .addEventListener("click", changeObjectFitHandler);
  // function changeObjectFitHandler() {
  //   let image = document.getElementsByClassName("img")[0];
  //   let style = window.getComputedStyle(image);
  //   let objectFit = style.getPropertyValue("object-fit");
  //   if (objectFit === "contain") {
  //     image.style.objectFit = "cover";
  //   }
  //   if (objectFit === "cover") {
  //     image.style.objectFit = "contain";
  //   }
  //   
  // }
  useEffect(() => {
    
    // console.log("testingggg bidInputValue", parseInt(bidInputValue));
    
  }, [bidInputValue, bidInputFormated]);
  // loadingDetails
  if (loadingDetails) {
    return <ProductMainShimmer />;
  } else {
    return (
      <Fragment>
        <div className="container-fluid mt-5  ">
          <div className="row">
            {/* left column */}
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12  ">
              {/* ================ image slider ================== */}
              <div className="slider_ ">
                {/* <Carousel images={product.images} /> */}

                <ImageGallery
                  lazyLoad={false}
                  // onScreenChange={screen_change}
                  // onSlide={set_magnifier}
                  onImageLoad={set_magnifier}
                  showBullets={true}
                  items={images}
                  showFullscreenButton={false}
                  // renderItem={(e) =>// console.log(e)}
                  originalClass="ImageGallery_originalClass"
                />
                {/* {images.length > 0 ? (
                  <SliderImage
                    data={SliderImageData}
                    width="100%"
                    direction="right"
                  />
                ) : null} */}
              </div>
           <div>
             
<div className="row p-4">

<h3>Shiping Charges</h3>
<p> Enter Your Post Code to Calculate shiping charges</p>
<div className="col-4">
<div className="inp_wrap ">
              <input 

onChange={(e)=>{setpostCode(e.target.value)}}

              className="inp_contact"
              placeholder="LEG321 "
              ></input>
              </div>
  

</div>
<div className="col-4">
<button className="button_heder" 
onClick={calculate_}
>{loading?"Calculating" :"Calculate"}</button>
</div>
<span className="mt-3"><span className=" abab">Total:{Math.floor(costData)}</span> </span>
 
</div>
           </div>
              <div className="col-sm-6.col-md-6.col-lg-6.col-xl-6 desktopbar mt-5">
          
                <TabExamplePointing />
           
              </div>
            </div>
            {/* right column */}
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12  ">
              <div
                className="magnifier-preview d-none"
                id="zoom-preview"
                // style={{
                // position: "absolute",
                // top: 0,
                // zIndex: 100000000,
                // left: wrapper.width,
                // width: window.innerWidth - wrapper.width,
                // height: wrapper.height,
                // backgroundColor: "",
                // }}
              >
                {/* this is side div */}
              </div>
              {/* =============car detail and bid ================= */}
              <div className="cardetailmain d-flex flex-column mt-5">
                {/* =============== car title and detail ================== */}
                <div className="viewtitle">
                  <span>{product.product_name}</span>
                  <div className="viewtitle_underline" />
                </div>
                <div
                  className={`${
                    bidingExpired ? "d-none" : ""
                  } d-flex  flex-column justify-content-center align-items-center`}
                >
                  {/* counter container */}
                  <div className="counter_main_container flex-column d-flex justify-content-center align-items-center align-self-center mt-1 mb-1">
                    <Fragment>
                      {/* buy now button container */}
                      {!bidingExpired ? (
                        <button className="counter_buy_now_cont  m-1 p-2">
                          <span className="counter_buy_now_text">
                            <AiOutlineHeart color="#fff" size={20} />
                            {/* <AiFillHeart color="#fff" size={20} /> */}
                          </span>
                        </button>
                      ) : null}
                      {/* count down timer container */}
                      <div className="d-flex justify-content-center align-items-center">
                        {timer.map((item, index) => {
                          return (
                            <Fragment>
                              <span className="  p-1 d-flex flex-column justify-content-center align-items-center">
                                <div className="counter_text counter_responsive_text">
                                  {item.value}
                                </div>
                                <span className="counter_time_title mt-1">
                                  {item.id}
                                </span>
                              </span>
                              <span className="counter_text pt-1 align-self-start ">
                                {index === timer.length - 1 ? null : ":"}
                              </span>
                            </Fragment>
                          );
                        })}
                      </div>
                      <Tabs
                        data={[
                          {
                            label: "Place Bid",
                            component: (
                              <span>
                                {/* place bid container */}
                                <div
                                  // style={{ height: "40px", margin: "5%" }}
                                  className="place_bid_input_cont"
                                >
                                  {/* car animation */}
                                  <div
                                    id="animating_car_cont"
                                    className="d-none"
                                  >
                                    <img
                                      className="bid_animating_car"
                                      src={carSvg}
                                      alt="car..."
                                    />
                                  </div>
                                  {/* ////////////// car animation */}
                                  <Fragment>
                                    <input
                                      disabled={
                                        loadingPlaceBid || bidingExpired
                                      }
                                      value={
                                        bidInputFormated.focused
                                          ? bidInputValue
                                          : bidInputFormated.formated
                                      }
                                      onChange={(e) =>
                                        bidInputOnChangeHandler(e.target.value)
                                      }
                                      onFocus={() =>
                                        setbidInputFormated({
                                          ...bidInputFormated,
                                          focused: true,
                                        })
                                      }
                                      onBlur={(e) => {
                                        if (bidInputValue < currentBid) {
                                          setBidInputValue(currentBid);
                                          setbidInputFormated({
                                            focused: false,
                                            formated: priceFormater(currentBid),
                                          });
                                        } else {
                                          setbidInputFormated({
                                            ...bidInputFormated,
                                            focused: false,
                                          });
                                        }
                                      }}
                                      // className="bg_red"
                                      placeholder="Place Your Bid"
                                      aria-label="Recipient's username"
                                      aria-describedby="button-addon2"
                                    />

                                    <div className="d-flex flex-column">
                                      <button
                                        disabled={
                                          loadingPlaceBid || bidingExpired
                                        }
                                        onClick={() =>
                                          bidInputOnChangeHandler(
                                            parseInt(bidInputValue) +
                                              bidAmountID
                                          )
                                        }
                                        className="d-flex justify-content-center align-items-center  incrementDecrementBtn"
                                      >
                                        <AiFillCaretUp />
                                      </button>
                                      <button
                                        disabled={
                                          loadingPlaceBid || bidingExpired
                                        }
                                        onClick={() => {
                                          const decVal =
                                            parseInt(bidInputValue) -
                                            bidAmountID;
                                          if (decVal >= currentBid) {
                                            bidInputOnChangeHandler(decVal);
                                          }
                                        }}
                                        className="d-flex justify-content-center align-items-center  incrementDecrementBtn"
                                      >
                                        <AiFillCaretDown />
                                      </button>
                                    </div>
                                  </Fragment>
                                </div>
                                {/* <button onClick={carAnimationHandler}>animate car</button> */}
                                {loadingPlaceBid ? (
                                  <div className="counter_place_bid_cont d-flex justify-content-center align-items-center w-100 m-1 p-2">
                                    <span
                                      className="counter_place_bid_cont_text"
                                      style={{ fontSize: "1.4rem" }}
                                    >
                                      Placing Bid ...
                                    </span>
                                  </div>
                                ) : (
                                  <SlideButton
                                    disabled={loadingPlaceBid || bidingExpired}
                                    onEnd={placeBidHandler}
                                  />
                                )}
                                {/* <button
                                  className="counter_place_bid_cont d-flex justify-content-center align-items-center w-100 m-1 p-2"
                                  disabled={loadingPlaceBid || bidingExpired}
                                  onClick={placeBidHandler}
                                >
                                  <span
                                    className="counter_place_bid_cont_text"
                                    style={{ fontSize: "1.4rem" }}
                                  >
                                    {loadingPlaceBid
                                      ? `Bidding...`
                                      : `Place Bid`}
                                  </span>
                                </button> */}
                              </span>
                            ),
                          },
                          {
                            label: "approxy bid",
                            component: (
                              <span>
                                <div className="place_bid_input_cont p-2">
                                  <input placeholder="Approxy Bid" 
                                   onChange={(e) =>
                                    bidInputOnChangeHandler(e.target.value)
                                  }
                                  />
                                </div>
                                {loadingPlaceBid ? (
                                  <div className="counter_place_bid_cont d-flex justify-content-center align-items-center w-100 m-1 p-2">
                                    <span
                                      className="counter_place_bid_cont_text"
                                      style={{ fontSize: "1.4rem" }}
                                    >
                                      Placing Approxy bid ...
                                    </span>
                                  </div>
                                ) : (
                                  <SlideButton
                                    disabled={false}
                                    onEnd={approxy_bid}
                                  />
                                )}
                              </span>
                            ),
                          },
                        ]}
                      />

                      {/* //////////////////////// place bid container */}
                    </Fragment>
                  </div>
                  {/* bidding deta detail container */}
                  <div className="mt-4 flex-row d-flex justify-content-center align-items-center align-self-center mt-1 mb-1">
                    {/* total bidders */}
                    <div className=" d-flex justify-content-center align-items-center flex-column data_row_item"
                    style={{cursor:"pointer"}}
                                        onClick={()=>{ history.push({ pathname: "/carreport", state: {product_code:location.state.product_code }})}}
                    >
                      <MdDirectionsCar className="data_row_item_icon" />
                      <span className="data_row_item_icon data_row_item_title">
                        Insepction Report
                      </span>
                      <span className="data_row_item_icon data_row_item_body">
               <br></br>
                      </span>
                    </div>
                    <div className=" d-flex justify-content-center align-items-center flex-column data_row_item" 
                    

                    >
                      <MdGroups className="data_row_item_icon" />
                      <span className="data_row_item_icon data_row_item_title">
                        total bids
                      </span>
                      <span className="data_row_item_icon data_row_item_body">
                        {graphData.total_bidders}
                      </span>
                    </div>
                    {/* My Bids */}
                    <div className=" d-flex justify-content-center align-items-center flex-column data_row_item">
                      <ImHammer2 className="data_row_item_icon" />
                      <span className="data_row_item_icon data_row_item_title">
                        My Bids
                      </span>
                      <span className="data_row_item_icon data_row_item_body">
                        {graphData.my_bids}
                      </span>
                    </div>
                    {/* Last Bid */}
                    <div className=" d-flex justify-content-center align-items-center flex-column data_row_item">
                      <MdLastPage className="data_row_item_icon" />
                      <span className="data_row_item_icon data_row_item_title">
                        Last Bid
                      </span>
                      <span className="data_row_item_icon data_row_item_body">
                        {graphData.last_bid}
                      </span>
                    </div>
                    {/* Reserved Price */}
                    <div
                      style={{ background: true ? "green" : "red" }}
                      className=" d-flex justify-content-center align-items-center flex-column data_row_item"
                    >
                      <RiReservedFill
                        style={{ color: "white" }}
                        className="data_row_item_icon"
                      />
                      <span
                        style={{ color: "white" }}
                        className="data_row_item_icon data_row_item_body"
                      >
                        Price
                      </span>
                      <span
                        style={{ color: "white" }}
                        className="data_row_item_icon data_row_item_body"
                      >
                        Reserved
                      </span>
                    </div>
                  </div>
                  <Graph
                    graphDataCb={(e) => setGraphData(e)}
                    ref={graphRef}
                    auction_id={product.auction_id}
                    user_id={user_id}
                  />
                </div>
                <div
                  className={`counter_main_container ${
                    bidingExpired ? null : "d-none"
                  } `}
                >
                  {" "}
                  SOLD
                </div>
              </div>
              <div className=" mobileTabBar">
                <TabExamplePointing />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user_id: state.auth.id,
  product: state.product.current,
  product_new: state.product.newtimer,
  loadingDetails: state.product.fetchingCurrent,
  loadingPlaceBid: state.product.loadingPlaceBid,
  shiping_coast_: state.shiping,
  loading: state.shiping.loading,
  costData: state.shiping.data,
 
});
const mapDispatchToProps = (dispatch) => ({
  // get product list


  updatetimerdata: (data) =>
  dispatch({
    type: TIMER_DATA_UPDATE,
    payload: {
      data,
    },
  }),





  getProductDetail: (code) =>
    dispatch({
      type: PRODUCT_DETAIL,
      payload: {
        type: PRODUCT_DETAIL,
        method: GET,
        label: `${Labels.product_detail}${code}/`,
        hideOverlayLoader: true,
      },
    }),
  //
  // place bid
  place_bid: (data) =>
    dispatch({
      type: PLACE_BID,
      payload: {
        type: PLACE_BID,
        method: POST,
        label: `${Labels.place_bid}`,
        data,
        hideOverlayLoader: true,
      },
    }),
  //  get shiping cost
  shiping_cost_calclate: (data) =>
    dispatch({
      type: SHIPING_COST,
      payload: {
        type: SHIPING_COST,
        method: POST,
        label: `${Labels.shiping_cost}`,
        data,
        hideOverlayLoader: true,
      },
    }),
  //
  // approxy place bid
  approxy_BidNew: (data) =>
    dispatch({
      type: APPROXY_BID_NEW,
      payload: {
        type: APPROXY_BID_NEW,
        method: POST,
        label: `${Labels.approxy_Bid}`,
        data,
        hideOverlayLoader: true,
      },
    }),
  //
  toasterShow: (data) =>
    dispatch({
      type: TOASTER_SHOW,
      payload: {
        show: true,
        autoHide: true,
        ...data,
      },
    }),
});
export default connect(mapStateToProps, mapDispatchToProps)(ProductMain);
