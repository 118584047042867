/**
 * Root Sagas
 */
import { all } from "redux-saga/effects";

// sagas
import authSagas from "./auth";
import productSaga from "./product";
import sellMyCarSaga from "./sellMyCar";
import showInterestSaga from "./showInterest";

export default function* rootSaga(getState) {
  yield all([authSagas(), productSaga(), sellMyCarSaga(), showInterestSaga()]);
}
