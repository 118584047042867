import React from "react";
//
import Form from "../../components/Form";
import Dropdown from "../../components/Form/Dropdown";
import IconInput from "../../components/Form/Input";
//

import { useHistory } from "react-router-dom";


import { connect } from "react-redux";
import { EVALUATION_CREATE, SELL_MY_CAR_STEPS } from "../../constant";
import { POST } from "../../constant/apiMethods";
import Labels from "../../services/apiLabels";
import Step6 from "./Step6";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step7 from "./Step7";
import Step8 from "./Step8";
//
import { scrollTop } from "../../utils/window";
// 
function EvaluationSteps(props) {
  const {
    currentStep,
    setcurrentStep,
    //
    step1,
    //
    reg_number,
    mileage,
    previous_owners,
    services_history,
    setreg_number,
    setmileage,
    setprevious_owners,
    setservices_history,
    //
    setuser_name,
    setuser_email,
    setuser_postcode,
    setuser_mobile_number,
    //
    user_name,
    user_email,
    user_postcode,
    user_mobile_number,
    //
    postEvaluationCreate,
    loading_evaluation_create,
  } = props;
  const formanimationClassname = "animate__animated animate__zoomIn";
  const step2OnSubmitHandler = () => {
    if (
      mileage.isSelected &&
      previous_owners.isSelected &&
      services_history.isSelected &&
      reg_number
    )
      setcurrentStep(currentStep + 1);
    scrollTop();
  };
  //
  const renderServiceHistoryDetail = (e) => {
    switch (e) {
      case "Full":
        return "All recomended serevices on time ";
      case "Some":
        return "Some recomended serevices are missed ";
      case "None":
        return "No services are done";
      case "First not Due":
        return "Recomended service is due";

      default:
        return "";
    }
  };
  //
  const postEvaluationCreateHandler = () => {
    if (
      step1.variant.isSelected &&
      mileage.isSelected &&
      previous_owners.isSelected &&
      services_history.isSelected &&
      user_name &&
      user_email &&
      user_postcode &&
      user_mobile_number &&
      reg_number
    ) {
      postEvaluationCreate({
        car: step1.variant.current.value,
        Mileage: mileage.current.title,
        reg_number,
        Previous_owners: previous_owners.current.id,
        Service_history: services_history.current.id,
        Website_user: user_name,
        Email: user_email,
        Postcode: user_postcode,
        Mobile_number: user_mobile_number,
      });
      scrollTop();
    } else {
       console.log("");
    }
  };

  const history = useHistory();





  return (
    <>
      {/* ============================== step 2 form ============================== */}
      
      {/* ============================== step 3 form ============================== */}
  
      <div className="w-100" style={{ padding: "0.5rem" }}>

      {/* {currentStep === 8 && (
          <Step8 formanimationClassname={formanimationClassname} />
        )} */}

        {/* {true ( */}



          <Step4 formanimationClassname={formanimationClassname} />
             {/* {currentStep === 5 && (
          <Step5 formanimationClassname={formanimationClassname} />
        )}
        {currentStep === 6 && (
          <Step6 formanimationClassname={formanimationClassname} />
        )}
        {currentStep === 7 && (
          <Step7 formanimationClassname={formanimationClassname} />
        )}
       */}
      </div>
      {/* <button onClick={() => setcurrentStep(currentStep - 1)}>Back</button>
      <button onClick={() => setcurrentStep(currentStep + 1)}>forward</button> */}
    </>
  );
}

const mapStateToProps = (state) => ({
  loading_evaluation_create: state.sellMyCar.loading,
  step1: state.sellMyCar.step1,
  reg_number: state.sellMyCar.step2.reg_number,
  mileage: state.sellMyCar.step2.mileage,
  previous_owners: state.sellMyCar.step2.previous_owners,
  services_history: state.sellMyCar.step2.services_history,
  // step 3 user info
  user_name: state.sellMyCar.user.name,
  user_email: state.sellMyCar.user.email,
  user_postcode: state.sellMyCar.user.postcode,
  user_mobile_number: state.sellMyCar.user.mobile_number,
});
const mapDispatchToProps = (dispatch) => ({
  setmileage: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "mileage",
        data,
      },
    }),
  setprevious_owners: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "previous_owners",
        data,
      },
    }),
  setservices_history: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "services_history",
        data,
      },
    }),
  setreg_number: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "reg_number",
        data,
      },
    }),
  // step 4
  setuser_name: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "user_name",
        data,
      },
    }),
  setuser_email: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "user_email",
        data,
      },
    }),
  setuser_postcode: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "user_postcode",
        data,
      },
    }),
  setuser_mobile_number: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "user_mobile_number",
        data,
      },
    }),
  // post EVALUATION_CREATE
  postEvaluationCreate: (data) => {
    return dispatch({
      type: EVALUATION_CREATE,
      payload: {
        type: EVALUATION_CREATE,
        method: POST,
        label: `${Labels.evaluation_create}`,
        hideOverlayLoader: true,
        data,
      },
    });
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(EvaluationSteps);
