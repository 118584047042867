import { React, useState } from "react";
import Step8 from "../Evaluation/Step8";
import { AiFillCar } from "react-icons/ai";
import "./style.css";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
// ES6
import DeskTopView from "../../components/Desktopview"
import * as LottiePlayer from "@lottiefiles/lottie-player";
import Draggable, { DraggableCore } from "react-draggable";
import DriggDraw from "../../components/Draggdraw/index.js";
import SlideRuler from "../../components/Ruler/index";
import $ from "jquery";
import Car1 from "../../assets/images/cara.png";
import Car2 from "../../assets/images/carb.png";
import Car3 from "../../assets/images/carc.png";
import Car4 from "../../assets/images/card.png";
import Car5 from "../../assets/images/care.png";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
function Appoinmentupdate() {
  const [imge, setimg] = useState(Car3);

  const [sreen, setsreen] = useState(
    window.orientation == 90 ? "landscap" : "portrait"
  );

  

  function preventHorizontalKeyboardNavigation(event) {
    
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      event.preventDefault();
      
    }
    
  }

  const handleChange = (e) => {
    
    const myElement = document.getElementById("ship");
    myElement.style.height = `${e.target.value}px`;
    myElement.style.width = `${e.target.value}px`;
    myElement.style.minHeight = "15px";
    myElement.style.minWidth = "15px";

    myElement.style.maxWidth = "80px";
    myElement.style.maxHeight = "80px";
  };

  const Change_Image1 = () => {
    document.getElementById("imge_wrap").style.width = "80%";
    setimg(Car3);
    const myElement = document.getElementById("aa");
    myElement.style.background = "green";
    document.getElementById("ab").style.background = "gray";
    document.getElementById("ac").style.background = "gray";
    document.getElementById("ad").style.background = "gray";
    document.getElementById("ae").style.background = "gray";
  };
  const Change_Image2 = () => {
    setimg(Car5);
    document.getElementById("imge_wrap").style.width = "100%";

    document.getElementById("aa").style.background = "gray";
    document.getElementById("ab").style.background = "green";
    document.getElementById("ac").style.background = "gray";
    document.getElementById("ad").style.background = "gray";
    document.getElementById("ae").style.background = "gray";
  };
  const Change_Image3 = () => {
    setimg(Car4);
    document.getElementById("imge_wrap").style.width = "70%";
    document.getElementById("aa").style.background = "gray";
    document.getElementById("ab").style.background = "gray";
    document.getElementById("ac").style.background = "green";
    document.getElementById("ad").style.background = "gray";
    document.getElementById("ae").style.background = "gray";
  };
  const Change_Image4 = () => {
    setimg(Car1);
    document.getElementById("imge_wrap").style.width = "100%";
    document.getElementById("aa").style.background = "gray";
    document.getElementById("ab").style.background = "gray";
    document.getElementById("ac").style.background = "gray";
    document.getElementById("ad").style.background = "green";
    document.getElementById("ae").style.background = "gray";
  };
  const Change_Image5 = () => {
    setimg(Car2);

    document.getElementById("imge_wrap").style.width = "100%";

    document.getElementById("ab").style.background = "gray";

    document.getElementById("ac").style.background = "gray";
    document.getElementById("ad").style.background = "gray";
    document.getElementById("ae").style.background = "gray";
    document.getElementById("ae").style.background = "green";
  };

  document.addEventListener("DOMContentLoaded", () => {
    function doOnOrientationChange() {
      
      switch (window.orientation) {
        // case -90:
        case 90:
          setsreen("landscap");

          
          break;
        default:
          setsreen("portrait");
          

          break;
      }
    }

    window.addEventListener("orientationchange", doOnOrientationChange);

    var ship = document.getElementById("ship");

    document
      .getElementById("imge_wrap")
      .addEventListener("touchstart", handleTouchEvent, true);
    document
      .getElementById("imge_wrap")
      .addEventListener("touchmove", handleTouchEvent, true);
    document
      .getElementById("imge_wrap")
      .addEventListener("touchend", handleTouchEvent, true);
    document
      .getElementById("imge_wrap")
      .addEventListener("touchcancel", handleTouchEvent, true);

    ship.addEventListener("touchstart", handleTouchEvent, true);
    ship.addEventListener("touchmove", handleTouchEvent, true);
    ship.addEventListener("touchend", handleTouchEvent, true);
    ship.addEventListener("touchcancel", handleTouchEvent, true);

    // will adjust ship's x to latest touch
    function handleTouchEvent(e) {
      // 
      if (e.touches.length === 0) return;
      var ship = document.getElementById("ship");
      var tA = document.getElementById("overmain");

      const elemWidth = ship.getBoundingClientRect().width;
      const parentWidth = ship.parentElement.getBoundingClientRect().width;

      // // console.log( "Element overflow ",elemWidth > parentWidth)
      // // console.log("Overflow width ",tA.scrollWidth > tA.clientWidth)
      // // console.log("Overflow height",tA.scrollHeight > tA.clientHeight)

      // // console.log("Overflow width 2",tA.scrollWidth < tA.clientWidth)
      // // console.log("Overflow height 2",tA.scrollHeight < tA.clientHeight)
      var clientRectangle = ship.getBoundingClientRect();

      // // console.log(parseFloat(ship.style.top));
      var y = clientRectangle.top - parseFloat(ship.style.top);
      var x = clientRectangle.left - parseFloat(ship.style.left);
      // // console.log(
      //   "Clietn left and right ",
      //   clientRectangle.top,
      //   clientRectangle.left
      // );
      // 
      // var positionInfo = ship.getBoundingClientRect();
      // // console.log("height ,width ",ship.offsetWidth,ship.offsetHeight,)

      // var mul = positionInfo.width*(2.3/50)
      // // console.log("multi ",mul)
      // var mul2 = positionInfo.height*(2.3/50)
      e.preventDefault();
      e.stopPropagation();
      // 
      var touch = e.touches[0];
      // var target =touch.target;
      // // console.log("width ",touch.pageX,ship.width,(touch.pageX - ship.width /2));
      // // console.log("Height ",touch.pageY,ship.height,(touch.pageY -ship.height/2));
      // 
      // 
      ship.style.left = touch.pageX - x + "px";
      // 
      ship.style.top = touch.pageY - y + "px";
      //  
    }
  });

  return (
    <>
      <div className="container-fluied aaa">
        <BrowserView>

        <DeskTopView/>

          {/* <div className="web_wrap ">
            <div className="mt-"> <h1> Sell Any Wheel</h1> </div>
            <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
            <lottie-player
              src="https://assets2.lottiefiles.com/packages/lf20_ti4y5deu.json"
              background="#FEF200"
              speed="1"
              style={{"width": "300px", "height": "300px"}}
              loop
             
              autoplay
            ></lottie-player>

            <div className="w-25 text_wrap_2"><h1 className="text_2">Non mobile devices are not supported</h1></div>
            <div className="w-50 mt-4">
            <p className="text_3">
              To access the photo app you need an iPhone or iPad running iOS 13
              or later, or an Android device running version 9 or later.
            </p>

            </div>
          
          </div> */}
        </BrowserView>

        <MobileView>
          {sreen == "landscap" ? (
            <div className="row car_Main_Wrap mt-4">
              <div className="col-9 ">
                <div className="Car_Pic_Wrap pt-5" id="overmain">
                  <div
                    className="buble"
                    id="ship"
                    style={{ top: "0px", left: "0px" }}
                  ></div>

                  <img className="car_Pic" id="imge_wrap" src={imge} />
                </div>

                <div className="row my-5 btn_wrap">
                  <div
                    className="col-2"
                    onClick={() => {
                      Change_Image1();
                    }}
                  >
                    <div className="icon_div" id="aa">
                      <AiFillCar />
                    </div>

                    <p className="car_text my-2 text-center">Front</p>
                  </div>
                  <div className="col-3" onClick={Change_Image2}>
                    <div className="icon_div" id="ab">
                      <AiFillCar />
                    </div>
                    <p className="car_text my-2 text-center">Passenger </p>
                  </div>
                  <div className="col-2" onClick={Change_Image3}>
                    <div className="icon_div" id="ac">
                      <AiFillCar />
                    </div>
                    <p className="car_text my-2 text-center">Back</p>
                  </div>
                  <div className="col-2" onClick={Change_Image4}>
                    <div className="icon_div" id="ad">
                      <AiFillCar />
                    </div>
                    <p className="car_text my-2 text-center">Driver</p>
                  </div>
                  <div className="col-2" onClick={Change_Image5}>
                    <div className="icon_div" id="ae">
                      <AiFillCar />
                    </div>
                    <p className="car_text my-2 text-center">Top</p>
                  </div>
                </div>
              </div>

              <div className="col-3">
                <div className="row">
                  <div className="col-3 mt-5 pt-4">
                    <Box sx={{ height: 260 }}>
                      <Slider
                        sx={{
                          '& input[type="range"]': {
                            WebkitAppearance: "slider-vertical",
                          },
                        }}
                        orientation="vertical"
                        defaultValue={20}
                        aria-label="Temperature"
                        onKeyDown={preventHorizontalKeyboardNavigation}
                        onChange={handleChange}
                      />
                    </Box>
                  </div>
                  <div className="col-9 pt-5 ">
                    <div>
                      <span className="heading-size"> Large </span>
                      <p>16cm+</p>
                    </div>
                    <div className="my-5 pt-3">
                      <span className="heading-size"> Medium</span>
                      <p>6-15cm</p>
                    </div>
                    <div className="my-5 pt-5">
                      <span className="heading-size">Small</span>
                      <p>0.52cm </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="Lotie_Main_Wrap">
              <div className="lotie_wrap">
                <lottie-player
                  src="https://assets10.lottiefiles.com/private_files/lf30_uqnbeq1u.json"
                  background="#48BA7E"
                  speed="1"
                  style={{ height: "92vh" }}
                  loop
                  autoplay
                ></lottie-player>
              </div>
            </div>
          )}
        </MobileView>
      </div>
    </>
  );
}

export default Appoinmentupdate;
