/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./navbar.css";
import { Navbar, Container, Nav } from "react-bootstrap";
import carlogo from "../../assets/images/logoc.png";
import {

  NavLink,

  useHistory,
} from "react-router-dom";
import { connect } from "react-redux";
import { LOG_OUT } from "../../constant";
import AccountMenu from "../AccountMenu";

const Index = ({ auth, logOut }) => {
  return (
    <>
      <Navbar
        collapseOnSelect
        className="sticky-top navbar-light navmain"
        expand="lg"
        bg="dark"
        variant="dark"
      >
        <Container>
          <Navbar.Brand>
            <Nav.Link to="/">
              <img className="navlogo" src={carlogo} />
            </Nav.Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link>
                <NavLink
                  exact
                  to="/"
                  activeClassName="selected"
                  className="unselected"
                >
                  Home
                </NavLink>
              </Nav.Link>
              <Nav.Link>
                <NavLink
                  exact
                  to="/Buying"
                  className="unselected"
                  activeClassName="selected"
                >
                  Buying
                </NavLink>
              </Nav.Link>
              <Nav.Link>
                <NavLink
                  exact
                  to="/selling"
                  className="unselected"
                  activeClassName="selected"
                >
                  Selling
                </NavLink>
              </Nav.Link>
              <Nav.Link>
                <NavLink
                  exact
                  to="/dostprogram"
                  className="unselected"
                  activeClassName="selected"
                >
                  Dost Program
                </NavLink>
              </Nav.Link>
              <Nav.Link>
                <NavLink
                  exact
                  to="/contact"
                  className="unselected"
                  activeClassName="selected"
                >
                  Contact
                </NavLink>
              </Nav.Link>
              {/* <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">
                  Something
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Separated link
                </NavDropdown.Item>
              </NavDropdown> */}
            </Nav>
            {auth.token ? (
              <Nav>
                <AccountMenu
                  onLogout={() => {
                    logOut();
                    // window.location.reload(true);
                  }}
                />
                {/* <button
                  onClick={() => {
                    logOut();
                    window.location.reload(true);
                  }}
                  className="btn btn-secondary unselected"
                  style={{ background: "none" }}
                >
                  Log Out
                </button> */}
              </Nav>
            ) : (
              <Nav>
                <Nav.Link>
                  <NavLink
                    exact
                    to="/login"
                    className="unselected"
                    activeClassName="selected"
                  >
                    Login
                  </NavLink>
                </Nav.Link>
                <Nav.Link eventKey={2}>
                  <NavLink
                    exact
                    to="/signup"
                    className="unselected"
                    activeClassName="selected"
                  >
                    Get Started
                  </NavLink>
                </Nav.Link>
              </Nav>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch) => ({
  logOut: (data) =>
    dispatch({
      type: LOG_OUT,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
