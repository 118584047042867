import React from "react";
import "./style.css";
//
function LandingSection({
  hideBackground,
  title,
  description,
  children,
  backgroundColor,
}) {
  return (
    <div
      className="landingSection_main"
      style={{
        background: hideBackground
          ? ""
          : backgroundColor
          ? backgroundColor
          : "var(--info)",
      }}
    >
      <div className="landingSection_body_wrapper">
        <div className="landingSection_header">
          {title ? (
            <div className="landingSection_header_title">{title}</div>
          ) : null}
          {description ? (
            <div className="landingSection_header_description">
              {description}
            </div>
          ) : null}
        </div>
        {children || null}
      </div>
    </div>
  );
}

export default LandingSection;
