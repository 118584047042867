import { React, useEffect, useState } from "react";
import axios from "axios";
import { SALE_PRODUCT_LIST, SHIPING_FORM } from "../../constant";
import { POST } from "../../constant/apiMethods";
import { connect } from "react-redux";
import Labels from "../../services/apiLabels";
import { useLocation } from "react-router-dom";
import "./style.css"
import { useHistory } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import TextField from "@mui/material/TextField";
import PuffLoader from "react-spinners/ClipLoader";
export const Shping = ({sumit_form,addresform}) => {

  let [loading, setLoading] = useState("false");
  const [divshow, setdivshow] = useState("false");

    document.body.style.background = "#F2F4F5"
    const [inpvalue, setInpvalue] = useState();

    const [postcode, setpostcode] = useState();
    const [desp, setdesp] = useState();

    

// console.log("inp value ============",addresform)
const location = useLocation();



const history = useHistory();


useEffect(() => {
  // console.log("inp value ===222222222=========",addresform)
  if(addresform){
    history.push("/winer")
    // console.log("inp value ======4444444444444444======",addresform)
  }

}, [addresform])






const addres_set=(data)=>{
  setInpvalue(data)
  setdivshow("false")
}



    const [citeslist, setciteslist] = useState([]);


// console.log("citexs lisstttt",citeslist)

    const citylisthandler = (e) => {
      setInpvalue(e.target.value)
      if(e.target.value.length > 1){
          // setInpvalue(e.target.value)
          get_all_cites_list(e.target.value)
          setLoading("true")
       
      }
    

if(e.target.value ==""){
  setdivshow("false")



}


  }



    const apikey = "xW_B8I_4kU-jI8cS_DScag35212"

    function get_all_cites_list(a) {
        axios({
            method: "get",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },

            url: `https://api.getAddress.io/autocomplete/${a}?api-key=${apikey} `,
        })
            .then(function (response) {
                
              
            
                if (response.data.suggestions.length > 0) {
                    setciteslist(response.data.suggestions)
                }
                setLoading("false")
                setdivshow("true")
            })
            .catch(function (error) {
                
            })

    }
    const User_Id=JSON.parse(localStorage.getItem('WFAC_localstorage_auth'))

const submit_form_data=()=>{
  sumit_form({
    postcode:postcode,
    inpvalue:inpvalue,
    desp:desp,
    product:location.state.product_id,
    user:User_Id.id
  })

}







  return (
    <>
      <div className="containerfluid contact_wrap p-5">
        <h2 className="text-center">Shiping Form</h2>
        <div className="row d-flex justify-content-center ">
          <div className="col-lg-4 col-md-5 col-sm-12 ">
            <h3 className="inp_heading"> City Post Code</h3>
            <div className="inp_wrap my-3">
              <input
                onChange={(e) => {
                  setpostcode(e.target.value);
                }}
                lassName="inp_contact"
                placeholder="City Post Code "
              ></input>
            </div>
            <h3 className="inp_heading"> Description</h3>
            <div className="inp_wrap my-3">
              <input
                onChange={(e) => {
                  setdesp(e.target.value);
                }}
                lassName="inp_contact"
                placeholder="tell some thing "
              ></input>
            </div>

            <h3 className="inp_heading">Address</h3>
            <div className="mt-2 inp_main abca" id="inp_main ">
              <input
                type=""
                className="inp_inp "
                placeholder="srearch"
                onChange={citylisthandler}
                value={inpvalue}
              ></input>

              <div className="srech_">
                {loading == "true" ? <PuffLoader /> : <FaSearch size={17} />}
              </div>
            </div>
            {divshow == "true" ? (
              <div className="mt city_list">
                {
                  // citeslist.suggestions.length > 0 ?
                  citeslist.map((item) => {
                    return (
                      <>
                        <ul>
                          <li
                            className="lii"
                            onClick={() => {
                              addres_set(item.address);
                            }}
                          >
                            {" "}
                            {item.address}
                          </li>
                        </ul>
                      </>
                    );
                  })

                  // :
                  // <div></div>
                }
              </div>
            ) : (
              ""
            )}

            <button
              name="CTAButton"
              className=" btn_contact my-3"
              onClick={submit_form_data}
            >
              Sumit Form
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  cityes_List_Data: state.cardeailupdate,
  addresform: state.shiping.addresform,
});
const mapDispatchToProps = (dispatch) => ({
  // get product list
  sumit_form: (data) =>
    dispatch({
      type: SHIPING_FORM,
      payload: {
        type: SHIPING_FORM,
        method: POST,
        label: Labels.shiping_form,
        hideOverlayLoader: true,
        data,
      },
    }),

  //
});

export default connect(mapStateToProps, mapDispatchToProps)(Shping)