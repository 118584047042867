import { sagaRoot } from "./root";
import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  APPOINTMENT_CREATE,
  SHOW_INTEREST,
  EVALUATION_CREATE,
  OFFICE_TIMING,
  MY_INTERSTE,
  MY_APPOINMENT,
  CAR_DETAILE,
  REMOVE_INTEREST,

} from "../constant";
// get all cars brands
export function* postvendorinterest(payload) {
  const response = yield call(sagaRoot, payload);
}
// get my inrest
export function* getvendorinterest(payload) {
  const response = yield call(sagaRoot, payload);
}
// my appoinment
export function* myappointment(payload) {
  const response = yield call(sagaRoot, payload);
}
// get car detail
export function* getcardetail(payload) {
  const response = yield call(sagaRoot, payload);
}
//remove car detail
export function* removecardata(payload) {
  const response = yield call(sagaRoot, payload);
}




// saga watcher
export default function* authWatcher() {
  yield takeLatest(MY_INTERSTE, getvendorinterest);
  yield takeLatest(SHOW_INTEREST, postvendorinterest);
  yield takeLatest(MY_APPOINMENT, myappointment);
  yield takeLatest(CAR_DETAILE, getcardetail);
  yield takeLatest(REMOVE_INTEREST, removecardata);
}
