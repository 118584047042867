import React, {useEffect}from "react";
//
import "./style.css";
// //
import { BiTransfer, BiCar, BiKey } from "react-icons/bi";
// //
import { GiCarKey } from "react-icons/gi";
import Form from "../../components/CarDetailForm";
import Banner from "./Banner/index";
import Works from "./Works";
import LandingSection from "../../components/LandingSection/index";
import CustomerCard from "../../components/CustomerCard/index"
import Howwork from "../../components/HowWorks/index"
import What from "../../components/What/index"
import Footer from "../../components/Footer/index"
//
import { useContext } from "react";
import FeedbackContext from "../../context/cmscontext";
import { scrollTop } from "../../utils/window";
function Landingmain(props) {
  const { navbarDimensions } = props;
  

   const { feedback, isLoading } = useContext(FeedbackContext);
   var home_page = feedback.home_page;
  
  // we offer card list


  useEffect(() => {
 
    scrollTop()
 
  }, [])





  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };


  const weOffer = [
    {
      title: `${home_page.buy_car_heading}`,
      description: `${home_page.buy_car_desp}`,
      Icon: BiCar,

      onClick: () => openInNewTab("https://dealers.sellanywheel.com/buynow"),
    },
    {
      title: `${home_page.sell_your_car}`,
      description: `${home_page.sell_your_car_desp}`,
      Icon: GiCarKey,
      onClick: () => openInNewTab("https://sellanywheel.com"),
    },
    {
      title: `${home_page.exchange_car}`,
      description: `${home_page.exchange_car_desp}`,
      Icon: BiTransfer,
      title__: `${home_page.exchange_car_desp}`,
      // onClick: () => openInNewTab('https://google.com'),z
    },
  ];

  return (
    <div className="landingmain_main_cont">
      <Banner
        style={{
          width: "100%",
          height: window.innerHeight - navbarDimensions.height,
        }}
      />
      <br></br>
      <LandingSection hideBackground title={home_page.what_doest}>
        <div className="offer_card_row mt-4">
          {React.Children.toArray(
            weOffer.map((item, index) => {
              const { title, title__, description, Icon, onClick } = item;
              return (
                <button onClick={onClick} className="cardShadow offer_card">
                  <Icon className="offer_card_title offer_card_title_icon" />
                  <div className="offer_card_title offer_card_title_text">
                    {title}
                  </div>
                  <div className="offer_card_description">{description}</div>
                  <div className="offer_card_title offer_card_title_text">
                    {title__}
                  </div>
                  <br />
                </button>
              );
            })
          )}
        </div>
      </LandingSection>
      <What home_page={home_page}/>

      {/* <LandingSection
        title={home_page.what_expect_heading}
        description={home_page.what_expect_desp}
      >
        {home_page.what_expect_desp2}
      </LandingSection> */}

      <CustomerCard />

      <Howwork />
    </div>
  );
}

export default Landingmain;

// import React from "react";
// import { Switch, Route } from "react-router-dom";
// //
// import Landingmain from "./Landingmain";
//  //
// function LandingPage(props) {
//   const { navbarDimensions } = props;
//   return (
//     <>

//     {/* <Switch>
//       <Route exact path="/"> */}
//         <Landingmain navbarDimensions={navbarDimensions} />
//       {/* </Route>
//       <Route path="/evaluation">
//         <Evaluation  />
//       </Route>
//     </Switch> */}
//     </>
//   );
// }

// export default LandingPage;
