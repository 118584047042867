import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import Data from './data/datafile'

import { PersistGate } from 'redux-persist/integration/react'



  
  // Data=av

  fetch(`/data/datafile.json`).then((response) => {
    response.json().then((data) => {
      ReactDOM.render(
        <React.StrictMode>
          <Provider store={store.store}>
          <PersistGate loading={null} persistor={store.persistor}>
            <BrowserRouter>
              <App data={data} />
            </BrowserRouter>
            </PersistGate>
          </Provider>
        </React.StrictMode>,
        document.getElementById("root")
      );
    });
  });





// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
