import { MY_INTERSTE, REMOVE_INTEREST } from "../constant/index";

const INITIAL_STATE = {
  loading: false,
  data: [],
  loadingRemoveInterest: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${MY_INTERSTE}_REQUEST`:
      return { ...state, loading: true };
    case `${MY_INTERSTE}_SUCCESS`:
      
      return { ...state, data: action.payload, loading: false };
    case `${MY_INTERSTE}_FAIL`:
      return { ...state, loading: false };
    case `${MY_INTERSTE}_ERROR`:
      return { ...state, loading: false };
    //

    case `${REMOVE_INTEREST}_REQUEST`:
      return { ...state, loadingRemoveInterest: true };
    case `${REMOVE_INTEREST}_SUCCESS`:
      let updatedData = state.data.filter(
        (element) => element.car_ref != action.payload.deleted_id
      );
      return {
        ...state,
        loadingRemoveInterest: false,
        data: updatedData,
      };
    case `${REMOVE_INTEREST}_FAIL`:
      return { ...state, loadingRemoveInterest: false };
    case `${REMOVE_INTEREST}_ERROR`:
      return { ...state, loadingRemoveInterest: false };
  }

  return state;
};
