import React from "react";
//
import Form from "../../components/Form";
import Dropdown from "../../components/Form/Dropdown";
import IconInput from "../../components/Form/Input";
//

import { useHistory } from "react-router-dom";


import { connect } from "react-redux";
import { EVALUATION_CREATE, SELL_MY_CAR_STEPS } from "../../constant";
import { POST } from "../../constant/apiMethods";
import Labels from "../../services/apiLabels";
import Step6 from "./Step6";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step7 from "./Step7";
import Step8 from "./Step8";
//
import { scrollTop } from "../../utils/window";
// 
function EvaluationSteps(props) {
  const {
    currentStep,
    setcurrentStep,
    //
    step1,
    //
    reg_number,
    mileage,
    previous_owners,
    services_history,
    setreg_number,
    setmileage,
    setprevious_owners,
    setservices_history,
    //
    setuser_name,
    setuser_email,
    setuser_postcode,
    setuser_mobile_number,
    //
    user_name,
    user_email,
    user_postcode,
    user_mobile_number,
    //
    postEvaluationCreate,
    loading_evaluation_create,
  } = props;
  const formanimationClassname = "animate__animated animate__zoomIn";
  const step2OnSubmitHandler = () => {
    if (
      mileage.isSelected &&
      previous_owners.isSelected &&
      services_history.isSelected &&
      reg_number
    )
      setcurrentStep(currentStep + 1);
    scrollTop();
  };
  //
  const renderServiceHistoryDetail = (e) => {
    switch (e) {
      case "Full":
        return "All recomended serevices on time ";
      case "Some":
        return "Some recomended serevices are missed ";
      case "None":
        return "No services are done";
      case "First not Due":
        return "Recomended service is due";

      default:
        return "";
    }
  };
  //
  const postEvaluationCreateHandler = () => {
    if (
      step1.variant.isSelected &&
      mileage.isSelected &&
      previous_owners.isSelected &&
      services_history.isSelected &&
      user_name &&
      user_email &&
      user_postcode &&
      user_mobile_number &&
      reg_number
    ) {
      postEvaluationCreate({
        car: step1.variant.current.value,
        Mileage: mileage.current.title,
        reg_number,
        Previous_owners: previous_owners.current.id,
        Service_history: services_history.current.id,
        Website_user: user_name,
        Email: user_email,
        Postcode: user_postcode,
        Mobile_number: user_mobile_number,
      });
      scrollTop();
    } else {

    }
  };

  const history = useHistory();





  return (
    <>
      {/* ============================== step 2 form ============================== */}
      {currentStep === 2 ? (
        <Form
          onSubmit={step2OnSubmitHandler}
          buttonText="Continue"
          className={formanimationClassname}
        >
          {/* Registration Number*/}
          <IconInput
            // loading={loading_evaluation_create}
            label="Registration Number"
            placeholder="Enter Registration Number"
            onChangeText={setreg_number}
            value={reg_number}
          />
          {/* mileage */}
          <IconInput
            label="Mileage"
            placeholder="Enter Mileage"
            onChangeText={(e) =>
              setmileage({
                ...mileage,
                isSelected: e.length > 0,
                current: { id: "", title: e },
              })
            }
            value={mileage.current.title}
          />
          {/* previous_owners */}
          <Dropdown
            label="Previous Owners"
            onSelect={(item) =>
              setprevious_owners({
                ...previous_owners,
                isSelected: true,
                current: item,
              })
            }
            selectedValue={previous_owners.current}
            loading={previous_owners.fetching}
            disabled={false}
            data={[
              { title: 1, id: 1 },
              { title: 2, id: 2 },
              { title: 3, id: 3 },
              { title: 4, id: 4 },
              { title:  "5 or more", id: 5 },
              { title: "Don't know ", id: 6 },
            ]}
          />
          {/* services_history */}
          <Dropdown
            label="Services History"
            onSelect={(item) =>
              setservices_history({
                ...services_history,
                isSelected: true,
                current: item,
              })
            }
            selectedValue={services_history.current}
            loading={services_history.fetching}
            disabled={false}
            data={[
              { title: "Full", id: 1 },
              { title: "Part", id: 2 },
              { title: "None", id: 3 },
              { title: "First not Due", id: 4 },
            ]}
          />
          {services_history.isSelected && (
            <div style={{ margin: "0 1rem" }}>
              {renderServiceHistoryDetail(services_history.current.title)}
            </div>
          )}
        </Form>
      ) : null}
      {/* ============================== step 3 form ============================== */}
      {currentStep === 3 ? (
        <Form
          onSubmit={postEvaluationCreateHandler}
          buttonText="Get my valuation"
          className={formanimationClassname}
          loading={loading_evaluation_create}
          loadingText="Getting your valuation ..."
        >
          {/* uesr name */}
          <IconInput
            loading={loading_evaluation_create}
            label="Your Name"
            placeholder="Your Name"
            onChangeText={setuser_name}
            value={user_name}
          />
          {/* uesr email */}
          <IconInput
            loading={loading_evaluation_create}
            type="email"
            label="Your Email"
            placeholder="Your Email"
            onChangeText={setuser_email}
            value={user_email}
          />
          {/* uesr postcode */}
          <IconInput
            loading={loading_evaluation_create}
            label="Your Postcode"
            placeholder="Your Postcode"
            onChangeText={setuser_postcode}
            value={user_postcode}
          />
          {/* uesr mobile_number */}
          <IconInput
            loading={loading_evaluation_create}
            label="Your Mobile Number"
            placeholder="Your Mobile Number"
            onChangeText={setuser_mobile_number}
            value={user_mobile_number}
          />
        </Form>
      ) : null}
      <div className="w-100" style={{ padding: "0.5rem" }}>

      {/* {currentStep === 8 && (
          <Step8 formanimationClassname={formanimationClassname} />
        )} */}

        {currentStep === 4 && (


// history.push("/carsteps")

          <Step4 formanimationClassname={formanimationClassname} />
        )}
        {/* {currentStep === 5 && (
          <Step5 formanimationClassname={formanimationClassname} />
        )}
        {currentStep === 6 && (
          <Step6 formanimationClassname={formanimationClassname} />
        )}
        {currentStep === 7 && (
          <Step7 formanimationClassname={formanimationClassname} />
        )}
       */}
      </div>
      {/* <button onClick={() => setcurrentStep(currentStep - 1)}>Back</button>
      <button onClick={() => setcurrentStep(currentStep + 1)}>forward</button> */}
    </>
  );
}

const mapStateToProps = (state) => ({
  loading_evaluation_create: state.sellMyCar.loading,
  step1: state.sellMyCar.step1,
  reg_number: state.sellMyCar.step2.reg_number,
  mileage: state.sellMyCar.step2.mileage,
  previous_owners: state.sellMyCar.step2.previous_owners,
  services_history: state.sellMyCar.step2.services_history,
  // step 3 user info
  user_name: state.sellMyCar.user.name,
  user_email: state.sellMyCar.user.email,
  user_postcode: state.sellMyCar.user.postcode,
  user_mobile_number: state.sellMyCar.user.mobile_number,
});
const mapDispatchToProps = (dispatch) => ({
  setmileage: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "mileage",
        data,
      },
    }),
  setprevious_owners: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "previous_owners",
        data,
      },
    }),
  setservices_history: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "services_history",
        data,
      },
    }),
  setreg_number: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "reg_number",
        data,
      },
    }),
  // step 4
  setuser_name: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "user_name",
        data,
      },
    }),
  setuser_email: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "user_email",
        data,
      },
    }),
  setuser_postcode: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "user_postcode",
        data,
      },
    }),
  setuser_mobile_number: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "user_mobile_number",
        data,
      },
    }),
  
  // post EVALUATION_CREATE
  postEvaluationCreate: (data) => {
    return dispatch({
      type: EVALUATION_CREATE,
      payload: {
        type: EVALUATION_CREATE,
        method: POST,
        label: `${Labels.evaluation_create}`,
        hideOverlayLoader: true,
        data,
      },
    });
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(EvaluationSteps);
