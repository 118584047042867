import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { Select } from "antd";
import { BsCheck2 } from "react-icons/bs";
//
export default function Dropdown(props) {
  const {
    label,
    data,
    defaultValue,
    onSelect,
    titleKey,
    idKey,
    disabled,
    loading,
    selectedValue,
  } = props;
  const { Option } = Select;
  const children = [];
  const titleKey_ = titleKey || "title";
  const idKey_ = idKey || "id";
  if (data)
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      children.push(
        <Option key={i}>
          <div className="d-flex flex-row align-items-center">
            <span style={{ flex: 1 }}>{item[titleKey_]}</span>
            {item[idKey_] === selectedValue[idKey_] ? (
              <BsCheck2 color="var(--primary)" size={20} />
            ) : null}
          </div>
        </Option>
      );
    }

  function onSelectHandler(index) {
    // 
    onSelect(data[index]);
  }
  // checks if dropdown list contain only one element then set is as selected value
  useEffect(() => {
    if (data.length === 1 && !selectedValue[idKey_]) onSelectHandler(0);
  }, [data]);
  //
  return (
    <div className="dropdown_wrapper">
      <div>{label}:</div>
      <Select
        loading={loading}
        disabled={disabled}
        showSearch={true}
        // allowClear
        style={{ width: "100%" }}
        placeholder={`${loading ? "Loading" : "Select"} ${label} `}
        onChange={onSelectHandler}
        value={selectedValue[titleKey_]}
      >
        {children}
      </Select>
    </div>
  );
}
