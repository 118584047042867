import React, { Fragment, useState, useEffect } from "react";
import { AiTwotoneEye, AiOutlineClockCircle } from "react-icons/ai";
import { BsDot } from "react-icons/bs";
import { GoLocation } from "react-icons/go";
import "./produ.css";
import "./ListCard.css";
import pic2 from "../../assets/svg/car_no_image.jpg";

import ProductMain from "../Product View/ProductMain";
import { Card, Container, Row } from "react-bootstrap";
import WishListButton from "../Product list/WishListButton";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
} from "react-router-dom";

function Car_buy_card({Products}) {
  return (
    <div
    className="position-relative  container-fluid product_list_card_main_cont_div"
    style={{ overflow: "hidden" }}
  >
    <WishListButton />
    <Link
      excat
      to={{
        pathname: "/productbuy",
        state: { product_code: Products.product_id },
      }}
      style={{ color: "inherit", textDecoration: "inherit" }}
      className="container-fluid product_list_card_main_cont imageHover "
    >
      {/* <button className="counter_buy_now_cont  m-1">
      <span className="counter_buy_now_text">{timer ? timer : "SOLD"}</span>
    </button> */}

<div className="row">
<div className="col-5 p-0">
        <div className="product_list_card_image_wrapper">
          <img

style={{

 
    "transition":" transform 0.2s",
    "width": "100%",
    "height": "100%",
    "margin":" 0 auto",
    "objectFit":"contain"
    // "object-fit": "contain",


}}

            className="product_list_car_image"
            src={Products.main_image}
            alt="banner"
          />
        </div>
      </div>
      <div className="col-7 align-self-start  product_list_card_col_2  p-5">
        {/*  */}

        <span className="product_list_card_title">
         Gli Car New Model
        </span>
        
        <div className="mt-2">
        {Products.product_feature
              ? Products.product_feature.slice(0, 3).map((item, index) => (
                  <span className="product_list_card_features_text">
                    {item.name}: {item.value}
                  </span>
                ))
              : null}
         </div>




      
      </div>
</div>

     
    </Link>
  </div>
  )
}

export default Car_buy_card