import React,  { useEffect } from "react";
import Carpic from "../../assets/images/h1.jpg";
import Carpic2 from "../../assets/images/framecar1.png";
import Carpic3 from "../../assets/images/hassan2.jpg";
import "./style.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { SALE_PRODUCT_LIST } from "../../constant";
import { GET } from "../../constant/apiMethods";
import { connect } from "react-redux";
import Labels from "../../services/apiLabels";
import { useContext } from "react";
import FeedbackContext from "../../context/cmscontext";
import Slider from "react-slick";
function CustomerCard(props) {

  const { product,productList,SaleProductList } = props;
   const { feedback, isLoading } = useContext(FeedbackContext);
   var home_page = feedback.home_page;

useEffect(() => {


  SaleProductList()


  // console.log("sale product list ==============>>>>>>>>use effect")

}, [])


const arr = [
  {
    name: "gli",
  },
];


// console.log("sale product list",productList)







  const settings = {
    arrows: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    vertical: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 1500,
    cssEase: "linear",
  };

  const settingsa = {
    vertical: false,
    arrows: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };





  return (
    <>
      <div className="container coutomer_wrap p-5">
        <h2 className="text-center">{home_page.happy_customers_heading}</h2>

        <p className="my-3 text-center">{home_page.happy_customers_desp}</p>

        {false ? (
          <div className="row-25 card_wrap_main py-5 ">
            <Slider {...settings}>
              {React.Children.toArray(
                [...productList, ...productList, ...productList].map((item) => {
                  return (
                    <div className="card_wrap w___ mr-5">
                      <div className="row row-25">
                        <div className="col-5 m_pic_wrap ">
                          <img
                            src={item.main_image}
                            className="card_pic_clas_m"
                          />
                        </div>
                        <div className="col-6 col_six_clas p-2">
                          {/* <h4>Muhammad Hassan</h4> */}
                          <span className="customer_name ">
                            {" "}
                            {item.product_name}{" "}
                          </span>
                          <p className="mt-1">
                            {/* {item.product_description} */}
                            {item.product_description}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </Slider>
          </div>
        ) : (
          <div className="row-25 card_wrap_main py-5 ">
            <Slider {...settings}>
              {React.Children.toArray(
                [...arr, ...arr, ...arr, ...arr].map((item) => {
                  return (
                    <div className="card_wrap w___ mr-5">
                      <div className="row row-25">
                        <div className="col-5 m_pic_wrap ">
                          <img src={Carpic2} className="card_pic_clas_m" />
                        </div>
                        <div className="col-6 col_six_clas p-2">
                          {/* <h4>Muhammad Hassan</h4> */}
                          <span className="customer_name "> New Gli Car</span>
                          <p className="mt-1">
                            {/* {item.product_description} */}
                            Sell Any Wheel bought my car within two hours. It
                            was the most swift transaction save a lot of time!
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </Slider>
          </div>
        )}

        {/* =========================web slider ======================= */}

        {false ? (
          <div className="row-25 card_wrap_main_mobile py-5  ">
            <Slider {...settingsa}>
              {React.Children.toArray(
                [...productList, ...productList, ...productList].map((item) => {
                  return (
                    <div className="card_wrap mx-5">
                      <div className="row row-25 ">
                        <div className="col-4 p-3">
                          <img
                            className="card_pic_clas"
                            src={item.main_image}
                          />
                        </div>
                        <div className="col-6 pt-2 ">
                          {/* <h4>Muhammad Hassan</h4> */}
                          <span className="customer_name ">
                           {item.product_name}{" "}
                          </span>
                          <p className="mt-2">{item.product_description}</p>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </Slider>
          </div>
        ) : (
          <div className="row-25 card_wrap_main_mobile py-5  ">
            <Slider {...settingsa}>
              {React.Children.toArray(
                [...arr, ...arr, ...arr, ...arr].map((item) => {
                  return (
                    <div className="card_wrap mx-5">
                      <div className="row row-25 ">
                        <div className="col-4 p-3">
                          <img className="card_pic_clas" src={Carpic2} />
                        </div>
                        <div className="col-6 pt-2 ">
                          {/* <h4>Muhammad Hassan</h4> */}
                          <span className="customer_name ">
                            Abdullah /  Xli Car New
                          </span>
                          <p className="mt-2">
                            {item.product_description}
                            Sell Any Wheel bought my car within two hours. It
                            was the most swift transaction and helped me save a
                            lot of time!
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </Slider>
          </div>
        )}
      </div>
    </>
  );
}




const mapStateToProps = (state) => ({
  
  productList: state.cardetail.productlist,
 
});
const mapDispatchToProps = (dispatch) => ({
  // get product list
  SaleProductList: (data) =>
    dispatch({
      type: SALE_PRODUCT_LIST,
      payload: {
        type: SALE_PRODUCT_LIST,
        method: GET,
        label: Labels.sale_product,
        hideOverlayLoader: true,
      },
    }),
  //
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerCard);


