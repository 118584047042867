import{ React,useState} from 'react'
import { connect } from 'react-redux'
import "./style.css"


import Labels from "../../services/apiLabels";
import { POST, GET } from "../../constant/apiMethods";
import { SHOW_INTEREST, FORGET_PASSWORD } from "../../constant";
// import { connect } from "react-redux";
export const Forget = ({passworedReset}) => {



    document.body.style.background = "#F2F4F5"

const [email, setemail] = useState("")

const reSetPassword=()=>{
  passworedReset({
    email:email
  })


}


  return (
    <>
      <div className="containerfluid contact_wrap p-5">
        <h2 className="text-center mt-5">Forget Password</h2>
        <div className="row text-center d-flex justify-content-center ">
          <div className="col-lg-4 col-md-5 col-sm-12 ">
            <div className="inp_wrap my-3">
              <input
                value={email}
                onChange={(e) => {
                  setemail(e.target.value);
                }}
                className="inp_contact"
                placeholder="email address "
              ></input>
            </div>

            <button
              name="CTAButton"
              className=" btn_contact my-3"
              onClick={reSetPassword}
            >
              Forget Password
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
 passworedReset: (data) =>
    dispatch({
      type: FORGET_PASSWORD,
      payload: {
        type: FORGET_PASSWORD,

        label: Labels.password_Reset,
        method: POST,
        data,
        hideOverlayLoader: true,
      },
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Forget)