import React, { useEffect, useState } from "react";

import "./style.css";
import axios from "axios";

import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { SALE_PRODUCT_LIST, ADD_CAR_FEATURES ,CHAKBOX_LIST} from "../../constant";
import { POST } from "../../constant/apiMethods";
import { connect } from "react-redux";
import Labels from "../../services/apiLabels";
import { scrollTop } from "../../utils/window";
function IputTypes(props) {
  const { inp_chk_action, xxxxx, cityes_List_, data ,userinfo,divindex,index_of} = props;
  const [slectdiv, setslectdiv] = useState(true);
  const [questionid, setquestionid] = useState();
  const [clr, setclr] = useState(true)
  const input_click = (e) => {
  };
  const Chkboxvaluehandler = async (e,event)  => {
    if(event.target.checked){
      var feature=[];
      if(divindex[index_of]){
         feature  =divindex[index_of];
        } 
        inp_chk_action({

          div:[...feature, e.choice_id],
          index:index_of
        })
    }
    else{
      const feature  = divindex[index_of];
      var index = feature.indexOf(e.choice_id);
      if (index !== -1) {
        feature.splice(index, 1);
        inp_chk_action({
          info:feature,
          div:feature,
          index:index_of
        })
      }
    }
  };


  useEffect(() => {
    scrollTop();
  }, []);


  const inpboxvaluehandler = async (e,id)  => {
      const d = [e.choice_id];
      inp_chk_action({
        info:d,
        div:d,
        index:index_of
      })
  };

  return (
    <>
    <br></br>
      <div className="showInterest_headingCont ">
        <div className="showInterest_heading">
        {data["question"]}
        </div>
        <div className="showInterest_description">  {data["select"] == "1"
            ? "Select one from the following"
            : "Choose that applies to your car"}</div>
      </div>
      <div className="container inp_main_wrappa">
        <div className="main_Wrap col-lg-5 col-md-5 col-sm-12 ">
          {React.Children.toArray(data["choices"].map((item,id) => {
            return (
              <>
                {data["select"] == "1" ? (
                  <div
                    id="inp1"
                    className={
                      divindex[index_of]
                        ? divindex[index_of].indexOf(item.choice_id) != -1
                          ? "inp_wrapx text-center bclas my-2"
                          : "inp_wrapx aclas text-center my-2"
                        : "inp_wrapx aclas text-center my-2"
                    }
                    onClick={() => {
                      inpboxvaluehandler(item, id);
                    }}
                  >
                    <span className="text-center">{item.name}</span>
                  </div>
                ) : (
                  <div
                    className={
                      divindex[index_of]
                        ? divindex[index_of].indexOf(item.choice_id) != -1
                          ? " inp_wrapx_ bclas_ my-2 d-flex"
                          : " inp_wrapx_ my-2 d-flex"
                        : " inp_wrapx_ my-2 d-flex"
                    }
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={item.name}
                            checked={
                              divindex[index_of]
                                ? divindex[index_of].indexOf(item.choice_id) !=
                                  -1
                                  ? true
                                  : false
                                : false
                            }
                            onChange={(event) => {
                              Chkboxvaluehandler(item, event);
                            }}
                            sx={{
                              color: "gray",
                              "&.Mui-checked": {
                                color: "#178971",
                              },
                            }}
                          />
                        }
                        label={item.name}
                      />
                    </FormGroup>
                  </div>
                )}
              </>
            );
          }))}
        </div>
      </div>

   
    </>
  );
}

const mapStateToProps = (state) => ({
  cityes_List_Data: state.cardeailupdate,
  divindex: state.cardeailupdate.divindex,
  userinfo: state.cardeailupdate.userinfo,
});
const mapDispatchToProps = (dispatch) => ({
  
  inp_chk_action: (data) =>
    dispatch({
      type: CHAKBOX_LIST,
      payload: {
        data,
      },
    }),



  carfeatureadd: (data) =>
    dispatch({
      type: ADD_CAR_FEATURES,
      payload: {
        type: ADD_CAR_FEATURES,
        method: POST,
        label: Labels.add_feaderes,
        hideOverlayLoader: true,
        data,
      },
    }),
  //
});

export default connect(mapStateToProps, mapDispatchToProps)(IputTypes);
