/* eslint-disable no-unused-vars */
/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable default-case */
// importing types from constants definded in constant/ index file
import {
  PRODUCT_LIST,
  PRODUCT_DETAIL,
  PLACE_BID,
  GET_PRODUCT_BIDDING_GRAPH,
  PRODUCT_LIST_BUY,
  PRODUCT_DETAIL_BUY,
  APPROXY_BID_NEW,
  TIMER_DATA_UPDATE,
} from "../constant/index";

const INITIAL_STATE = {
  fetchingList: true,
  fetchingCurrent: true,
  loadingPlaceBid: false,
  // fetchBiddingGraph: false,
  data: [],
  databuy: [],
  current: {},
  currentbuy: {},
  newtimer:{},

  // currentProductGraph: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // product list
    case `${PRODUCT_LIST}_REQUEST`:
      return {
        ...state,
        fetchingList: true,
      };
    case `${PRODUCT_LIST}_SUCCESS`:
      return {
        ...state,
        fetchingList: false,
        data: action.payload,
      };
    case `${PRODUCT_LIST}_FAIL`:
      return {
        ...state,
        fetchingList: false,
      };
    case `${PRODUCT_LIST}_ERROR`:
      return {
        ...state,
        newtimer: false,
      };



 // product list  buy
 case `${PRODUCT_LIST_BUY}_REQUEST`:
  return {
    ...state,
    fetchingList: true,
  };
case `${PRODUCT_LIST_BUY}_SUCCESS`:
  return {
    ...state,
    fetchingList: false,
    databuy: action.payload,
  };
case `${PRODUCT_LIST_BUY}_FAIL`:
  return {
    ...state,
    fetchingList: false,
  };
case `${PRODUCT_LIST_BUY}_ERROR`:
  return {
    ...state,
    fetchingList: false,
  };

// ===========================  timer data updaate==========================

case TIMER_DATA_UPDATE:
  // console.log("new time action chk", action.payload.data)
  return {
    ...state,
    newtimer:  action.payload.data,
  };

















    // current product buy  detail===========================
    case `${PRODUCT_DETAIL_BUY}_REQUEST`:
      return {
        ...state,
        fetchingCurrent: true,
        currentbuy: {},
      };
    case `${PRODUCT_DETAIL_BUY}_SUCCESS`:
      return {
        ...state,
        fetchingCurrent: false,
        currentbuy: action.payload,
      };
    case `${PRODUCT_DETAIL_BUY}_FAIL`:
      return {
        ...state,
        fetchingCurrent: false,
        currentbuy: {},
      };
    case `${PRODUCT_DETAIL_BUY}_ERROR`:
      return {
        ...state,
        fetchingCurrent: false,
        currentbuy: {},
      };

    // current product detail
    case `${PRODUCT_DETAIL}_REQUEST`:
      return {
        ...state,
        fetchingCurrent: true,
        current: {},
      };
    case `${PRODUCT_DETAIL}_SUCCESS`:
      return {
        ...state,
        fetchingCurrent: false,
        current: action.payload,
      };
    case `${PRODUCT_DETAIL}_FAIL`:
      return {
        ...state,
        fetchingCurrent: false,
        current: {},
      };
    case `${PRODUCT_DETAIL}_ERROR`:
      return {
        ...state,
        fetchingCurrent: false,
        current: {},
      };






    //  place bid
    case `${PLACE_BID}_REQUEST`:
      return {
        ...state,
        loadingPlaceBid: true,
      };
    case `${PLACE_BID}_SUCCESS`:
      return {
        ...state,
        loadingPlaceBid: false,
      };
    case `${PLACE_BID}_FAIL`:
      return {
        ...state,
        loadingPlaceBid: false,
      };
    case `${PLACE_BID}_ERROR`:
      return {
        ...state,
        loadingPlaceBid: false,
      };


// ===============aprocxy bid===================


case `${APPROXY_BID_NEW}_REQUEST`:
  return {
    ...state,
    loadingPlaceBid: true,
  };
case `${APPROXY_BID_NEW}_SUCCESS`:
  return {
    ...state,
    loadingPlaceBid: false,
  };
case `${APPROXY_BID_NEW}_FAIL`:
  return {
    ...state,
    loadingPlaceBid: false,
  };
case `${APPROXY_BID_NEW}_ERROR`:
  return {
    ...state,
    loadingPlaceBid: false,
  };






    // get produdt bidding graph data
    // case `${GET_PRODUCT_BIDDING_GRAPH}_REQUEST`:
    //   return {
    //     ...state,
    //     fetchBiddingGraph: true,
    //     currentProductGraph: {},
    //   };
    // case `${GET_PRODUCT_BIDDING_GRAPH}_SUCCESS`:
    //   return {
    //     ...state,
    //     fetchBiddingGraph: false,
    //     currentProductGraph: action.payload,
    //   };
    // case `${GET_PRODUCT_BIDDING_GRAPH}_FAIL`:
    //   return {
    //     ...state,
    //     fetchBiddingGraph: false,
    //     currentProductGraph: {},
    //   };
  }
  return state;
};
