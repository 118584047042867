/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";
import { AiTwotoneEye, AiOutlineClockCircle } from "react-icons/ai";
import { BsDot } from "react-icons/bs";
import { GoLocation } from "react-icons/go";
import { Card } from "react-bootstrap";
import "./produ.css";
import ProductMain from "../Product View/ProductMain";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
} from "react-router-dom";
import ListCard from "./ListCard";
import ListCardShimmer from "./ListCardShimmer";

function Products(props) {
  const { data, loading } = props;
  if (loading) {
    return (
      <Fragment>
        <ListCardShimmer />
        <ListCardShimmer />
      </Fragment>
    );
  } else {
    return (
      <div className="p-1">
        {data.length > 0 ? (
        data.map((Products, index) => (
            <ListCard index={index} Products={Products} />
          ))
        ) : (
          <span>product list is empty</span>
        )}
      </div>
    );
  }
}

export default Products;
