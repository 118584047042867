/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect } from "react";
// import { AiTwotoneEye, AiOutlineClockCircle } from "react-icons/ai";
// import { BsDot } from "react-icons/bs";
// import { GoLocation } from "react-icons/go";
import GridCardShimmer from "./GridCardShimmer";
import "./produ.css";
import { Container, Row, Col } from "react-bootstrap";
import GridCard from "./GridCard";

const Grid = (props) => {
  const { data, Card } = props;
  return (
    <div className="grid_container">
      <div className="grid_card_wrapper">
        {data.map((e) => (
          <div className="grid_card">
            <Card Products={e} />
          </div>
        ))}
      </div>
    </div>
  );
};

function Products(props) {
  const { data, loading } = props;
  if (loading) {
    return <Grid data={[{}, {}]} Card={GridCardShimmer} />;
  }

  return <Grid data={data} Card={GridCard} />;
}

export default Products;
