import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import { BsFillCheckCircleFill } from "react-icons/bs";
import { BiRevision } from "react-icons/bi"
import Form from "../../components/Form";
import Dropdown from "../../components/Form/Dropdown";
import IconInput from "../../components/Form/Input";
import "./style.css"
import { useHistory, useParams } from "react-router-dom";
import { MdPending } from "react-icons/md";
import { CAR_DATA_UPDATE_STEPS,SET_APPOINTMENT_DATA,USER_UPDATE } from "../../constant";
import axios from "axios";
import { useContext } from "react";
import { PUT } from "../../constant/apiMethods";
import Labels from "../../services/apiLabels";
import FeedbackContext from "../../context/cmscontext";
import config from "../../config";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { styled } from "@mui/material/styles";
import ProgressBar from "react-bootstrap/ProgressBar";
export const CarSteps = (props) => {

  
  const { id } = useParams();

 const { feedback, isLoading } = useContext(FeedbackContext);

    var car_steps_page = feedback.car_steps_page;
    const {
      step1,
      step2,
      step3,
      evaluation,
      evaluation_id_,
      book_appointmt_,
      vehicle_details,
      total_question,
      appoinments,
      vehicle_address,
      add_car_data,
      car_data,
      appointment_update,
      set_apointment_data,
      mycardata,
      totalpics,
      evaluation_create,
      divindex,
      update_user_info,
    } = props;
    const history = useHistory();


console.log("evaluation==================>>>>>>", evaluation);
  
    const [api_loading, setapi_loading] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [name, setName] = useState();
    const [email, setemail] = useState();
    const [number, setnumber] = useState();
    const [open_page, setopen_page] = useState(false);
    useEffect(() => {

  //     if (!id){
  // if (!step1 && !evaluation_id_) {
  //   history.push("/")
  // }
  //     }
      
    
        // 
      }, []);
const baseUrl = `${config.baseUrl}api/v1/single_evaluate_details`;
function loadData(id){
  axios
  .post(baseUrl, {
    evaluate_id: id??evaluation_id_,
  })
.then((response) => {
      console.log("response   -==========", response.data.data);
      add_car_data(response.data.data[0]);
       setapi_loading(false);
      // setavailable(response.data.data.available);
    })
    .catch(
      ({ data, status, statusText, headers, config, request }) => {}
    );

}
      useEffect(() => {

        if(id){
           setapi_loading(true);
        }
        if (id) {

          loadData(id);
         
        }







      }, [])
      
      useEffect(() => {
        if(mycardata.length>0 && open_page){
          var index=0
          history.push({
            pathname: "/myappointmentdetail",
            state: { index },
          });
        }
       
      },[mycardata])

    // const  headers = {
    //   "Content-Type": "application/json",
    // }
// add_car_data()
      function open_details(){
        setopen_page(true)
        set_apointment_data([evaluation])
       
      }

 const now = 60;
  const update_userDetai = () => {
    console.log("name========", name, number);

axios.put(`https://backend.sellanywheel.com/api/v1/update_evaluation_Details/${evaluation?.id}/`, {
    Website_user: name,
    Email: email,
    Mobile_number: number
  })
  .then(function (response) {
    console.log(response);
    if (response.status == '200' )
    setEdit(false);
 update_user_info(name, email, number,evaluation?.id);
 
  })
  .catch(function (error) {
    console.log(error);
  });
//    update_user_info(name, email, number,evaluation?.id);
// setEdit(false);

  }
  const update_User = () => {
    setName(evaluation?.Website_user);
    setnumber(evaluation?.Mobile_number);
    setemail(evaluation?.Email);
     setEdit(true);
}
  return (
    <>
      <div className="showInterest_headingCont ">
        <div className="showInterest_heading">
          {car_steps_page.Main_heading}
        </div>
        <div className="showInterest_description">
          {car_steps_page.main_heading_desp}
        </div>
      </div>

      <div className="container">
        {isEdit ? (
          <div className="m-auto py-5">
            <Form
              onSubmit={update_userDetai}
              className={"m-auto"}
              buttonText={"update "}
            >
              {/* uesr name */}
              <IconInput
                label={"Your Name"}
                placeholder={"name"}
                onChangeText={setName}
                value={name}
              />
              {/* uesr email */}
              <IconInput
                type="email"
                label={"Your Email"}
                placeholder={"example@example.com"}
                onChangeText={setemail}
                value={email}
              />
              <IconInput
                label={"Your Mobile Number"}
                placeholder={"Your Mobile Number"}
                onChangeText={setnumber}
                value={number}
              />
            </Form>
          </div>
        ) : (
          <>
            {api_loading ? (
              <div className="row my-5">
                <ShimmerThumbnail height={150} rounded />
                <ShimmerThumbnail height={150} rounded />
                <ShimmerThumbnail height={150} rounded />
              </div>
            ) : (
              <>
                <div className="row my-5">
                  <div className="">
                    <div className="car_card_step">
                      <div className="card_content col-8 col-xs-12">
                        <div className=" row d-flex">
                          <h3>{car_steps_page.Contact_Details}</h3>
                          <p>{car_steps_page.Your_Contact_Information}</p>
                          {step1 ? (
                            <span>
                              <BsFillCheckCircleFill
                                size={15}
                                className="cici"
                              />
                              &nbsp;{car_steps_page.Contact_Complate_text}
                            </span>
                          ) : (
                            <span>
                              <MdPending size={15} className="cici" /> &nbsp;
                              {car_steps_page.Contact_Pending_text}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-4 col-xs-12">
                        {step1 ? (
                          <button
                            name="CTAButton"
                            className="card_bto"
                            onClick={update_User}
                          >
                            Edit
                          </button>
                        ) : (
                          <button name="CTAButton" className="card_bto">
                            {car_steps_page.Contact_Start_text}
                          </button>
                        )}
                      </div>
                    </div>

                    <div className="prog">
                      <ProgressBar
                        variant="warning"
                        now={step1 ? 100 : 0}
                        label={`${step1 ? 100 : 0}%`}
                        style={{ height: "0.7rem" }}
                        className="prog_clas"
                      />
                    </div>
                  </div>
                </div>

                <div className="row my-5">
                  <div>
                    <div className="car_card_step">
                      <div className="card_content col-8 col-xs-12">
                        <div className=" row d-flex">
                          <h3>{car_steps_page.Vehicle_Details}</h3>
                          <p>{car_steps_page.Vehicle_Information}</p>

                          {step2 &&
                          Math.round(
                            (((vehicle_details.length ?? 0) +
                              (vehicle_address.length ?? 0)) /
                              (total_question + 1)) *
                              100
                          ) == 100 ? (
                            <span>
                              <BsFillCheckCircleFill
                                size={15}
                                className="cici"
                              />
                              &nbsp;{car_steps_page.Vehicle_Complate_text}
                            </span>
                          ) : total_question && vehicle_details ? (
                            vehicle_details.length >= total_question ? (
                              vehicle_address ? (
                                vehicle_address.length > 0 ? (
                                  <span>
                                    <BsFillCheckCircleFill
                                      size={15}
                                      className="cici"
                                    />
                                    &nbsp;{car_steps_page.Vehicle_Complate_text}
                                  </span>
                                ) : (
                                  <span>
                                    <MdPending size={15} className="cici" />{" "}
                                    &nbsp;Vehicle address not provided yet
                                  </span>
                                )
                              ) : (
                                <span>
                                  <BsFillCheckCircleFill
                                    size={15}
                                    className="cici"
                                  />
                                  &nbsp;{car_steps_page.Vehicle_Complate_text}
                                </span>
                              )
                            ) : (
                              <span>
                                <MdPending size={15} className="cici" /> &nbsp;
                                {vehicle_details.length} out of {total_question}{" "}
                                answered
                              </span>
                            )
                          ) : (
                            <span>
                              <MdPending size={15} className="cici" /> &nbsp;
                              {car_steps_page.Vehicle_Pending_text}
                            </span>
                          )}
                          {/* <span>   <BsFillCheckCircleFill size={15} className='cici'/> &nbsp;Complate</span> */}
                        </div>
                      </div>

                      <div className="col-4 col-xs-12">
                        {step2 &&
                        Math.round(
                          (((vehicle_details.length ?? 0) +
                            (vehicle_address.length ?? 0)) /
                            (total_question + 1)) *
                            100
                        ) == 100 ? (
                          <button name="CTAButton">
                            {" "}
                            {car_steps_page.Book_Appoinment_Done_text}
                          </button>
                        ) : total_question && vehicle_details ? (
                          vehicle_details.length >= total_question ? (
                            vehicle_address ? (
                              vehicle_address.length > 0 ? (
                                <button
                                  name="CTAButton"
                                  onClick={() => {
                                    history.push("/carupdate");
                                  }}
                                >
                                  {" "}
                                  Edit
                                </button>
                              ) : (
                                <button
                                  name="CTAButton"
                                  className="card_bto"
                                  onClick={() => {
                                    history.push("/carupdate");
                                  }}
                                >
                                  {vehicle_details.length > 0 ? 'Edit' :'Start' }
                                </button>
                              )
                            ) : (
                              <button
                                name="CTAButton"
                                onClick={() => {
                                  history.push("/carupdate");
                                }}
                              >
                                {" "}
                                Edit
                              </button>
                            )
                          ) : (
                            <button
                              className="card_bto"
                              name="CTAButton"
                              onClick={() => {
                                history.push("/carupdate");
                              }}
                            >
                              {vehicle_details.length > 0 ? 'Edit' :'Start' }
                            </button>
                          )
                        ) : (
                          <button
                            className="card_bto"
                            name="CTAButton"
                            onClick={() => {
                              history.push("/carupdate");
                            }}
                          >
                            {car_steps_page.Book_Appoinment_Start_text}
                          </button>
                        )}
                        {/* <button className='card_bton_w' onClick={()=>{history.push("/carupdate")}}  > Start</button> */}
                      </div>
                    </div>

                    <div className="prog">
                      <ProgressBar
                        now={
                          vehicle_details
                            ? Math.round(
                                (((vehicle_details.length ?? 0) +
                                  (vehicle_address.length ?? 0)) /
                                  (total_question + 1)) *
                                  100
                              )
                            : 0
                        }
                        label={`${
                          vehicle_details
                            ? Math.round(
                                (((vehicle_details.length ?? 0) +
                                  (vehicle_address.length ?? 0)) /
                                  (total_question + 1)) *
                                  100
                              )
                            : 0
                        }%`}
                        style={{ height: "0.7rem" }}
                        className="prog_clas"
                      />
                    </div>
                  </div>
                </div>

                <div className="row my-5">
                  <div>
                    <div className="car_card_step">
                      <div className="card_content col-8 col-xs-12">
                        <div className=" row d-flex">
                          <h3>
                            {" "}
                            <BiRevision
                              className="btnReload"
                              onClick={() => loadData(id)}
                            ></BiRevision>{" "}
                            {car_steps_page.Photos_text}
                          </h3>
                          {step1 ? (
                            <p>{car_steps_page.Photos_desp1}</p>
                          ) : (
                            <p>{car_steps_page.Photos_desp2}</p>
                          )}

                          {/* <span>  hj  <BsFillCheckCircleFill size={15} className='cici'/> &nbsp;Complate</span> */}
                        </div>
                      </div>
                      <div className="col-4 col-xs-12">
                        <button
                          className="min_w"
                          name="CTAButton"
                          onClick={open_details}
                        >
                          See Pictures
                        </button>
                      </div>
                    </div>

                    <div className="prog">
                      <ProgressBar
                        now={Math.round((totalpics / 16) * 100)}
                        label={`${Math.round((totalpics / 16) * 100)}%`}
                        style={{ height: "0.7rem" }}
                        className="prog_clas"
                      />
                    </div>
                  </div>
                </div>

                <div className="row my-5">
                  <div>
                    <div className="car_card_step">
                      <div className="card_content col-8 col-xs-12">
                        <div className=" row d-flex">
                          <h3>{car_steps_page.Book_Appoinment_Details}</h3>
                          <p>{car_steps_page.Book_Appoinment_Information}</p>
                          {step3 ? (
                            <span>
                              <BsFillCheckCircleFill
                                size={15}
                                className="cici"
                              />
                              &nbsp;
                              {car_steps_page.Book_Appoinment_Complate_text}
                            </span>
                          ) : appoinments ? (
                            appoinments.length > 0 ? (
                              <span>
                                <BsFillCheckCircleFill
                                  size={15}
                                  className="cici"
                                />
                                &nbsp;
                                {car_steps_page.Book_Appoinment_Complate_text}
                              </span>
                            ) : (
                              <span>
                                <MdPending size={15} className="cici" /> &nbsp;
                                {car_steps_page.Book_AppoinmentPending_text}
                              </span>
                            )
                          ) : (
                            <span>
                              <MdPending size={15} className="cici" /> &nbsp;
                              {car_steps_page.Book_AppoinmentPending_text}
                            </span>
                          )}
                          {/* <span>   <BsFillCheckCircleFill size={15} className='cici'/> &nbsp;Complate</span> */}
                        </div>
                      </div>

                      <div className="col-4 col-xs-12">
                        {step3 ? (
                          <button name="CTAButton" onClick={() => {
                                if (book_appointmt_) {
                                  history.push("/bookappointmentnew");
                                } else {
                                  history.push("/bookappointment");
                                }
                              }}>
                            {" "}
                           Edit
                          </button>
                        ) : appoinments ? (
                          appoinments.length > 0 ? (
                                  <button name="CTAButton"
                                  onClick={() => {
                                if (book_appointmt_) {
                                  history.push("/bookappointmentnew");
                                } else {
                                  history.push("/bookappointment");
                                }
                              }}
                                  >
                              {" "}
                              Edit
                            </button>
                          ) : (
                            <button
                              name="CTAButton"
                              className="card_bt"
                              onClick={() => {
                                if (book_appointmt_) {
                                  history.push("/bookappointmentnew");
                                } else {
                                  history.push("/bookappointment");
                                }
                              }}
                            >
                              {car_steps_page.Vehicle_Start_text}
                            </button>
                          )
                        ) : (
                          <button
                            name="CTAButton"
                            className="card_bto"
                            onClick={() => {
                              if (book_appointmt_) {
                                history.push("/bookappointmentnew");
                              } else {
                                history.push("/bookappointment");
                              }
                            }}
                          >
                            {car_steps_page.Vehicle_Start_text}
                          </button>
                        )}
                        {/* <button className='card_bton_w' onClick={()=>{history.push("/bookappointment")}} > Start</button> */}
                      </div>
                    </div>

                    <div className="prog">
                      <ProgressBar
                        now={
                          appoinments ? (appoinments.length > 0 ? 100 : 0) : 0
                        }
                        label={`${
                          appoinments ? (appoinments.length > 0 ? 100 : 0) : 0
                        }%`}
                        style={{ height: "0.7rem" }}
                        className="prog_clas"
                      />
                    </div>
                  </div>
                </div>
                <div className="text-center"></div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({
  mycardata: state.myAppointment.data,
  appointment_update: state.sellMyCar,
  totalpics: state.sellMyCar.totalpics,
  evaluation:state.sellMyCar.evaluation_create,
  book_appointmt_: state.sellMyCar.book_appointmt_,
  step1: state.sellMyCar.step1complete,
  step2: state.cardeailupdate.step2complete,
  car_data: state.cardeailupdate.data,
  divindex: state.cardeailupdate.divindex,
  step3: state.sellMyCar.step3complete,
  evaluation_id_: state.sellMyCar.evaluation_create.id,
  vehicle_details: state.sellMyCar.evaluation_create.vehcile_details,
  total_question: state.sellMyCar.evaluation_create.total_vehicle_question,
  appoinments: state.sellMyCar.evaluation_create.appointment,
  vehicle_address: state.sellMyCar.evaluation_create.vehicle_address,
});

const mapDispatchToProps = (dispatch) => ({
  add_car_data: (data) =>
    dispatch({
      type: CAR_DATA_UPDATE_STEPS,
      payload: {
        data: data,
        update: true,
      },
    }),
  //  update_user_info: (name, email, number,id) =>
  //   dispatch({
  //     type: USER_UPDATE,
  //     payload: {
  //       type: USER_UPDATE,
  //       method: PUT,
  //       label: `${Labels.user_update}/${id}`,
  //       hideOverlayLoader: true,
  //       data: {
  //       Website_user: name,
  //       Email: email,
  //       Mobile_number: number,
  //     },
  //     },
  //   }),
    update_user_info: (name, email, number) =>
    dispatch({
      type: 'update_user_details',
      payload: {
        name: name,
        email: email,
        number: number,

      },
    }),
   
   
  set_apointment_data: (data) =>
    dispatch({
      type: SET_APPOINTMENT_DATA,
      payload: {
        data: data,
        update: true,
      },
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CarSteps)