import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./style.css";
import { useHistory } from "react-router-dom";
import { Search, Grid, Header, Segment } from "semantic-ui-react";
import { styled } from "@mui/material/styles";

import { scrollTop } from "../../utils/window";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { Outlet, Link } from "react-router-dom";
import SearchExampleStandard from "./search";
import Labels from "../../services/apiLabels";
import { ShimmerThumbnail } from "react-shimmer-effects";
import {
  CAR_DETAIL_UPDATE,
  ADD_CAR_FEATURES,
  MY_INTERSTE,
  POST,
  CHAKBOX_LIST,
  GET,
} from "../../constant";
import SearchCity from "../../components/SearchCity";
import ChekBox from "../../components/ChekBox";
import InputTypes from "../../components/InputTypes";
import { useContext } from "react";
import FeedbackContext from "../../context/cmscontext";
export const CarUpdate = ({
  car_data,
  getcardetail,
  evaluation_id_,
  loading,
  vehicle_details_change,carfeatureadd,
  inp_chk_action,
  userinfo,
  divindex,
  is_updating,
  evaluation,
}) => {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const { feedback, isLoading } = useContext(FeedbackContext);
  var carupdate_page = feedback.carupdate_page;
  useEffect(() => {
    getcardetail(is_updating == true ? evaluation : {}, evaluation_id_);

    //
  }, []);
  let history = useHistory();
  //
    const [bar, setbar] = useState(0);
  const [no, setno] = useState(1);
  useEffect(() => {
    scrollTop(50);
  }, [no]);
  const [index, setIndex] = useState(0);
  // const [userinfo, setUserInfo] = useState([]);

  // const [divindex, setdivindex] = useState({});
  const [divindex_multiple, setdivindex_multiple] = useState([]);




  console.log("barrrrrrrrrrr", bar);

  function Buttons({ index, setIndex, size }) {
    const nextSlide = (move=true) => {
      //

      setno(no+1);

      if (car_data[index] && divindex[index]) {
        console.log("data in --->> ", divindex[index]);
        carfeatureadd({
          evaluation: evaluation_id_,
          question: car_data[index]["question_id"],
          feature: divindex[index],
        });
        var f=[];
        for(var i=0;i<car_data[index]["choices"].length;i++){
          if (
            divindex[index].indexOf(car_data[index]["choices"][i].choice_id) != -1
          ) {
            f.push({
              id: car_data[index]["choices"][i].choice_id,
              choice: car_data[index]["choices"][i].name,
            });
          }
        }
     
        console.log("data in ff----____---->> ", f);
      //  question: "What is the color of your car?";
      //  question_id: "1";
      //  select: "1";
        
        vehicle_details_change({
          id: 43,
          question_: car_data[index]["question"],
          question: car_data[index]['question_id'],
          feature: f,
        });

        //
        // {id: 43, question_: "What is the color of your car?", question: 1, feature: [{id: 1, choice: "Green"}]}
      }
      if(move){
        if (index < size) setIndex((i) => i + 1);
        setbar(bar + 100 / car_data.length);
      }
     
    };
    const prevSlide = () => {
        setno(no - 1);
      if (index > 0) setIndex(--index);
      setbar(bar - 100 / car_data.length);
    };
    if (index == 0 && no == 0) {
      console.log(index,"history.goBack()", no);
      setno(0)
      history.goBack()
    }
      return (
        <>
          <div className="container-fluied  botm_wrap mb-2">
            <div className="row d-flex justify-content-center  ">
              <div className="col-6 d-flex text-center justify-content-center button-wrap">
                <button
                  onClick={prevSlide}
                  className="back_btn_ my-2"
                  name="CTAButton"
                >
                  {" "}
                  {carupdate_page.Previous_button_text}{" "}
                </button>

                {}
                {index < car_data.length ? (
                  <>
                    <Link
                      onClick={() => nextSlide(false)}
                      to="/carsteps"
                      name="CTAButton"
                      className="mx-4 next_btn_ my-2 "
                    >
                      {carupdate_page.Save_and_exit}
                    </Link>

                    <button
                      onClick={() => nextSlide(true)}
                      name="CTAButton"
                      className="next_btn_ my-2"
                    >
                      {" "}
                      {carupdate_page.Next_button_text}{" "}
                    </button>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </>
      );
  }

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
    },
  }));

  const childData = (e) => {
    // console.log("chind data in parent",e)
  };

  return (
    <>
      {/* <div className="aaaaaa">
        <div className="p_wrap mt-2">
          <BorderLinearProgress
            variant="determinate"
            className="progresbar"
            value={bar}
          />
        </div>
      </div> */}

      <main>
        {/* <div className="presentation">{renderSlide()}</div> */}
        {loading ? (
          <>
            <div className="my-4">
              <ShimmerThumbnail height={200} rounded />
            </div>

            <div className="p-5">
              <ShimmerThumbnail height={60} width={400} rounded />
              <ShimmerThumbnail height={60} width={400} rounded />
              <ShimmerThumbnail height={60} width={400} rounded />
            </div>
          </>
        ) : index < car_data.length ? (
          <InputTypes data={car_data[index]} index_of={index} />
        ) : (
          <SearchCity />
        )}

        <Buttons index={index} setIndex={setIndex} size={car_data.length} />
      </main>
    </>
  );
};

const mapStateToProps = (state) => ({
  car_data: state.cardeailupdate.data,
  loading: state.cardeailupdate.loading,
  divindex: state.cardeailupdate.divindex,
  userinfo: state.cardeailupdate.userinfo,
  evaluation_id_: state.sellMyCar.evaluation_create.id,
  evaluation: state.sellMyCar.evaluation_create,
  is_updating:state.sellMyCar.is_udating_info,
});
const mapDispatchToProps = (dispatch) => ({
  carfeatureadd: (data) =>
    dispatch({
      type: ADD_CAR_FEATURES,
      payload: {
        type: ADD_CAR_FEATURES,
        method: POST,
        label: Labels.add_feaderes,
        hideOverlayLoader: true,
        data,
      },
    }),
  vehicle_details_change: (data_v) =>
    dispatch({
      type: "CAR_UPDATE_DETAILS",
      payload: {
        type: "CAR_UPDATE_DETAILS",
        data: data_v,
        // method: POST,
        // label: Labels.add_feaderes,
        // hideOverlayLoader: true,
        // data,
      },
    }),
  inp_chk_action: (data) =>
    dispatch({
      type: CHAKBOX_LIST,
      payload: {
        data,
      },
    }),

  getcardetail: (data = [], id) =>
    dispatch({
      type: CAR_DETAIL_UPDATE,
      payload: {
        type: CAR_DETAIL_UPDATE,
        label: `${Labels.carDetail_Update}?id=${id}`,
        method: GET,
        hideOverlayLoader: true,
        data_vehicle: data,
      },
    }),
});
export default connect(mapStateToProps, mapDispatchToProps)(CarUpdate);

function Presentation({ slides, index }) {
  const renderSlide = () => {
    return slides[index];
  };
  return <div className="presentation">{renderSlide()}</div>;
}
