import React from "react";
//
import { useLocation } from "react-router-dom";
import Form from "../../components/Form";
import Dropdown from "../../components/Form/Dropdown";
import IconInput from "../../components/Form/Input";
//
import { useEffect ,useState} from "react";
import { useHistory } from "react-router-dom";


import { connect } from "react-redux";
import { EVALUATION_CREATE, SELL_MY_CAR_STEPS } from "../../constant";
import { POST } from "../../constant/apiMethods";
import Labels from "../../services/apiLabels";
import Step6 from "./Step6";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step7 from "./Step7";
import Step8 from "./Step8";
//
import { useContext } from "react";
import FeedbackContext from "../../context/cmscontext";
import { scrollTop } from "../../utils/window";
import { upperCase } from "lodash";
// 
function EvaluationSteps(props) {
  const {
    currentStep,
    setcurrentStep,
    //
    step1,
    //
    reg_number,
    mileage,
    previous_owners,
    services_history,
    setreg_number,
    setmileage,
    setprevious_owners,
    setservices_history,
    //
    setuser_name,
    setuser_email,
    setuser_postcode,
    setuser_mobile_number,
    //
    user_name,
    user_email,
    user_postcode,
    user_mobile_number,
    //
    postEvaluationCreate,
    loading_evaluation_create,
  } = props;
  const formanimationClassname = "animate__animated animate__zoomIn";
  const step2OnSubmitHandler = () => {
    if (
      mileage.isSelected &&
      previous_owners.isSelected &&
      services_history.isSelected &&
      reg_number
    )
      setcurrentStep(currentStep + 1);
    scrollTop();
  };
  //
  const [refel_code, setrefel_code] = useState()
  const det_refel_code=(e)=>{
    setrefel_code(e)
  }
  
  const { feedback, isLoading } = useContext(FeedbackContext);
  var evaluation_page = feedback.evaluation_page;



  const renderServiceHistoryDetail = (e) => {
    switch (e) {
      case "Full":
        return "All recomended serevices on time ";
      case "Some":
        return "Some recomended serevices are missed ";
      case "None":
        return "No services are done";
      case "First not Due":
        return "Recomended service is due";

      default:
        return "";
    }
  };
  //



const [car_description, setcar_description] = useState()

const [car_notes, setcar_notes] = useState()
const [car_body, setcar_body] = useState({})


console.log("car_bodycar_bodycar_bodycar_body", car_body);



  const postEvaluationCreateHandler = () => {
    if (
      step1.variant.isSelected &&
      mileage.isSelected &&
      previous_owners.isSelected &&
      services_history.isSelected &&
      user_name &&
      user_email &&
      user_postcode &&
      user_mobile_number &&
      reg_number
    ) {
      postEvaluationCreate({
        car: step1.variant.current.value,
        Mileage: mileage.current.title,
        reg_number,
        Previous_owners: previous_owners.current.id,
        Service_history: services_history.current.id,
        Website_user: user_name,
        Email: user_email,
        refel_code: refel_code,
        Postcode: user_postcode,
        Mobile_number: user_mobile_number,
        car_description: car_description,
        car_notes: car_notes,
        car_body_type:car_body?.id
      });
      scrollTop();
    } else {

    }
  };

  const history = useHistory();



  const location = useLocation();

useEffect(() => {

   


  if(location.state){
    setinputshow(true)

     // result: 'some_value'

    setreg_number(location.state.Car_Reg_N.toUpperCase())
  }





}, [location])

var dis="enabled"

const [inputshow, setinputshow] = useState(false)



  return (
    <>
      {/* ============================== step 2 form ============================== */}
      {currentStep === 2 ? (
        <Form
          onSubmit={step2OnSubmitHandler}
          buttonText="Continue"
          className={formanimationClassname}
        >
          {/* Registration Number*/}

          {inputshow ? null : (
            <IconInput
              label={evaluation_page.Registration_Number_heading}
              placeholder={evaluation_page.Registration_Number_placeholder}
              onChangeText={setreg_number}
              value={reg_number.toUpperCase()}
            />
          )}

          {/* mileage */}
          <IconInput
            label={evaluation_page.Mileage_heading}
            placeholder={evaluation_page.Mileage_placeholder}
            onChangeText={(e) =>
              setmileage({
                ...mileage,
                isSelected: e.length > 0,
                current: { id: "", title: e },
              })
            }
            value={mileage.current.title}
          />
          {/* previous_owners */}
          <Dropdown
            label={evaluation_page.Previous_Owners_heading}
            onSelect={(item) =>
              setprevious_owners({
                ...previous_owners,
                isSelected: true,
                current: item,
              })
            }
            selectedValue={previous_owners.current}
            loading={previous_owners.fetching}
            disabled={false}
            data={[
              { title: 1, id: 1 },
              { title: 2, id: 2 },
              { title: 3, id: 3 },
              { title: 4, id: 4 },
              { title: "5 or more", id: 5 },
              { title: "Don't know", id: 6 },
            ]}
          />
          {/* ======================orinal code  */}
          {/* services_history */}
          <Dropdown
            label={evaluation_page.Services_History_label}
            onSelect={(item) =>
              setservices_history({
                ...services_history,
                isSelected: true,
                current: item,
              })
            }
            selectedValue={services_history.current}
            loading={services_history.fetching}
            disabled={false}
            data={[
              { title: "Full", id: 1 },
              { title: "Part", id: 2 },
              { title: "None", id: 3 },
              { title: "First not Due", id: 4 },
            ]}
          />
          {services_history.isSelected && (
            <div style={{ margin: "0 1rem" }}>
              {renderServiceHistoryDetail(services_history.current.title)}
            </div>
          )}
        </Form>
      ) : null}
      {/* ============================== step 3 form ============================== */}
      {currentStep === 3 ? (
        <Form
          onSubmit={postEvaluationCreateHandler}
          buttonText={evaluation_page.Get_my_valuation_buton_text}
          className={formanimationClassname}
          loading={loading_evaluation_create}
          loadingText={evaluation_page.Get_my_valuation_buton_text_loading}
        >
          {/* uesr name */}
          <IconInput
            loading={loading_evaluation_create}
            label={evaluation_page.Your_Name_label}
            placeholder={evaluation_page.Your_Name_placeholder}
            onChangeText={setuser_name}
            value={user_name}
          />
          {/* uesr email */}
          <IconInput
            loading={loading_evaluation_create}
            type="email"
            label={evaluation_page.Your_Email_label}
            placeholder={evaluation_page.Your_Email_placeholder}
            onChangeText={setuser_email}
            value={user_email}
          />
          {/* uesr postcode */}
          <IconInput
            style={{ TextTransform: upperCase }}
            loading={loading_evaluation_create}
            label={evaluation_page.Your_Postcode_label}
            placeholder={evaluation_page.Your_Postcode_placeholder}
            onChangeText={setuser_postcode}
            value={user_postcode.toUpperCase()}
          />

          {/* ===========================refel code =========== */}
          {/* <IconInput
            style={{ TextTransform: upperCase }}
            label={evaluation_page.Your_referal_label}
            placeholder={evaluation_page.Your_referal_placeholder}
            onChangeText={det_refel_code}
            value={refel_code}
          /> */}
          {/* uesr mobile_number */}
          <IconInput
            loading={loading_evaluation_create}
            label={evaluation_page.Your_Mobile_Number_label}
            placeholder={evaluation_page.Your_Mobile_Number_placeholder}
            onChangeText={setuser_mobile_number}
            value={user_mobile_number}
          />
            <Dropdown
            label='Car Body Type'
            onSelect={(item) =>
              setcar_body(item)
            }
            selectedValue={car_body}
            loading={services_history.fetching}
            disabled={false}
            data={[
              { title: "Hatchback", id: 1 },
              { title: "SUV", id: 2 },
              { title: "Saloon", id: 3 },
            ]}
          />
          {/* <div className="dropdown_wrapper">
            <span className="label-t "> {evaluation_page.Your_Description}</span>
            <textarea
              className="text-area mt-1 mb-1 p-1"
              id="w3review"
              name="w3review"
              rows="3"
              placeholder={evaluation_page.Your_Description_Placeholder}
              value={car_description}
              cols="50"
              onChange={(e) => {
                setcar_description(e.target.value);
              }}
            ></textarea>
          </div>
          <div className="dropdown_wrapper">
            <span className="label-t  "> {evaluation_page.Your_Notes}</span>
            <textarea
              className="text-area mt-1 p-1"
              id="w3review"
              name="w3review"
              rows="3"
              cols="50"
              placeholder= {evaluation_page.Your_Notes_palceholder}
              value={car_notes}
              onChange={(e) => {
                setcar_notes(e.target.value);
              }}
            ></textarea>
          </div> */}
        </Form>
      ) : null}
      <div className="w-100" style={{ padding: "0.5rem" }}>
        {/* {currentStep === 8 && (
          <Step8 formanimationClassname={formanimationClassname} />
        )} */}

        {
          currentStep === 4 && history.push("/carsteps")

          // <Step4 formanimationClassname={formanimationClassname} />
        }
        {/* {currentStep === 5 && (
          <Step5 formanimationClassname={formanimationClassname} />
        )}
        {currentStep === 6 && (
          <Step6 formanimationClassname={formanimationClassname} />
        )}
        {currentStep === 7 && (
          <Step7 formanimationClassname={formanimationClassname} />
        )}
       */}
      </div>
      {/* <button onClick={() => setcurrentStep(currentStep - 1)}>Back</button>
      <button onClick={() => setcurrentStep(currentStep + 1)}>forward</button> */}
    </>
  );
}

const mapStateToProps = (state) => ({
  loading_evaluation_create: state.sellMyCar.loading,
  step1: state.sellMyCar.step1,
  reg_number: state.sellMyCar.step2.reg_number,
  mileage: state.sellMyCar.step2.mileage,
  previous_owners: state.sellMyCar.step2.previous_owners,
  services_history: state.sellMyCar.step2.services_history,
  // step 3 user info
  user_name: state.sellMyCar.user.name,
  user_email: state.sellMyCar.user.email,
  user_postcode: state.sellMyCar.user.postcode,
  user_mobile_number: state.sellMyCar.user.mobile_number,
});
const mapDispatchToProps = (dispatch) => ({
  setmileage: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "mileage",
        data,
      },
    }),
  setprevious_owners: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "previous_owners",
        data,
      },
    }),
  setservices_history: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "services_history",
        data,
      },
    }),
  setreg_number: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "reg_number",
        data,
      },
    }),
  // step 4
  setuser_name: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "user_name",
        data,
      },
    }),
  setuser_email: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "user_email",
        data,
      },
    }),
  setuser_postcode: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "user_postcode",
        data,
      },
    }),
  setuser_mobile_number: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "user_mobile_number",
        data,
      },
    }),
  // post EVALUATION_CREATE
  postEvaluationCreate: (data) => {
    return dispatch({
      type: EVALUATION_CREATE,
      payload: {
        type: EVALUATION_CREATE,
        method: POST,
        label: `${Labels.evaluation_create}`,
        hideOverlayLoader: true,
        data,
      },
    });
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(EvaluationSteps);
