export const scrollTop = () => {
  window.scroll({
    top: 0,
    behavior: "smooth",
  });
};
export async function copyTextToClipboard(text, callbackAction) {
  if ("clipboard" in navigator) {
    await navigator.clipboard.writeText(text);
    callbackAction();
    return true;
  } else {
    
    return false;
  }
}
