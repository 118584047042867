import reducer from "./reducers/index";
import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import thunk from "redux-thunk";
import rootSaga from './saga/index'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
const persistConfig = {
  key: 'root',
  storage, // which reducer want to store
};
const pReducer = persistReducer(persistConfig, reducer);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();
const middlewares = [thunk, sagaMiddleware];

const store = createStore(
  pReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);
const persistor = persistStore(store);
// run saga
sagaMiddleware.run(rootSaga);

export default {persistor,store};
