/* 
use:
    • pass an array in props name as routesArr = [{children: _a component to render in router_ , path: _route path_ , type: private || public || auth }] 

*/
import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { get } from "../services/localStorage";
import { LOGIN, LOG_OUT } from "../constant";
// Default constants
export const DEFAULT_ROUTE = "/";
export const DEFAULT_AUTH_ROUTE = "/login";
export const localStorage_auth = () => {
  const localStorage_auth_data = get({ key: "auth" });
  return localStorage_auth_data
    ? localStorage_auth_data.token
      ? localStorage_auth_data
      : {}
    : {};
};
export let USER = localStorage_auth().token;
// 
// // =============== default router function
// function Index({ children, auth, login, logOut }) {

//   return <Switch>{children}</Switch>;
// }
// const mapStateToProps = (state) => ({
//   auth: state.auth,
// });
// const mapDispatchToProps = (dispatch) => ({
//   login: (data) =>
//     dispatch({
//       type: `${LOGIN}_SUCCESS`,
//       payload: { ...data },
//     }),
//   logOut: (data) =>
//     dispatch({
//       type: LOG_OUT,
//     }),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(Index);

// // ========================== auth route component
export const AuthRoute = (props) => {
  const { children, redirect, path, type, auth, ...rest } = props;

  return (
    <Route
      {...rest}
      render={({ location }) => {
        let from = location.state ? location.state.from : {};
        const redirectPath = from.pathname
          ? from.pathname
          : redirect
          ? redirect
          : DEFAULT_ROUTE;
        return !auth.token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: redirectPath,
              // state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

// private route component

export const PrivateRoute = (props) => {
  const { children, redirect, path, type, auth } = props;
  //   const { children, redirect, path, type } = routeObj;
  return (
    <Route
      path={path}
      //   {...rest}
      render={({ location }) =>
        auth.token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: redirect ? redirect : DEFAULT_AUTH_ROUTE,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
// public route component

export const PublicRoute = (props) => {
  const { children, redirect, path, type, bool } = props;
  let redirectCondition = bool || false;
  return (
    <Route
      path={path}
      //   {...rest}
      render={({ location }) =>
        !redirectCondition ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: redirect ? redirect : DEFAULT_ROUTE,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
