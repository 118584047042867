/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { Drawer } from "antd";
import "./style.css";

export default (props) => {
  const { title, visible, handleDrawer, width, placement, closable, body } =
    props;
  /* 
placements : top | right | bottom | left
*/
  return (
    <>
      <Drawer
        width={width ? width : "100%"}
        title={title}
        placement={placement || "right"}
        closable={closable || false}
        // onClose={handleDrawer}
        visible={visible}
      >
        {body}
      </Drawer>
    </>
  );
};
