import { CAR_DETAILE ,SALE_PRODUCT_LIST} from "../constant/index";

const INITIAL_STATE = {
  loading: false,
  data:[],
  productlist:[],

};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case `${CAR_DETAILE}_REQUEST`:
      return { ...state, loading: true };
    case `${CAR_DETAILE}_SUCCESS`:
      // console.log("MY_INTERSTE ...........MY_INTERSTE",action.payload)

      return { ...state, 
        data:action.payload,
        loading:false,
      };
    case `${CAR_DETAILE}_FAIL`:
      return { ...state, loading: false };
    case `${CAR_DETAILE}_ERROR`:
      return { ...state, loading: false };
    //
  // =======================product sale list====================
    case `${SALE_PRODUCT_LIST}_REQUEST`:
      return { ...state, loading: true };
    case `${SALE_PRODUCT_LIST}_SUCCESS`:
      // console.log("sale product list..........======>>>>",action.payload)

      return { ...state, 
        productlist:action.payload,
       
        loading:false,
      };
    case `${SALE_PRODUCT_LIST}_FAIL`:
      return { ...state, loading: false };
    case `${SALE_PRODUCT_LIST}_ERROR`:
      return { ...state, loading: false };
    //
  
  
  
  
  }
  return state;
};
