import React from 'react'

function DeskTopView() {
  return (
  <>
  
  
  
  
  <div className="container-fluied aaa">
      
          <div className="web_wrap ">
            <div className="mt-"> <h1> Sell Any Wheel</h1> </div>
            <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
            <lottie-player
              src="https://assets2.lottiefiles.com/packages/lf20_ti4y5deu.json"
              background="#FEF200"
              speed="1"
              style={{"width": "300px", "height": "300px"}}
              loop
             
              autoplay
            ></lottie-player>

            <div className="w-25 text_wrap_2"><h1 className="text_2">Non mobile devices are not supported</h1></div>
            <div className="w-50 mt-4">
            <p className="text_3">
              To access the photo app you need an iPhone or iPad running iOS 13
              or later, or an Android device running version 9 or later.
            </p>

            </div>
          
          </div>
      
    
      </div>
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  </>
  )
}

export default DeskTopView