import { sagaRoot } from "./root";
import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { LOGIN, SIGN_UP, TOASTER_SHOW } from "../constant";
import { useHistory } from "react-router-dom";

// login
export function* loginSaga(payload) {
  // 
  const response = yield call(sagaRoot, payload);
  // 
  if (response.message.type === "success") {
    window.location.reload(true);
    const callBack = payload.payload.callBack;
    callBack(response);
  }
  // if (response.status === 200) {
  //   yield put({
  //     type: TOASTER_SHOW,
  //     payload: {
  //       show: true,
  //       autoHide: true,
  //       type: "success",
  //       heading: "Successful",
  //       description: "You have successfully loged in",
  //     },
  //   });
  // }
}

// sign up











export function* signUpSaga(payload) {
  // 
  const response = yield call(sagaRoot, payload);
  // 
  // if (response.message.type === "success") {

  //   // window.location.reload(true);
    
  // }
  // if (response.status === 200) {
  //   yield put({
  //     type: TOASTER_SHOW,
  //     payload: {
  //       show: true,
  //       autoHide: true,
  //       type: "success",
  //       heading: "Successful",
  //       description: "Account Created Successfully ",
  //     },
  //   });
  // }
}

export default function* authWatcher() {
  yield takeLatest(LOGIN, loginSaga);
  yield takeLatest(SIGN_UP, signUpSaga);
}
