/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";
import {
  ShimmerThumbnail,
  ShimmerButton,
  ShimmerTitle,
} from "react-shimmer-effects";
import "./GridCard.css";
export default (props) => {
  return (
    <div className=" product_grid_card_main_cont">
      <ShimmerThumbnail height={160} />
      {/* body section */}
      <div className="product_grid_card_body_cont">
        <ShimmerTitle className="w-75" line={1} gap={10} variant="primary" />
        <div className="d-flex justify-content-start align-items-center">
          <ShimmerTitle className="w-25 " line={1} gap={10} variant="primary" />

          <ShimmerTitle className="w-25" line={1} gap={10} variant="primary" />
        </div>
        <ShimmerButton size="md" />
      </div>
    </div>
  );
};
