/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable default-case */
// importing types from constants definded in constant/ index file
import * as storage from "../services/localStorage";
import { LOGIN, SIGN_UP, LOG_OUT } from "../constant/index";

const INITIAL_STATE = {
  SIGN_UP_done:false,
  loging: false,
  creating: false,
  isLogedin: false,
  token: "",
  expiry: "",
  email: "",
  firstname: "",
  lastname: "",
  username: "",
  id: "",
};

export default (state = INITIAL_STATE, action) => {
  
  switch (action.type) {
    // ---------------------------------- login cases ---------------------------------- //
    // 1 case when login requested
    case `${LOGIN}_REQUEST`:
      return {
        ...INITIAL_STATE,
        loging: true,
      };
    // 2 case when login successful
    case `${LOGIN}_SUCCESS`:
      storage.set({
        key: "auth",
        data: {
          ...state,
          ...action.payload,
          loging: false,
          isLogedin: true,
          ...action.payload.user,
        },
      });
      return {
        ...state,
        loging: false,
        ...action.payload,
        isLogedin: true,
        // token: action.payload.token,
        // expiry: action.payload.expiry,
        // user: action.payload.user,
      };
    // 3 case when login rejected response get from api
    case `${LOGIN}_FAIL`:
      return {
        ...state,
        loging: false,
      };
    // ---------------------------------- sign up cases ---------------------------------- //
    // 1 case when login requested
    case `${SIGN_UP}_REQUEST`:
      return {
        ...INITIAL_STATE,
        creating: true,
      };
    // 2 case when login successful
    case `${SIGN_UP}_SUCCESS`:
      // storage.set({
      //   key: "auth",
      //   data: {
      //     ...state,
      //     ...action.payload,
      //     creating: false,
      //     isLogedin: true,
      //   },
      // });

      return {
        
        ...state,
        SIGN_UP_done:action.payload,
        // ...action.payload,
        // creating: false,
        // isLogedin: true,
        // token: action.payload.token,
        // expiry: action.payload.expiry,
        // user: action.payload.user,
      };
    // 3 case when login rejected response get from api
    case `${SIGN_UP}_FAIL`:
      return {
        ...state,
        creating: false,
        // token: "",
        // expiry: "",
        // user: {},
      };
    case LOG_OUT:
      storage.remove({
        key: "auth",
      });
      return INITIAL_STATE;
  }
  return state;
};
