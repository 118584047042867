import React, {  useEffect, useState } from "react";
import "./style.css";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import TextField from "@mui/material/TextField";
import PuffLoader from "react-spinners/ClipLoader";
import { SALE_PRODUCT_LIST, ADDRESS_FORM } from "../../constant";
import { POST } from "../../constant/apiMethods";
import { connect } from "react-redux";
import Labels from "../../services/apiLabels";
import config from "../../config/index"
import { scrollTop } from "../../utils/window";
import { useContext } from "react";
import FeedbackContext from "../../context/cmscontext";
function SearchCity(props) {
  const {
    product,
    productList,
    cityes_List_Data,
    sumit_form,
    addressformCreate,
    addressform,
    evaluation_id_,
    car_update_address,
  } = props;


    const { feedback, isLoading } = useContext(FeedbackContext);
    var carupdate_page = feedback.carupdate_page;
  const history = useHistory();

  const [inpValue, setInpValue] = useState("");
  const [citiesList, setCitiesList] = useState([]);
  const [cityDetail, setCityDetail] = useState("");
  const [line1, setLine1] = useState("");
  const [line2, setLine2] = useState("");
  const [line3, setLine3] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [postCode, setPostCode] = useState("");
  const [show, setShow] = useState(false);
  const [divShow, setDivShow] = useState("false");
  let [loading, setLoading] = useState("false");
  useEffect(() => {
    scrollTop();
  }, []);
  useEffect(() => {
    console.log("address from ", addressform)
    if (addressform) {
      // history.push("/carsteps")
    }
  }, [addressform]);

  const citiesListHandler = (e) => {
    e.preventDefault();

    setInpValue(e.target.value);

    if (e.target.value.length > 1) {
      getAllCitiesList(e.target.value);
      setLoading("true");
    }

    if (e.target.value == "") {
      setCitiesList([]);
      setDivShow("false");
      document.getElementById("inp_main").style.borderBottom = "2px solid #afada3";
    }
  };

  function getAllCitiesList(code) {
    axios
      .put(
        `https://api-finder.eircode.ie/Latest/finderautocomplete?key=_3c14c473-8c8f-4607-aba9-06d4f1e11a2c&address=${code}&language=en&geographicAddress=true`
      )
      .then((response) => {
        setLoading("false");
        setDivShow("true");

        document.getElementById("inp_main").style.borderBottom = "none";

        if (response.data.options.length > 0) {
          setCitiesList(response.data.options);
        }
      })
      .catch((error) => {
        console.error(`Error: ${error}`);
      });
  };

  const cityData = (item) => {
    // console.log("wwwwwww", item)
    setInpValue(item)
    getOneCity(item);
  };

  function getOneCity(address) {
    axios.get(`${config.baseUrl}api/v1/get_address_detail/?address=${address}`)
      .then((response) => {
        // 
        if (response.data.data.postalAddress) {
          if (response.data.data.postalAddress.length == 1) {
            setLine1(response.data.data.postalAddress[0]);
          } else if (response.data.data.postalAddress.length == 2) {
            setLine1(response.data.data.postalAddress[0]);
            setLine2(response.data.data.postalAddress[1]);
          } else if (response.data.data.postalAddress.length > 2) {
            setLine1(response.data.data.postalAddress[0]);
            setLine2(response.data.data.postalAddress[1]);
          }
        }


        if (response.data.data.postalAddress && response.data.data.postalAddress.length>2) {
          if (response.data.data.postalAddress[2] && response.data.data.postalAddress[3]) {
            setLine3(response.data.data.postalAddress[2] + ", " + response.data.data.postalAddress[3]);
          } else {
            setLine3(response.data.data.postalAddress[2]);
          }
        }

        setAddress(response.data.data.input.address);
        setCountry(response.data.data.input.country);
        setPostCode(response.data.data.postcode);
        setShow(true);

        setCityDetail(response.data.data);

        
      })
      .catch(error => {
        console.error(`Error: ${error}`);
      })
  };

  const submitAddress = () => {
    sumit_form({
      evaluation_id: evaluation_id_,
      line1: line1,
      line2: line2,
      line3: line3,
      address: address,
      country: country,
      post_code: postCode,
    })
    car_update_address({
      address: address,
      city: null,
      country: country,
      id: 11,
      line1: line1,
      line2: line2,
      post_code: postCode,
    });   
    
    setTimeout(
  () => history.push("/carsteps"), 
  2000
);
    
    // console.log("Data Sent.")
  };
 const write_addres=()=>{
    // console.log("eidt addddd")

    setShow(true)
}
  const serach_inppp = () => {
    // console.log("edit address")

    setShow(false)
  };

  // const serchbar_ = () => { };

  // const actioncall = () => {
  //

  //   citydata_action({
  //       abc:"abdullah"
  //   })
  // };

  
console.log(addressformCreate,"addressform===========",addressform)

  return (
    <>
      <div className="showInterest_headingCont ">
        <div className="showInterest_heading">
          {carupdate_page.Provide_Your_Address}
        </div>
        <div className="showInterest_description">
          {carupdate_page.Enter_Your_Address}
        </div>
      </div>
      <div className="container  inp_main_wrap p-5 kkkkkkkk">
        {show ? (
          <div className="containerfluid contact_wra p-5 ">
            <div className="row  d-flex justify-content-center ">
              <div className="col-lg-4 col-md-5 col-sm-12 ">
                <h4>Postal Address</h4>
                <h5 className="text-muted mt-1">
                  {carupdate_page.Line_1_text}
                </h5>
                <div className="inp_wrap my-3">
                  <input
                    value={line1}
                    onChange={(e) => {
                      setLine1(e.target.value);
                    }}
                    className="inp_contact"
                    placeholder={carupdate_page.Line_1_placeholder}
                  />
                </div>
                <h5 className="text-muted mt-1">
                  {carupdate_page.Line_2_text}
                </h5>
                <div className="inp_wrap my-3">
                  <input
                    value={line2}
                    onChange={(e) => {
                      setLine2(e.target.value);
                    }}
                    className="inp_contact"
                    placeholder={carupdate_page.Line_2_placeholder}
                  />
                </div>
                <h5 className="text-muted mt-1">
                  {carupdate_page.Line_3_placeholder}
                </h5>
                <div className="inp_wrap">
                  <input
                    value={line3}
                    onChange={(e) => {
                      setLine3(e.target.value);
                    }}
                    className="inp_contact"
                    placeholder={carupdate_page.Line_3_placeholder}
                  />
                </div>
                <h4>{carupdate_page.Address_text}</h4>
                <div className="inp_wrap">
                  <input
                    value={address}
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                    className="inp_contact"
                    placeholder={carupdate_page.Address_text}
                  />
                </div>
                <h4>{carupdate_page.Country_text}</h4>
                <div className="inp_wrap my-3">
                  <input
                    type="email"
                    onChange={(e) => {
                      setCountry(e.target.value);
                    }}
                    value={country}
                    className="inp_contact"
                    placeholder={carupdate_page.Country_text}
                  />
                </div>
                <h4>{carupdate_page.Postcode_text}</h4>
                <div className="inp_wrap my-3">
                  <input
                    type="email"
                    onChange={(e) => {
                      setPostCode(e.target.value.toUpperCase());
                    }}
                    value={postCode}
                    className="inp_contact "
                    placeholder={carupdate_page.Postcode_placeholder}
                  />
                </div>
                <span
                  style={{ cursor: "pointer" }}
                  id="editAddress"
                  onClick={serach_inppp}
                >
                  <i className="enter">{carupdate_page.Edit_Addres_text}</i>
                </span>

                <button
                  name="CTAButton"
                  className="btn_contact my-3"
                  onClick={submitAddress}
                >
                  {carupdate_page.Submit_text}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="main_Wrap_ss col-lg-12 col-md-12 col-sm-12 border">
              <label>{carupdate_page.Enter_postcode_first_line}</label>
              <br></br>
              <span
                style={{ cursor: "pointer" }}
                className="text-bold"
                onClick={write_addres}
              >
                {carupdate_page.If_you_cannot}
                <span className="enter"> {carupdate_page.Click_to_enter}</span>
              </span>
              <div className="mt-2 inp_main" id="inp_main">
                <input
                  type="text"
                  className="inp_inp"
                  placeholder={carupdate_page.serch_placeholder}
                  onChange={citiesListHandler}
                  value={inpValue}
                />
                <div className="srech_">
                  {loading == "true" ? <PuffLoader /> : <FaSearch size={17} />}
                </div>
              </div>

              {divShow == "true" ? (
                <div className="mt city_list">
                  {
                    // citeslist.suggestions.length > 0 ?
                    React.Children.toArray(
                      citiesList.map((item) => {
                        return (
                          <>
                            <ul>
                              <li
                                className="lii"
                                onClick={() => {
                                  cityData(item.displayName);
                                }}
                              >
                                {" "}
                                {item.displayName}
                              </li>
                            </ul>
                          </>
                        );
                      })
                    )
                  }
                </div>
              ) : (
                ""
              )}
            </div>
            <div></div>
          </>
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  cityes_List_Data: state.cardeailupdate,
  addressform: state.cardeailupdate.addressform,
  addressformCreate: state.cardeailupdate.addressformCreate,
  evaluation_id_: state.sellMyCar.evaluation_create.id,
});
const mapDispatchToProps = (dispatch) => ({
  sumit_form: (data) =>
    dispatch({
      type: ADDRESS_FORM,
      payload: {
        type: ADDRESS_FORM,
        method: POST,
        label: Labels.address_form,
        hideOverlayLoader: true,
        data,
      },
    }),
  car_update_address: (data_a) =>
    dispatch({
      type: "CAR_UPDATE_ADDRESS",
      payload: {
        type: "CAR_UPDATE_ADDRESS",
        data: data_a,
      },
    }),

  //
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchCity);
