import React, {
  Fragment,
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
} from "react";
import "./navbar.css";
import "./CurveNavbar.css";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import logo from "../../assets/images/finallogo.png";
// import logo from "../../assets/images/logoc.png";
import { FaCar, FaRegHeart } from "react-icons/fa";
import { FiLogOut, FiMenu, FiLogIn } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import { MdArrowForwardIos } from "react-icons/md";
import Drawer from "../../components/Drawer";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom"
import { LOG_OUT } from "../../constant";
import { useContext } from "react";
import $ from "jquery";
import FeedbackContext from "../../context/cmscontext";

const CurveNavbar = ({ setnavbarDimensions, auth, logOut }) => {
  const [mobileDrawer, setMobileDrawer] = useState(false);
  const navbarContainer = useRef(null);
  //
 const { feedback, isLoading } = useContext(FeedbackContext);
  var home_page = feedback.home_page;
const section2Buttons = [
  {
    title: `${home_page.Home}`,
    to: "/",
  },
  {
    title: `${home_page.My_Appointment}`,
    to: "/myappointment",
  },
  {
    title: `${home_page.Contact_Us}`,
    to: "/contactus",
  },
  {
    title: `${home_page.About_Us}`,
    to: "/aboutus",
  },
  {
    title: `${home_page.Dost_Program}`,
    to: "/dostprogram",
  },
  {
    title: `${home_page.For_Dealers}`,
    to: "https://dealers.sellanywheel.com",
  },
];
  let history = useHistory();
  useEffect(() => {






    function setnavbarDimensionsHandler() {
      if (navbarContainer.current.offsetWidth > 1023) {
        setMobileDrawer(false)
      }
      // // console.log(
      //   "navbar height on change handler called",
      //   navbarContainer.current.offsetWidth
      // );

      setnavbarDimensions({
        width: navbarContainer.current.offsetWidth,
        height: navbarContainer.current.offsetHeight,
      });
    }

    window.addEventListener("resize", setnavbarDimensionsHandler);
    return () =>
      window.removeEventListener("resize", setnavbarDimensionsHandler);
  }, []);

  //
  const section1Buttons = [
    {
      title: `${home_page.Sell_Any_Wheel}`,
      to: "/",
    },
  ];
  // -----------------mobile view --------------------- //
  const mobileviewLeftButtons = [
    // {
    //   title: "resent",
    //   to: "/",
    //   icon: <FaCar className="mobile-nav-button-icon" />,
    // },
    // {
    //   title: "My Appointment",
    //   to: "/myappointment",
    //   icon: <FaRegHeart className="section1Button-icon" />,
    // },

  ];
  const mobileviewRightButtons = [

  ];
  const handleDrawer = () => {
    setMobileDrawer(!mobileDrawer);
  };

  return (
    <div id="curve-nav-bar-main-cont" ref={navbarContainer}>
      {/*--------------------------------------------------------------- desktop view -----------------------------*/}
      <div className="courve-nav-bar-container-desktop  ">
        <div className="courve-nav-bar-logo-container d-flex justify-content-start align-items-start">
          <img
            className="navlogo m-1"
            alt="Logo"
            src={home_page.main_logo}
            onClick={() => {
              // console.log("wwwwww")
              history.push("/");
            }}
          />
        </div>
        <div className="courve-nav-bar-body d-flex flex-column justify-content-center align-items-end">
          {/* first row */}
          <div className="courve-nav-bar-body-s courve-nav-bar-body-s1-main">
            <div className="courve-nav-bar-section-1-curve"></div>
            <div className="courve-nav-bar-body-s courve-nav-bar-body-s1 d-flex   justify-content-end align-items-center">
              <Nav className="courve-nav-bar-body-s1-child d-flex   justify-content-between">
                {React.Children.toArray(
                  section1Buttons.map((item, index) => (
                    <Fragment>
                      {item.isButton ? (
                        <button
                          key={item}
                          onClick={item.to}
                          className="mobile-nav-menu-button-s1 d-flex  justify-content-center align-items-center"
                        >
                          {item.icon} {item.title}
                        </button>
                      ) : (
                        <NavLink
                          exact
                          key={item}
                          to={item.to}
                          activeClassName="section1Button-active"
                          className="section1Button d-flex justify-content-center align-items-center"
                        >
                          {item.icon} {item.title}
                        </NavLink>
                      )}
                      {index === section1Buttons.length - 1 ? null : (
                        <div className="vertical-divider" />
                      )}
                    </Fragment>
                  ))
                )}

                <a
                  // activeClassName="section1Button-active"
                  className="section1Button d-flex justify-content-center align-items-center"
                  href="/#how_work"
                >
                  {home_page.How_Works}
                </a>
              </Nav>
            </div>
          </div>
          {/* second row */}
          <div className="courve-nav-bar-body-s courve-nav-bar-body-s2 d-flex   justify-content-end align-items-center">
            <Nav className="courve-nav-bar-body-s1-child d-flex   justify-content-between">
              {React.Children.toArray(
                section2Buttons.map((item, index) =>
                  item.title != home_page.For_Dealers ? (
                    <NavLink
                      exact
                      to={item.to}
                      activeClassName="selected"
                      className="unselected"
                    >
                      {item.title}
                    </NavLink>
                  ) : (
                    <a
                      // activeClassName="selected"
                      className="unselected mt-"
                      href="https://dealers.sellanywheel.com"
                      target="_blank"
                    >
                      {home_page.For_Dealers}
                    </a>
                  )
                )
              )}
            </Nav>
          </div>
        </div>
      </div>
      {/*--------------------------------------------------------------- mobile and tablet view -----------------------------*/}
      <div className="courve-nav-bar-container-mobile ">
        <div
          className="courve-nav-bar-logo-container d-flex justify-content-start align-items-start"
          style={{ flex: 1 }}
        >
          <img
            className="navlogo "
            alt="Logo"
            src={home_page.main_logo}
            onClick={() => {
              // console.log("wwwwww")
              history.push("/");
            }}
          />
        </div>

        <Nav className="courve-nav-bar-mobile-left-button ">
          {/* {mobileviewRightButtons.map((item, index) => (
            <button
              onClick={item.to}
              // activeClassName="mobile-nav-button-active"
              // className="mobile-nav-button d-flex flex-column justify-content-center align-items-center"
              className="mobile-nav-menu-button d-flex flex-column justify-content-center align-items-center"
            >
              {item.icon} {item.title}
            </button>
          ))} */}

          <button
            onClick={handleDrawer}
            className="mobile-nav-menu-button d-flex flex-column justify-content-center align-items-center"
          >
            <FiMenu className="mobile-nav-button-icon" />
            {mobileDrawer ? "close" : "menu"}
          </button>
        </Nav>

        <Drawer
          visible={mobileDrawer}
          handleDrawer={handleDrawer}
          body={
            <MobileDrawerBody
              handleDrawer={handleDrawer}
              home_page={home_page}
              section2Buttons={section2Buttons}
            />
          }
        />
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  logOut: (data) => {
    
    dispatch({
      type: LOG_OUT,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CurveNavbar);

// drawer body
const MobileDrawerBody = (props) => {
  const { handleDrawer, section2Buttons, home_page } = props;
  return (
    <Fragment>
      <div className="courve-nav-bar-container-mobile d-flex align-items-center">
        <div
          className="courve-nav-bar-logo-container d-flex justify-content-start align-items-start"
          style={{ flex: 1 }}
        >
          <img className="navlogo m-1" alt="Logo" src={home_page.main_logo} />
        </div>
        <button
          onClick={handleDrawer}
          className="mobile-nav-menu-button d-flex flex-column justify-content-center align-items-center"
        >
          <AiOutlineClose className="mobile-nav-button-icon" />
          close
        </button>
      </div>
      <div className="mobile-dawer-body-cont">
        {section2Buttons.map((item, index) => {
          return item.title !== home_page.For_Dealers? (
            <div className="mobile-dawer-body-links-cont">
              <NavLink
                key={index}
                onClick={handleDrawer}
                exact
                to={item.to}
                className="mobile-dawer-body-links"
              >
                <span>{item.title}</span>
                <MdArrowForwardIos />
              </NavLink>
            </div>
          ) : (
            <div className="mobile-dawer-body-links-cont">
              <a
                // activeClassName="selected"
                className="mobile-dawer-body-links"
                href="https://dealers.sellanywheel.com"
                target="_blank"
              >
                <span>`${home_page.For_Dealers}`</span> <MdArrowForwardIos />
              </a>
            </div>
          );
        })}
      </div>
    </Fragment>
  );
};
