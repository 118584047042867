/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import Searchfilter from "../../components/Filter search bar/Filter_Search";
import ProductsListView from "../../components/Product list/Products";
import ProductsGridView from "../../components/Product grid/Products";
import Auctionlist from "../../components/Acution Listing/Filters";
import AuctionWon from "../../components/Auction Won/Auctionwon";
import { BsListUl, BsList, BsFillGridFill, BsGrid } from "react-icons/bs";
import { FaThList, FaFilter } from "react-icons/fa";
import Drawer from "../../components/Drawer";
// import { AiTwotoneEye, AiOutlineClockCircle } from "react-icons/ai";
// import { BsDot } from "react-icons/bs";
// import { GoLocation } from "react-icons/go";
// import { Card, Container, Row } from "react-bootstrap";
// import { BrowserRouter, Switch, Route, Link } from "react-router-dom";
import "./style.css";
import "./grid.css";
//
import { PRODUCT_LIST } from "../../constant";
import { GET } from "../../constant/apiMethods";
import { connect } from "react-redux";
import Labels from "../../services/apiLabels";
//

import { scrollTop } from "../../utils/window";
function AuctionDashboard(props) {
  const { product, getProductList, filtersQuery, navbarDimensions } = props;
  const { data } = product;
  
  
  const [mobileDrawer, setMobileDrawer] = useState(false);

  const [showgrid, setShowgrid] = useState(false);
  // useEffect(() => {
  //   if (product.data.length < 1 || filtersQuery) {
  //     getProductList();
  //   }
  // }, [filtersQuery]);
  const handleDrawer = () => {
    setMobileDrawer(!mobileDrawer);
  };

  useEffect(() => {
 
    scrollTop()
 
  }, [])
  
  return (
    <Fragment>
      <div className="landing_page_main_cont mt-5 ">
        <div className="landing_page_left_cont_wrapper  ">
          <div
            className="landing_page_left_cont mt-4 "
            style={{
              // position: "fixed",
              // left: 0,
              // top: navbarDimensions.height,
              height: `calc(100vh - ${navbarDimensions.height}px)`,
            }}
          >
            <Auctionlist />
          </div>
        </div>
        <div className=" product-list-cont ">
          <div className="buttons_tab_main_cont ">
            <div>
              <div className="mobile_filter_drawer_wrapper ">
                <button
                  onClick={handleDrawer}
                  className="mobile_filter_drawer_button "
                >
                  Filter <FaFilter style={{ fontSize: "0.9rem" }} />
                </button>
                <Drawer
                  visible={mobileDrawer}
                  handleDrawer={handleDrawer}
                  body={<Auctionlist handleDrawer={handleDrawer} />}
                  placement="left"
                />
              </div>
            </div>
            <div className="grid_list_toggle_button_group">
              <button
                style={{
                  backgroundColor: showgrid
                    ? "var(--secondary)"
                    : "var(--primary)",
                }}
                onClick={() => setShowgrid(false)}
                className="grid_list_toggle_button "
              >
                <FaThList
                  style={{ fontSize: "1.1rem" }}
                  className="grid_list_toggle_button_icon"
                />

                {showgrid ? null : <span style={{ marginLeft: 5 }}>List</span>}
              </button>
              <button
                style={{
                  backgroundColor: showgrid
                    ? "var(--primary)"
                    : "var(--secondary)",
                }}
                onClick={() => setShowgrid(true)}
                className="grid_list_toggle_button "
              >
                <BsFillGridFill
                  style={{ fontSize: "1.2rem" }}
                  className="grid_list_toggle_button_icon"
                />
                {showgrid ? <span style={{ marginLeft: 5 }}>Grid</span> : null}
              </button>
            </div>
          </div>
          {showgrid ? (
            <ProductsGridView loading={product.fetchingList} data={data} />
          ) : (
            <ProductsListView loading={product.fetchingList} data={data} />
          )}
        </div>
        <div className="landing_page_right_cont_wrapper ">
          <div
            className="landing_page_right_cont mt-5"
            style={{
              // position: "fixed",
              right: 0,
              // top: navbarDimensions.height,
              height: `calc(100vh - ${navbarDimensions.height}px)`,
            }}
          >
            <AuctionWon />
          </div>
        </div>
      </div>
    </Fragment>
  );
}
const mapStateToProps = (state) => ({
  product: state.product,
  filtersQuery: state.filters.queryString_,
});
const mapDispatchToProps = (dispatch) => ({
  // get product list
  getProductList: (data) =>
    dispatch({
      type: PRODUCT_LIST,
      payload: {
        type: PRODUCT_LIST,
        method: GET,
        label: Labels.product_list,
        hideOverlayLoader: true,
      },
    }),
  //
});

export default connect(mapStateToProps, mapDispatchToProps)(AuctionDashboard);
