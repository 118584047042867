// importing types from constants definded in constant/ index file
import {
  TOASTER_SHOW,
  TOASTER_HIDE,
  LOADER_SHOW,
  toaster_s,
  toaster_e,
  toaster_w,
} from "../constant/index";

const INITIAL_STATE = {
  show: false,
  type: "",
  heading: "",
  description: [],
  autoHide: false,
};

export default (state = INITIAL_STATE, action) => {
  const payload = action.payload;
  switch (action.type) {
    case TOASTER_SHOW:
      
      return {
        ...state,
        ...payload,
         description: payload.description.split("<br/>"),
      };
    case TOASTER_HIDE:
      return INITIAL_STATE;
    case LOADER_SHOW:
      return INITIAL_STATE;
  }
  return state;
};
