import React, { useEffect, useState } from "react";
import "./style.css";
import { connect } from "react-redux";
import { scrollTop } from "../../utils/window";
import { DOST_PROGRAM } from "../../constant";
import Labels from "../../services/apiLabels";
import { POST } from "../../constant/apiMethods";
import PuffLoader from "react-spinners/ClipLoader";
import CarFriend from "../../assets/images/banner-thumb.png";
import { useContext } from "react";
import FeedbackContext from "../../context/cmscontext";

function DostProgram({ submitData, dostProgramSignUp, dost_loading }) {
   const { feedback, isLoading } = useContext(FeedbackContext);
  var dost_program = feedback.dost_program;

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [postcode, setPostcode] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [loading, setLoading] = useState("false");

  useEffect(() => {
    scrollTop();
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();

    // setLoading("true");

    const data = {
      name: fullName,
      email: email,
      postcode: postcode,
      state: state,
      city: city,
      mobile_number: mobileNo,
    };
// if (data) {
  dostProgramSignUp(data)
// }
  };

  return (
    <>
      <div>
        <section className="lead_banner">
          <div className="container relative">
            <div className="row">
              <div className="col-lg-7 relative cars_th_wrap">
                <div className="">
                  <h1 className="dost">{dost_program.Become_Sell_Any_Wheel}</h1>
                  <p className="text-p fw-400 lead">
                    {dost_program.Refer_your_friends}
                  </p>
                  {/* Table */}
                  <div className="table-responsive">
                    <table className="dptable table table-sm">
                      <thead>
                        <tr>
                          <th width="75%">{dost_program.Value_of_car}</th>
                          <th width="25%">{dost_program.Reward_}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{dost_program.up_to_2_million}</td>
                          <td>{dost_program.Rs_15000}</td>
                        </tr>
                        <tr>
                          <td>{dost_program.up_to_4_million}</td>
                          <td>{dost_program.Rs_20000}</td>
                        </tr>
                        <tr>
                          <td>{dost_program.above_4_million}</td>
                          <td>{dost_program.Rs_25000}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/* Table ends */}
                </div>
              </div>
              <div className="col-lg-5">
                <div className="lead_banner_form_wrap mb-5">
                  <h5 className="thin_title">
                    {dost_program.Join_the_Dost_Army}
                  </h5>
                  <form onSubmit={submitHandler}>
                    <div className="lead_banner_pad">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="fullname"
                          required
                          placeholder={dost_program.Name_placeholder}
                          onChange={(e) => {
                            setFullName(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="email"
                          name="email"
                          required
                          placeholder={dost_program.Email_placeholder}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="postcode"
                          required
                          placeholder={dost_program.Postcode_placeholder}
                          onChange={(e) => {
                            setPostcode(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="state"
                          required
                          placeholder={dost_program.State_placeholder}
                          onChange={(e) => {
                            setState(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="city"
                          required
                          placeholder="City"
                          onChange={(e) => {
                            setCity(e.target.value);
                          }}
                        />
                      </div>
                      {/* <div className="form-group">
                        <select
                          className="form-control"
                          name="city"
                          required
                          onChange={(e) => {
                            setCity(e.target.value);
                          }}
                        >
                          <option value="Islamabad">
                            {dost_program.City_Name_placeholder}
                          </option>
                          <option value="Karachi">
                            {dost_program.Karachi_slect}
                          </option>
                          <option value="Lahore">
                            {dost_program.Lahore_slect}
                          </option>
                          <option value="Faisalabad">
                            {dost_program.Faisalabad_slect}
                          </option>
                          <option value="Gujranwala">
                            {dost_program.Gujranwala_slect}
                          </option>
                          <option value="Hyderabad">
                            {dost_program.Hyderabad_slect}
                          </option>
                          <option value="Multan">
                            {dost_program.Multan_slect}
                          </option>
                          <option value="Peshawar">
                            {dost_program.Peshawar_slect}
                          </option>
                          <option value="Sialkot">
                            {dost_program.Sialkot_slect}
                          </option>
                        </select>
                      </div> */}
                      <div className="form-group">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            name="mobile"
                            id="mobile"
                            placeholder={dost_program.Mobile_No_placeholder}
                            required
                            onChange={(e) => {
                              setMobileNo(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <ol>
                        <li>{dost_program.Your_mobile_number}</li>
                        <li>{dost_program.SellAnyWheel_can_reject}</li>
                        <li>{dost_program.SellAnyWheel_can_cancel}</li>
                        <li>{dost_program.Register_to_learn}</li>
                        <li>
                          {dost_program.SellAnyWheel_Dost_Program_Rewards}
                        </li>
                      </ol>
                      <label className="checkbox_wrap">
                        {dost_program.Terms_and_Conditions_text}
                        <input
                          type="checkbox"
                          name="termsConditions"
                          id="termsConditions"
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="mt-2 inp_main" id="inp_main">
                      {/* <div>
                        
                      </div> */}
                      <button name="CTAButton">
                        {dost_loading ? (
                          <PuffLoader size={15} color={"white"} />
                        ) : (
                          <>{dost_program.SignUp_button_text}</>
                        )}
                      </button>
                    </div>
                    {/* <p class="mt-1 mb-1" id="success" style="display: none;">
                      <i class="fas fa-check-circle text-success"></i>
                      Thank you! We will contact you soon
                    </p> */}
                  </form>
                  <div
                    className="success_loader"
                    id="success_loader"
                    style={{ display: "none" }}
                  />
                  {/*            thank you*/}
                </div>
              </div>
            </div>
            <div className="cars_th">
              <img src={CarFriend} alt="Banner" className="img-fluid" />
            </div>
          </div>
          {/* <div class="newbanner d-none"></div> */}
        </section>
        <section className="howitworks">
          <div className="container">
            <h3 className="text-capitalize mb-4 fw-700">
              {dost_program.How_It_Works_text}
            </h3>
            <div className="row">
              <div className="col-lg-4">
                <div className="stepbox">
                  <h6 className="fw-700">{dost_program.Step_1_text}</h6>
                  <p className="m-0">{dost_program.Step_1_desp}</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="stepbox">
                  <h6 className="fw-700">{dost_program.Step_2_text}</h6>
                  <p className="m-0">{dost_program.Step_2_desp}</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="stepbox">
                  <h6 className="fw-700">{dost_program.Step_3_text}</h6>
                  <p className="m-0">{dost_program.Step_3_desp}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({
  dost_program: state.datafile.dost_program,
  dost_loading: state.cardeailupdate.dost_loading,
});

const mapDispatchToProps = (dispatch) => ({
  dostProgramSignUp: (data) =>
    dispatch({
      type: DOST_PROGRAM,
      payload: {
        type: DOST_PROGRAM,
        label: Labels.dost_program,
        method: POST,
        data,
        hideOverlayLoader: true,
      },
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(DostProgram);
