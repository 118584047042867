import { formateNumber } from "./formating";
//
export const getNext7Days = () => {
  var date = new Date();

  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var todayObj = {
    id: date.getDay(),
    date: `${date.getDate()} ${month[date.getMonth()]}`,
    dateNum: `${date.getFullYear()}-${formateNumber(
      date.getMonth() + 1
    )}-${formateNumber(date.getDate())}`,
  };
  //   const month = [
  //     "January",
  //     "February",
  //     "March",
  //     "April",
  //     "May",
  //     "June",
  //     "July",
  //     "August",
  //     "September",
  //     "October",
  //     "November",
  //     "December",
  //   ];
  let days = [todayObj];
  for (let i = 2; i < 8; i++) {
    // 
    // // console.log("getNext7Days for loop 2 date.getday ", 2, date.getDate() + 2);
    date.setDate(date.getDate() + 1);
    days.push({
      id: date.getDay(),
      date: `${date.getDate()} ${month[date.getMonth()]}`,
      dateNum: `${date.getFullYear()}-${formateNumber(
        date.getMonth() + 1
      )}-${formateNumber(date.getDate())}`,
    });
  }
  
  return days;
};
export const formateDate = date_ => {
  let date = new Date(date_);
  return `${formateNumber(date.getDate())}-${formateNumber(
    date.getMonth() + 1,
  )}-${date.getFullYear()}`;
};