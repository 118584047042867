/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import Searchfilter from "../../components/Filter search bar/Filter_Search";
import ProductsListView from "../../components/Product list/Products";
import ProductsGridView from "../../components/Product grid/Products";
import Auctionlist from "../../components/Acution Listing/Filters";
import AuctionWon from "../../components/Auction Won/Auctionwon";
import { BsListUl, BsList, BsFillGridFill, BsGrid } from "react-icons/bs";
import { FaThList, FaFilter } from "react-icons/fa";
import Drawer from "../../components/Drawer";
// import { AiTwotoneEye, AiOutlineClockCircle } from "react-icons/ai";
// import { BsDot } from "react-icons/bs";
// import { GoLocation } from "react-icons/go";
// import { Card, Container, Row } from "react-bootstrap";
// import { BrowserRouter, Switch, Route, Link } from "react-router-dom";
import "../Auction/style.css";
import "../Auction/grid.css";
//
import {
  ShimmerThumbnail,
  ShimmerButton,
  ShimmerTitle,
 } from "react-shimmer-effects";
import Card from "../../components/Car_buy_card/index"
import Card2 from "../../components/Car_buy_card copy/index"
import { PRODUCT_LIST_BUY } from "../../constant";
import { GET } from "../../constant/apiMethods";
import { connect } from "react-redux";
import Labels from "../../services/apiLabels";
//

import { scrollTop } from "../../utils/window";
import Lottie from "lottie-react";
import loader from '../../assets/noCar.json'
function Buynow(props) {
  const { product, getProductList, filtersQuery,fetchingList, productbuy,getProductListbuy } = props;
  const { data } = productbuy;
  
  
  const [mobileDrawer, setMobileDrawer] = useState(false);

  const [showgrid, setShowgrid] = useState(false);
  useEffect(() => {
    // if (product.data.length < 1 ) {
      getProductListbuy()
    // }
  }, []);
  const handleDrawer = () => {
    setMobileDrawer(!mobileDrawer);
  };
  

  useEffect(() => {
 
    scrollTop()
 
  }, [])
  return (
    <Fragment>
      <div className="landing_page_main_cont mt-5 ">
        <div className="landing_page_left_cont_wrapper  ">
   
        </div>

        {
fetchingList?



<Fragment>


<div className="w-100">

<div className="container-fluid product_list_card_main_cont imageHover ">
  <div className="col-5 p-0">
    <ShimmerThumbnail
      className="product_list_card_image_wrapper"
      height={160}
    />
  </div>
  <div className="col-7 align-self-start pt-2 product_list_card_col_2 ">
    {/* <ShimmerButton size="lg" /> */}
    <ShimmerTitle className="w-75" line={1} gap={10} variant="primary" />
    <div className="d-flex justify-content-start align-items-center">
      <ShimmerTitle
        className="w-25 "
        line={1}
        gap={10}
        variant="primary"
      />

      <ShimmerTitle
        className="w-25"
        line={1}
        gap={10}
        variant="primary"
      />
    </div>
      <ShimmerButton size="md" />
  </div>
</div>

<div className="container-fluid product_list_card_main_cont imageHover ">
  <div className="col-5 p-0">
    <ShimmerThumbnail
      className="product_list_card_image_wrapper"
      height={160}
    />
  </div>
  <div className="col-7 align-self-start pt-2 product_list_card_col_2 ">
    {/* <ShimmerButton size="lg" /> */}
    <ShimmerTitle className="w-75" line={1} gap={10} variant="primary" />
    <div className="d-flex justify-content-start align-items-center">
      <ShimmerTitle
        className="w-25 "
        line={1}
        gap={10}
        variant="primary"
      />

      <ShimmerTitle
        className="w-25"
        line={1}
        gap={10}
        variant="primary"
      />
    </div>
      <ShimmerButton size="md" />
  </div>
</div>

</div>






</Fragment>

:
productbuy?
<>
<div className=" car_l">


<Lottie animationData={loader} style={{height:"70%",width:"30%"}} className="animi"  autoplay={true}  loop={true}/>
<h1 className="text-center no_car p-5">No Car Avaible</h1>
</div>
</>

:
<>
<div>

<div className=" product-list-cont ">
          <div className="buttons_tab_main_cont ">
            <div>
              <div className="mobile_filter_drawer_wrapper ">
             
            
              </div>
            </div>



     


           
            <div className="grid_list_toggle_button_group">
            
              <button
                style={{
                  backgroundColor: showgrid
                    ? "var(--secondary)"
                    : "var(--primary)",
                }}
                onClick={() => setShowgrid(false)}
                className="grid_list_toggle_button "
              >
            
                <FaThList
                  style={{ fontSize: "1.1rem" }}
                  className="grid_list_toggle_button_icon"
                />

                {showgrid ? null : <span style={{ marginLeft: 5 }}>List</span>}
              </button>
              <button
                style={{
                  backgroundColor: showgrid
                    ? "var(--primary)"
                    : "var(--secondary)",
                }}
                onClick={() => setShowgrid(true)}
                className="grid_list_toggle_button "
              >
                <BsFillGridFill
                  style={{ fontSize: "1.2rem" }}
                  className="grid_list_toggle_button_icon"
                />
                {showgrid ? <span style={{ marginLeft: 5 }}>Grid</span> : null}
              </button>
            </div>
          </div>
          {showgrid ? (
          <div className="grid_container">
      <div className="grid_card_wrapper">
      
   
      
      {[...productbuy,...productbuy].map((e) => (
          <div className="grid_card">
            <Card  loading={productbuy.fetchingList} Products={e} />
          </div>
        ))}
                 
      </div>
    </div>
          ) : (
          
          <>

{[...productbuy,...productbuy].map((e) => (
        <div  className="p-1">
            <Card2 Products={e} />
          </div>
        ))}


   
           
            </>
           
          )}


          
        </div>
</div>




</>
}





        <div className="landing_page_right_cont_wrapper ">
          <div
            className="landing_page_right_cont mt-5"
            style={{
              position: "fixed",
              right: 0,
           
      
            }}
          >

          </div>
        </div>
      </div>
    </Fragment>
  );
}
const mapStateToProps = (state) => ({
  productbuy: state.product.databuy,
  fetchingList: state.product.fetchingList,
 
});
const mapDispatchToProps = (dispatch) => ({
  // get product list
  getProductListbuy: (data) =>
    dispatch({
      type: PRODUCT_LIST_BUY,
      payload: {
        type: PRODUCT_LIST_BUY,
        method: GET,
        label: Labels.buynow_list,
        hideOverlayLoader: true,
      },
    }),
  //
});

export default connect(mapStateToProps, mapDispatchToProps)(Buynow);
