import React, { useState, useEffect } from "react";
import "./style.css";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import { HiChevronRight, HiCheck } from "react-icons/hi";
import LandingSection from "../../components/LandingSection";
import Steps from "./Steps";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { formateKey } from "../../utils/formating";
import OverLayLoader from "../../components/OverLayLoader";
import { SELL_MY_CAR_STEPS } from "../../constant";
import Greatings from "./Greatings";
import { sellMyCarStepsName } from "../../constant/constants";
//
import { useContext } from "react";
import number_logo from "../../assets/images/logo2.PNG";
import FeedbackContext from "../../context/cmscontext";
function Evaluation(props) {
  const { feedback, isLoading } = useContext(FeedbackContext);
  var evaluation_page = feedback.evaluation_page;
  const {
    step1,
    step2,
    currentStep,
    setnext_step,
    setprev_step,
    setcurrentStep,
  } = props;
  const dimensions = { height: "calc( 100vh / 1.7 )", width: "100%" };
  //
  console.log("step1===============",step1,currentStep)
  const history = useHistory();
  // //checks if step one form is not completed
  useEffect(() => {
    // console.log("step1===============",step1,currentStep)
    if (!step1["variant"].isSelected || currentStep < 2) {
      // console.log("step1==============inside useefct=",step1,currentStep)
      history.push("/");
      // setcurrentStep(4);
    }
  }, [step1]);
  //
  //
  const NumberIcon = (props) => {
    const { id, size, className } = props;
    return (
      <span style={{ display: "inline-block" }}>
        <span
          style={size ? { width: size, height: size } : {}}
          className={`numberIcon ${className} `}
        >
          {id}
        </span>
      </span>
    );
  };
  //
  const s1c2Items = [
    { text: " No hassle with the paperwork." },
    { text: " Get paid immediately." },
    { text: " Sell your car in an hour." },
  ];
  //
  const s2lcItems = [
    {
      text: evaluation_page.What_happens_text1,
    },
    {
      text: evaluation_page.What_happens_text2,
    },
    { text: evaluation_page.What_happens_text3 },
    { text: evaluation_page.What_happens_text4 },
    { text: evaluation_page.What_happens_text5 },
  ];
  //
  const List = (props) => {
    const { title, value } = props;

    return (
      <div className="d-flex ">
        <span
          className="text-capitalize text-right elevation_s1_lc_cont_1_t_1"
          style={{ margin: "0.2rem 0.5rem", fontWeight: "bold" }}
        >
          {formateKey(title)} :
        </span>
        <span
          className="text-capitalize elevation_s1_lc_cont_1_t_1"
          style={{ margin: "0.2rem 0.5rem" }}
        >
          {value}
        </span>
      </div>
    );
  };
  //
  const LeftCont2 = ({ title }) => {
    // return s1c2Items.map((item, index) => (
    //   <span
    //     key={index}
    //     className="d-flex align-items-center mt-3 elevation_s1_lc_cont_1_t_1"
    //   >
    //     <HiCheck
    //       color="var(--secondary)"
    //       size={20}
    //       style={{ background: `var(--primary)` }}
    //       className="rounded-circle mr-2 p-1"
    //     />
    //     {item.text}
    //   </span>
    // ));
    return (
      <>
        <div className="overflow-hidden">
          {title && <div className="evaluation_s2_heading">{title}wret</div>}
          <List title="model" value={step1["model"]} />
          <List title="fuel type" value={step1["fuel_type"]} />
          <List title="year" value={step1["year"]} />
          {currentStep > 2 ? (
            <div
              className={
                currentStep === 3
                  ? "animate__animated animate__fadeInLeftBig"
                  : ""
              }
            >
              <List title="mileage" value={step2["mileage"]} />
              <List title="previous_owners" value={step2["previous_owners"]} />
              <List
                title="services_history"
                value={step2["services_history"]}
              />
            </div>
          ) : null}
        </div>
      </>
    );
  };
  //
  if (!step1["variant"].isSelected || currentStep < 2)
    return <OverLayLoader alwaysShow={true} />;

  return (
    <div style={{ margin: "1rem 0", height: "auto" }}>
      {/* ----------------------------- section 1 ---------------------- */}
      <div
        className="banner_main_cont evaluation_section_1"
        style={{ zIndex: 1 }}
      >
        {/* background gradient */}
        <div
          // style={{ ...dimensions }}
          className="banner_background_wrapper evaluation_background_gradient_wrapper "
        >
          <div className="position-relative">
            <div className="evaluation_background_gradient" />
          </div>
        </div>
        {/*  */}
        {currentStep <= sellMyCarStepsName.length - 2 ? (
          <div className="d-flex flex-lg-row flex-xl-row flex-column">
            <div className="d-flex justify-content-xl-end justify-content-lg-end justify-content-center  pt-5  landingmain_banner_left_cont">
              <div className="elevation_s1_lc">
                <div className="dropdown_wrapper">
                  <div
                    className="step_num"
                    style={{
                      fontSize: "1.3rem",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      color: "var(--info)",
                    }}
                  >
                    {sellMyCarStepsName[currentStep]}
                  </div>
                </div>
                <div className="main_wrap_onboth ">
                  <div className="background-info w-100 cardShadow   p-3  d-flex flex-column justify-content-center align-items-center rounded elevation_s1_lc_cont_1">
                    <div>
                      {/* <img
                        className="model_img"
                        src={step1["brand"].current.brand_image}
                      ></img> */}
                    </div>
                    {true ? (
                      <>
                        <div className="d-flex bot_wrap mt-2">
                          <img className="img_d" src={number_logo}></img>
                          <div className="car_num_clr">{step2.reg_number}</div>
                        </div>
                      </>
                    ) : null}

                    <div className="elevation_s1_lc_cont_1_price">
                      <span>{`${step1.brand}-${step1.variant.variant} `}</span>
                      {/* <span> {car_color ? car_color.vehicle.colour : ""}</span> */}
                    </div>
                    <div className="background-info w-100 p-3 rounded elevation_s1_lc_cont_2">
                      <div className="car_info_wrap">
                        <span
                          className="text-capitalize elevation_s1_lc_cont_1_t_1"
                          style={{
                            margin: "0.2rem 0.5rem",
                            fontWeight: "bold",
                          }}
                        >
                          {step1["model"]}-{step1["brand"]}-
                          {step1["brand"].color ? "-" : null}
                          {step1["fuel_type"]}-{step1["year"]}
                        </span>
                        <br></br>
                        <span
                          className="text-capitalize elevation_s1_lc_cont_1_t_1"
                          style={{
                            margin: "0.2rem 0.5rem",
                            fontWeight: "bold",
                          }}
                        >
                          {currentStep > 2 ? (
                            <>
                              {" "}
                              {step2["mileage"]}-{step2["previous_owners"]}-
                              {step2["services_history"]}
                            </>
                          ) : null}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column align-items-center  p-2 pt-0 landingmain_banner_right_cont">
              <Steps
                currentStep={currentStep}
                setcurrentStep={setcurrentStep}
              />
            </div>
          </div>
        ) : (
          <Greatings />
        )}
      </div>
      {/* ----------------------------- section 2 ---------------------- */}
      {currentStep <= sellMyCarStepsName.length - 6 ? (
        <>
          <div className="banner_main_cont">
            {/*  */}
            <div className="d-flex flex-lg-row flex-xl-row flex-column">
              <div className="d-flex justify-content-xl-end justify-content-lg-end justify-content-center  landingmain_banner_left_cont">
                <div className=" evaluation_s2_lc_heading">
                  <div className="evaluation_s2_heading">
                    {evaluation_page.What_happens_inspection}
                  </div>
                  <ol className="evaluation_s2_orderlist">
                    {React.Children.toArray(
                      s2lcItems.map((item, index) => {
                        return <li className="w-100">{item.text}</li>;
                      })
                    )}
                  </ol>
                </div>
              </div>
              <div
                className="d-flex flex-column align-items-center  p-2 pt-0 landingmain_banner_right_cont"
                style={{ zIndex: -1 }}
              >
                {/* responsive cont */}
                {/* <div className="background-info w-100 p-3 elevation_s1_lc_cont_2_r mt-0 rounded ">
                  <LeftCont2 title="Car Details" />
                </div> */}
              </div>
            </div>
          </div>
          {/* ----------------------------- Footer ---------------------- */}
        </>
      ) : null}
    </div>
  );
}
const mapStateToProps = (state) => ({
  // evaluation_page: state.datafile.evaluation_page,
  step1: state.sellMyCar.step1,
  step2: state.sellMyCar.step2,
  currentStep: state.sellMyCar.currentStep,
});
const mapDispatchToProps = (dispatch) => ({
  setcurrentStep: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "current_step",
        data,
      },
    }),
  setnext_step: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "next_step",
        data,
      },
    }),
  setprev_step: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "prev_step",
        data,
      },
    }),
});
export default connect(mapStateToProps, mapDispatchToProps)(Evaluation);
