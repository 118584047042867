// packeges
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import $ from "jquery";
// components
import "../Login Page/loginstyle.css";
import carlogo from "../../assets/images/finallogo.png";
import { Link, NavLink, useHistory } from "react-router-dom";
// redux related things
import Footer from "../../components/Footer"

import { connect } from "react-redux";
import Labels from "../../services/apiLabels";
import { SIGN_UP, TOASTER_SHOW, toaster_w } from "../../constant";
import { get } from "../../services/localStorage";
import { POST } from "../../constant/apiMethods";
// functional component
function Signup(props) {
  const { auth, signUp, toasterShow ,SIGN_UP_done} = props;
  let history = useHistory();
  // const [email, setEmail] = useState("");
  // const [username, setUsername] = useState("");
  // //  user name handler
  // useEffect(() => {
  //   if (email) {
  //     setUsername(email.split("@")[0]);
  //   } else {
  //     setUsername("");
  //   }
  // }, [email]);


// console.log("chk auth data",auth,"sigb up=======>>>>>>>",SIGN_UP_done)
useEffect(() => {
  
if(SIGN_UP_done.token){
  history.push("/login");
  SIGN_UP_done.token=""

}


}, [SIGN_UP_done])















  const signUpHandler = () => {
    let firstname = $("#first_name").val();
    let lastname = $("#last_name").val();
    let email = $("#email").val();
    let username = $("#username").val();
    let password = $("#password").val();
    const remember_me = $("#remember_me").is(":checked");
    const data = {
      firstname,
      lastname,
      username,
      password,
      email,
    };
    // 
    if (firstname && lastname && email && username && password) {
      signUp(data);
    } else {
      toasterShow({
        show: true,
        autoHide: false,
        type: toaster_w,
        description: `please enter ${!firstname ? "First Name" : ""}${
          !lastname ? ", Last Name" : ""
        } ${!email ? ", Email Address" : ""} ${
          !username ? ", User Name" : ""
        } ${!password ? ", Password" : ""} & try again`,
      });
    }
  };
  const handleNavigation = () => {
    let a = get({ key: "auth" });
    
    // history.replace("/auction");
  };

  return (
    <>
      <div className="loginbody_signup">
        {/* ==============shape 1 ================= */}
        <div className="shape" />
        {/* =================shape 2  ================ */}

        <div className="shape2"></div>
        <div className="loginMainview">
          {/* ==========rightside================== */}
          <div className="Leftsides">
            <div className="loginfields">
           

              <div className="loginfieldwrapper">
                <Col className=" mobileform">
                  <div className="formcontainer">
                    <h5 className="logintext">Sign-up</h5>
                    <div className="registration">
                      <div className="form-group">
                        <label for="first_name">First Name</label>
                        <input
                          id="first_name"
                          type="text"
                          className="form-control"
                          placeholder="First Name"
                        />
                      </div>

                      <div className="form-group">
                        <label for="last_name">Last Name</label>
                        <input
                          id="last_name"
                          type="text"
                          className="form-control"
                          placeholder="Last Name"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label for="email">Email address</label>
                      <input
                        id="email"
                        type="email"
                        className="form-control"
                        placeholder="Enter email"
                        // onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label for="email">User Name</label>
                      <input
                        id="username"
                        type="text"
                        // value={username}
                        className="form-control"
                        placeholder="Enter User Name"
                      />
                    </div>
                    <div className="form-group">
                      <label for="password">Password</label>
                      <input
                        id="password"
                        type="password"
                        className="form-control"
                        placeholder="Enter password"
                      />
                    </div>
                    <div className="form-group">
                      <div className="custom-control custom-checkbox">
                        <input
                          id="remember_me"
                          type="checkbox"
                          className="custom-control-input"
                          // id="customCheck1"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheck1"
                        >
                          Remember me
                        </label>
                      </div>
                    </div>
                    <button
                      onClick={signUpHandler}
                      // to="/auction"
                      // type="submit"
                      className="btn btn-primary btn-block"
                    >
                      Register
                    </button>
                    <p className=" text-right">
                      Already Have a Account <Link to="/login">Login?</Link>
                    </p>
                  </div>
                </Col>
                <Col className="mobileimagecontainer">
                  <div className="carimage">
                    <div className="logincardimage">
                      <img src={carlogo} alt="BigCo Inc. logo" />
                    </div>
                    <div className="imagetext">
                      <p>
                        Lorem ipsum is placeholder text commonly used in the.
                      </p>
                    </div>
                  </div>
                </Col>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fffffffffffffffffff"  >
      <Footer/>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  SIGN_UP_done: state.auth.SIGN_UP_done,
});
const mapDispatchToProps = (dispatch) => ({
  // login action
  signUp: (data) =>
    dispatch({
      type: SIGN_UP,
      payload: { type: SIGN_UP, method: POST, label: Labels.signUp, data },
    }),
  //
  toasterShow: (data) =>
    dispatch({
      type: TOASTER_SHOW,
      payload: {
        ...data,
      },
    }),
  //
});

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
