import React from "react";
import "./style.css";
import pic2 from "../../assets/svg/car_no_image.jpg";
import FeedbackContext from "../../context/cmscontext";
import { useContext } from "react";
function Carcard({ item, onClick, mycar, index }) {
  const { feedback, isLoading } = useContext(FeedbackContext);
  var myAppointment_page = feedback.myAppointment_page;
  return (
    <>
      <div className="row mdiv1 m-3 " onClick={onClick}>
        <div className="col-sm-12 col-lg-5 col-md-5 cola p-4">
          {mycar[index].interior_images_ < 1 ? (
            <img className="img1" src={pic2} />
          ) : (
            <img
              className="imga1"
              src={mycar[index].interior_images_[0].image}
              data-toggle="modal"
              data-target="#exampleModalLong"
            />
          )}
        </div>

        <div className=" col-sm-12 col-lg-7 col-md-7 p-5 colb">
          <span className="product_list_card_title brndname">
            {item.brand_name}
          </span>
          <div className="mt-2">
            <span className="modelvarint ">
              {item.Mileage}-{item.variant_name}
            </span>
            <br />
            <span className="product_list_card_features_text txt mt-4">
              {myAppointment_page.postcode_text} {item.Postcode}
            </span>
            <br />
            <span className="product_list_card_features_text txt">
            {myAppointment_page.previous_owners_text} {item.Previous_owners}
            </span>
            <br />
            <span className="product_list_card_features_text txt">
              {myAppointment_page.service_history_text} {item.Service_history}
            </span>
            <br />
            <span className="product_list_card_features_text txt">
              {myAppointment_page.fuel_type_text} {item.fuel_type}
            </span>
            <br />
            <span className="product_list_card_features_text txt">
            {myAppointment_page.model_name} {item.model_name}
            </span>
            <br />
            <span className="product_list_card_features_text txt">
            {myAppointment_page.reg_number_text} {item.reg_number}
            </span>
            <br />
            <span className="product_list_card_features_text txt">
            {myAppointment_page.year_name_text} {item.year_name}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Carcard;
