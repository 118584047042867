import { React, useEffect, useState } from "react";

import "./style.css";
import axios from "axios";

import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { SALE_PRODUCT_LIST,CITY_DETAIL_LIST,CHAKBOX_LIST } from "../../constant";
import { GET } from "../../constant/apiMethods";
import { connect } from "react-redux";
import Labels from "../../services/apiLabels";


function CheckboxList(props) {

    const { product, productList, cityes_List_Data , Chkbox_action} = props;



    const [userinfo, setUserInfo] = useState({
        languages: [],
        response: [],
      });
    
    // console.log("chk aryyyyy",userinfo)
    
      const Chkboxvaluehandler=(e)=>{
    
        const { value, checked } = e.target;
    
        const { languages } = userinfo;
    
        if (checked) {
          setUserInfo({
            languages: [...languages, value],
            response: [...languages, checked],
          });


          Chkbox_action({userinfo})


        }
    
    
    // console.log("chk box e ",e.target)
    // console.log("chk box e ",e.target.value)
    // console.log("chk box e ",e.target.checked )
      }
    
      // const [chkbox,setchkbox] = useState("");
      // // console.log("chk box value",chkbox)
    







    return (
        <>
        <div className="showInterest_headingCont ">
        <div className="showInterest_heading">Who we are</div>
        <div className="showInterest_description">
          We are transforming the way used cars are traded
        </div>
      </div>
        <div className="container inp_main_wrapp">
             <div className="main_Wrap col-lg-5 col-md-5 col-sm-12 ">
               <div className="inp_wrap my-2 d-flex">
               <FormGroup>
           <FormControlLabel control={<Checkbox  value="abc" onChange={Chkboxvaluehandler} />} label="Label" />
      
         </FormGroup>
               </div>
               <div className="inp_wrap my-2">
               <FormGroup>
           <FormControlLabel control={<Checkbox value="abc2" onChange={Chkboxvaluehandler} />} label="Label" />
      
         </FormGroup>
     
               </div>
               <div className="inp_wrap my-2">
               <FormGroup>
           <FormControlLabel control={<Checkbox value="abc3" onChange={Chkboxvaluehandler}  />} label="Label" />
      
         </FormGroup>
     
               </div>
               <div className="inp_wrap my-2">
               <FormGroup>
           <FormControlLabel control={<Checkbox  />} label="Label" />
      
         </FormGroup>
     
               </div>
     
             </div>
           </div>
       
           <br></br>
               <br></br>
               <br></br>
               <br></br>
         
         </>
    );
}




const mapStateToProps = (state) => ({

cityes_List_Data: state.cardeailupdate,

});
const mapDispatchToProps = (dispatch) => ({
    // get product list
    Chkbox_action: (data) =>
        dispatch({
            type: CHAKBOX_LIST,
            payload: {
                type: "add_city",
                data,
              },
        }),
    SaleProductList: (data) =>
        dispatch({
            type: SALE_PRODUCT_LIST,
            payload: {
                type: SALE_PRODUCT_LIST,
                method: GET,
                label: Labels.sale_product,
                hideOverlayLoader: true,
            },
        }),
    //
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckboxList);


