import React from "react";
import Carpic from "../../assets/images/a1.PNG";
import pic from "../../assets/images/q.jpg";
import { HiCheck } from "react-icons/hi";

import { useContext } from "react";
import FeedbackContext from "../../context/cmscontext";
import "./style.css";
function What() {


   const { feedback, isLoading } = useContext(FeedbackContext);
   var home_page = feedback.home_page;

  return (
    <>
      <div className="p-5">
        <h2 className="text-center">{home_page.what_expect_heading}</h2>
        <h3 className="text-center">{home_page.what_expect_desp}</h3>
        <div className="row">
          <div className=" p-5 col-sm-12 col-md-12 col-lg-6 ">
            <img
              height="400"
              width="300"
              // src={pic}
              src={home_page.what_expect_img}
              alt=""
            />
          </div>
          <div className=" p-5 col-sm-12 col-md-12 col-lg-6 ">
            <div className="">
              <div className="d-flex  pb-4">
                <HiCheck
                  color="white"
                  size={20}
                  style={{ background: `var(--primary)` }}
                  className="rounded-circle mr-3 p-1"
                />
                <div>
                  <h3>{home_page.what_expect_list_heading1}</h3>
                </div>
              </div>
              <p className="w-75 marginLeft mb-3">
                {home_page.what_expect_list_heading1_desp}
              </p>
            </div>
            <div>
              <div className="d-flex  pb-4">
                <HiCheck
                  color="white"
                  size={20}
                  style={{ background: `var(--primary)` }}
                  className="rounded-circle mr-3 p-1"
                />
                <div>
                  <div>
                    <h3>{home_page.what_expect_list_heading2}</h3>
                  </div>
                </div>
              </div>
              <p className="w-75 marginLeft mb-3">
                {home_page.what_expect_list_heading2_desp}
              </p>
            </div>

            <div>
              <div className="d-flex  pb-4">
                <HiCheck
                  color="white"
                  size={20}
                  style={{ background: `var(--primary)` }}
                  className="rounded-circle mr-3 p-1"
                />

                <div>
                  <h3>{home_page.what_expect_list_heading3}</h3>
                </div>
              </div>
              <p className="w-75 marginLeft mb-3">
                {home_page.what_expect_list_heading3_desp}
              </p>
            </div>

            <div>
              <div className="d-flex  pb-4">
                <HiCheck
                  color="white"
                  size={20}
                  style={{ background: `var(--primary)` }}
                  className="rounded-circle mr-3 p-1"
                />
                <div>
                  <h3>{home_page.what_expect_list_heading4}</h3>
                </div>
              </div>
              <p className="w-75 marginLeft mb-3">
                {home_page.what_expect_list_heading4_desp}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default What;
