import { SHIPING_COST} from "../constant/index";
import React, {useState, Fragment} from 'react'
const INITIAL_STATE = {
  home_page: {
    // ==================navbar================

    Home: "Home",
    My_Appointment: "My Appointment",
    Dost_Program: "Refrral Program",
    Sell_Any_Wheel: "Sell Any Wheel",
    How_Works: "How Works",
    For_Dealers: " For Dealers",
    More: "More",
    More_Contact: "Contact",
    More_Privacy_Policy: "Privacy Policy",
    More_FAQs: "FAQs",
    More_Documents: "Documents",
    More_About_us: "About us",

    // =============baner=============

    banner_background_image:
      "http://localhost:3000/static/media/landingPageBanner.791494f2.jpg",
    banner_background_image2:
      "http://localhost:3000/static/media/landingPageBanner.791494f2.jpg",
    car_reg_text: "Please Enter Your Car Reg Number For Car Details.",
    car_reg_placeholder: "Enter Reg",
    car_reg_button_text: "Value your car",
    car_reg_button_loding_text: "Loading",
    baner_heading:
      " Selling, buying and exchanging your car has never been this easy",
    baner_sub_heading1: "No hassle with the paperwork",
    baner_sub_heading2: " Get paid immediately",
    baner_sub_heading3: " Sell your car in an hour",

    // ===================Sell your car =============

    sell_your_car: "Sell Your Car",
    get_car_price_button: "Get Car Price",
    brand_title: "Brand",
    model_title: "Model",
    fuel_type__title: "Fuel Type",
    year_title: "Year",
    Variant_title: "Variant",

    // =======================What does WFAC offer?=======================
    what_doest: " What does WFAC offer?",
    buy_car_heading: " Buy a car",
    buy_car_desp: " Browse through 1000+ cerified cars",
    sell_your_car: " Sell your  car",
    sell_your_car_desp: " Sell your car instantly and safely within an hour",
    exchange_car: " Exchange your car",
    exchange_car_desp: " Exchange for a new or used car",
    exchange_car_cooming_soon: " Cooming Soon ",
    // ========================what expact===============
    what_expect_img:
      "https://www.carfirst.com/public/assets/why-choose-us-alt/whychooseus-pk.jpg",
    what_expect_heading: "What to expect",
    what_expect_desp: " How do we compare with other ways to sell?",
    fair_prices_heading: "    Fair prices",
    fair_prices_desp:
      "  Whether you sell, buy or exchange your car, we can assure market-conform and fair prices.",
    A_clear_heading: "A clear and transparent process",
    A_clear_desp:
      "We offer a clear and transparent process for all of our clients using any of our services. We will help you in every step on the way.",
    Secure_safe_heading: "Secure and safe transactions",
    Secure_safe_desp:
      "Never need to worry anymore about unsafe payments. We got your back with our secure and certified RC transfers.",
    We_arrange_heading: "We arrange your paperwork",
    We_arrange_desp:
      " Never worry about the hassle of doing paperwork. Just bring the required documents and we will do the rest.",

    // =======================happy coutmers==============
    happy_customers_heading: "1000+ happy customers",
    happy_customers_desp:
      " Don't take our word for it! Read our customer's reviews.",

    // =================================how its works===========

    How_Works_heading: "How it Works",
    Profile_your_car_heading: "1. Profile your car",
    Profile_your_car_img: "http://localhost:3000/static/media/a1.415c2f05.PNG",
    Profile_your_car_desp:
      "  Ready to sell for up to £1,000* more? Enter your reg to get aninstant valuation and create a car profile from your phone.Easy.",
    We_do_img: "http://localhost:3000/static/media/a2new.40d2a894.png",
    We_do_heading: "2.  We do our thing",
    We_do_heading_desp:
      "  We alert the 5,000+ car dealers in our nationwide network and they make their best offer for your car in our daily online sale.",
    Get_your_best_img: "http://localhost:3000/static/media/a3.52d23dc7.PNG",
    Get_your_best_heading: "3. Get your best offer",
    Get_your_best_desp:
      "  We'll present the highest offer for you to approve, then arrange a date for your car to be collected, for free, of course.",
    Complete_your_img: "http://localhost:3000/static/media/a4new.2e32c346.png",
    Complete_your_heading: " 4. Complete your sale",
    Complete_your_desp:
      " You receive fast payment† in your bank account within 24 hours of collection. Ta-dah, you've sold your car the Motorway way!",

    // ========================footer====================/
    any_question: "Any question ?",
    any_question_desp:
      "You'll find the answers Of your questions in our FAQs section.",
    call_number: "123 456 789",
    call_time: "11:30am - 07:30pm",
    contact_us: "Contact",
    Privacy_Policy: "Privacy Policy ",
    FAQs: "FAQs",
    Required_documents: "Required documents",
    Terms_conditions: "Terms and conditions",
    About_us: "About us ",
    what_do_you_want: "What do you want to do? ",
    range_of_services: "Choose from our range of services. ",
    Buy_Now_button_text: "Buy Now ",
    Auction_button_text: "Auction ",
    Interest_button_text: "Interest",
    Subscribe_text: "Subscribe",
    Subscribe_desp: " Subscribe our newsletter to stay update every moment",
    Subscribe_placeholder: " abc@gmail.com ",
    Subscribe_button_text: " Subscribe",

    // ======================mini foter================
    All_right_text: " All Rights Reserved",
    All_right_year: " 2020",
  },

  evaluation_page: {
    We_want_to_buy: " We want to buy your car",
    What_happens_inspection: " What happens at the inspection?",
    What_happens_text1:
      "We Will carefully inspect your car and will give you a final offer.",
    What_happens_text2:
      "Decide wheather to accept the offer, there's no obligation to sell.",
    What_happens_text3: "If you accept, we take care of all the paperwork.",
    What_happens_text4:
      "We'll transfewr your money safely and securely - instantly!",
    What_happens_text5: "Kaching!",

    // ==============regestion form================

    Continue_button_text: "Continue",
    Registration_Number_heading: "Registration Number",
    Registration_Number_placeholder: "Enter Registration Number",
    Mileage_heading: "Mileage",
    Mileage_placeholder: "Enter Mileage",
    Previous_Owners_heading: "Previous Owners",
    // 'Previous_Owners_number1':"1",
    Previous_Owners_number1: 1,
    Previous_Owners_number2: 2,
    Previous_Owners_number3: 3,
    Previous_Owners_number4: 4,
    Previous_Owners_number_5_or_more: "5 or more",
    Previous_Owners_number_do_not_know: "Don't know",
    Services_History_label: "Services History",
    Services_History_Full: "Full",
    Services_History_Part: "Part",
    Services_History_None: "None",
    Services_History_First_not_Due: "First not Due",

    // =======================================Get my valuation===========================

    Get_my_valuation_buton_text: "Get my valuation",
    Get_my_valuation_buton_text_loading: "Getting your valuation ...",
    Your_Name_label: "Your Name",
    Your_Name_placeholder: "Your Name",
    Your_Email_label: "Your Email",
    Your_Email_placeholder: "Your Email",

    Your_Postcode_label: "Your Postcode",
    Your_Postcode_placeholder: "Your Postcode",
    Your_Mobile_Number_label: "Your Mobile Number",
    Your_Mobile_Number_placeholder: "Your Mobile Number",

    // =================appoitnment create =======
    " Your_appointment_text": " Your appointment",
    Get_your_appointment_button_text: "Get your appointment",
    Get_your_appointment_button_loading: "Getting your appointment ...",
    Office_label: "Office",
    Appointment_Time_label: "Appointment Time:",

    // ===========================================gratings------------------

    Whats_next_text1:
      "We Will carefully inspect your car and will give you a final offer.",
    Whats_next_text2:
      "Decide wheather to accept the offer, there's no obligation to sell.",
    Whats_next_text3: "If you accept, we take care of all the paperwork.",
    Whats_next_text4:
      "We'll transfewr your money safely and securely - instantly!",
    Whats_next_text5: "Kaching!",

    Thank_you: "Thank You",
    Please_check_your_email:
      " Please check your email address we have sent you an email with the URL. You are advised to open that URL in mobile device and providethe pictures or information of your vehicle it will add more value to your call and help us find a best price to flip your car.",
    Back_to_Home: " Back to Home",

    // ==================booking=============
    Booking_Details: "Booking Details",
    Adress_label: "Adress",
    Time_label: "Time",
    Contact_label: "Contact",
    Tracking_Number_label: "Tracking Number",

    // =====================user detail=============
    Name_label: "Name",
    Email_label: "Email",
    Post_Code_label: "Post Code",
    Mobile_Number_label: "Mobile Number",

    // =====================car detail=============
    brand_label: "brand",
    model_label: "model",
    fuel_type_label: "fuel type",
    year_label: "year",
    mileage_label: "mileage",
    previous_owners: "previous_owners",
    services_history_label: "services_history",

    Print_Confirmation_button_text: "Print Confirmation",
  },
  car_steps_page: {
    Main_heading: "Steps to Reproduce",
    main_heading_desp:
      "Complete the steps which are not complete it will help us to vlaue your car more properly",

    Contact_Details: "Contact Details",
    Your_Contact_Information: "Your Contact Information",
    Contact_Complate_text: "Complate",
    Contact_Pending_text: "Pending",
    Contact_Done_text: "Done",
    Contact_Start_text: "Start",

    Vehicle_Details: "Vehicle  Details",
    Vehicle_Information: "Information about your vehicle",
    Vehicle_Complate_text: "Complate",
    Vehicle_Pending_text: "Pending",
    Vehicle_Done_text: "Done",
    Vehicle_Start_text: "Start",

    Book_Appoinment_Details: "Book Appoinment",
    Book_Appoinment_Information: "Book your Appointment",
    Book_Appoinment_Complate_text: "Complate",
    Book_AppoinmentPending_text: "Pending",
    Book_Appoinment_Done_text: "Done",
    Book_Appoinment_Start_text: "Start",
    Photos_text: "Photos",
    Photos_desp1:
      "Please find the link in your email address to provide the Photos of your Car. Using your mobile device",
    Photos_desp2:
      "Please provide the contact details to get the url to upload photos of your car",
  },
  carupdate_page: {
    Save_and_exit: "Save & exit",
    Previous_button_text: "Previous",
    Next_button_text: "Next",

    // =====================serach============city=============

    Provide_Your_Address: "Provide Your Address",
    Enter_Your_Address: "Enter the address whare vehicle is located  ",
    Line_1_text: "Line 1",
    Line_1_placeholder: "Line 1",
    Line_2_text: "Line 2",
    Line_2_placeholder: "Line 2",
    City_text: "City",
    City_placeholder: "City",
    Postcode_text: "Postcode",
    Postcode_placeholder: "Postcode",
    Edit_Addres_text: "Edit Addres",
    Submit_text: "Submit",
    "Enter_postcode_first_line ":
      "Enter postcode or the first line of the address",
    serch_placeholder: "Search",
  },

  dost_program: {
    main_image: "http://localhost:3000/static/media/banner-thumb.76727331.png",

    Become_Sell_Any_Wheel: " Become a Sell Any Wheel Referral and Earn Cash!",
    Refer_your_friends:
      "Refer your friends to sell their cars to Sell Any Wheel and earn thousands in cash rewards. Whenever you refer a friend to sell their car to Sell Any Wheel, you put cash in your pocket!",
    Value_of_car: "Value of car referred",
    Reward_: "Reward",
    up_to_2_million: "up to 2 million",
    Rs_15000: "  Rs. 15,000",

    up_to_4_million: "up to 4 million",
    Rs_20000: "  Rs. 20,000",

    above_4_million: " above 4 million",
    Rs_25000: "  Rs. 25,000",

    Join_the_Dost_Army: " Join the Referral Program by filling the form below:",
    Name_placeholder: "Full Name",
    Email_placeholder: "Email Address",
    Postcode_placeholder: "Postcode",
    State_placeholder: "State",
    City_Name_placeholder: "City Name",
    Karachi_slect: "Karachi",
    Lahore_slect: "Lahore",
    Faisalabad_slect: "Faisalabad",
    Gujranwala_slect: "Gujranwala",
    Hyderabad_slect: "Hyderabad",
    Multan_slect: "Multan",
    Peshawar_slect: "Peshawar",
    Sialkot_slect: "Sialkot",
    Mobile_No_placeholder: "Mobile No",

    Your_mobile_number:
      "Your mobile number will be your unique reference code.",
    SellAnyWheel_can_reject:
      "Sell Any Wheel can reject any application any point in time at our discretion.",
    SellAnyWheel_can_cancel:
      "Sell Any Wheel can cancel/change/end any or all elements of the program at any point without prior notice.",
    Register_to_learn: "Register to learn more details.",
    SellAnyWheel_Dost_Program_Rewards:
      "Sell Any Wheel Referral Program Rewards and Incentives are only eligible for payout on non-promo sales",
    Terms_and_Conditions_text:
      " I have read and agreed to the Terms and Conditions.",
    SignUp_button_text: "Sign Up",

    How_It_Works_text: "How It Works",

    Step_1_text: "Step 1: Register Online",
    Step_1_desp:
      "Signup to be a part of the Referral Program and start referring your friends to Sell Any Wheel",

    Step_2_text: "Step 2: Online Booking",
    Step_2_desp:
      "  Make sure your friend enters your unique reference ID i.e your registered mobile number when booking an appointment at www.Sell Any Wheel.com",

    Step_3_text: "Step 3: Earn Cash",
    Step_3_desp: "Earn Cash Rewards when your friend sells his car!",
  },

  myAppointment_page: {
    My_Appointment_heading: "My Appointment",
    My_Appointment_desp: "your interested cars detiles hare",
    Please_Enter_Your_Details: "Please Enter Your Details",
    Emil_placeholder: "example@gmail.com",
    post_code_placeholder: "enter your post code",
    Get_Appointments_button_text: "Get Appointments",

    // ===================myAppointment detail===========
  },

  myAppointment_Deatail_page: {
    // ===================myAppointment detail===========

    Treads_Images_text: " Treads Images",
    Exterior_Images_text: " Exterior Images",
    Interior_Images_text: " Interior Images",
    Wheels_Images_text: " Wheels Images",
    Damage_Images_text: " Damage Images",

    My_Appointment_heading: "My Appointment",
    Car_Steps_text: "Car Steps",
    Update_Car_Information: "Update Car Information",

    Address_text: "Address:",
    Time_Selected_text: "Time Selected:",
    Date_Selected_text: "Date Selected:",

    Car_Details_heading: "Car Details",
    Brand_text: "Brand:",
    Model_text: "Model:",
    Fuel_Type_text: "Fuel Type:",
    Year_text: " Year:",
    Previou_Owners_text: "Previous Owners:",
    Car_text: " Car",
    Service_History_text: "Service History",
    Mileage_text: "Mileage",
    Variant_text: "Variant",

    User_Information_text: " User Information",
    User_Name_text: "User Name:",
    Email_text: "Email:",
    Mobile_Number_text: "Mobile Number:",
    Postcode_text: "Postcode:",
    Tracking_Number_text: "Tracking Number",
    vehicle_text: "Vehicle Details",
  },

  contact_us: {
    contact_text: "CONTACT US",
    contactus_description: "How to get in touch with Sell Any Wheel",

    sell_image:
      "http://localhost:3000/static/media/mobile-svgrepo-com.d0ac8b19.svg",
    sell_text: "Sell your car",
    sell_description: "Get a price for your car from our advisor ?",
    sell_bottomtab: "12345678",

    questions_image:
      "http://localhost:3000/static/media/question-svgrepo-com.0c119e4a.svg",
    questions_text: "Questions",
    questions_description: "Find answers in our online support about car.",
    questions_bottomtab: "Go to FAQs",

    chat_iamge:
      "http://localhost:3000/static/media/chat-sms-svgrepo-com.bc60d15f.svg",
    chat_text: "Chat Online",
    chat_description: "If you Want to speak somebody, please text?",
    chat_bottomtab: "Let's Talk",

    sellanywheel_heading: "Sell Any Wheel",
    sellanywheel_description:
      "Whether you're an individual looking to sell your car, an organisation interested in talking about offloading a fleet of vehicles or would just like to find out more about what we do and how we could work together, get in touch using the details below",
    address_text: "Address",
    address_description: "Gulbeerg II. Lahore",
    phoneno_text: "Phone",
    phone_no: "123 456 789",

    contactus_form: "Contact Us",
    firstname_placeholder: "First Name",
    lastname_placeholder: "Last Name",
    email_placeholder: "Email",
    leave_placeholder: "Leave Comment",
    contactus_submit: "Contact Us",
  },

  privacy_plicy_page: {
    main_heading: "PRIVACY POLICY",
    html_code:
      '<div class="content"><ul ><li>Car Brand</li><li>Car Model</li><li>Car Trim</li><li>Car Year</li><li>Car Variant/Trim</li><li>Car mileage</li><li>License plate</li><li>Contact Number</li><li>Contact email</li></ul></div>',
  },

  FAQs: {
    frequent_text: "frequently asked questions",
    box1_desc: "How does Sell Any Wheel work?",
    box2_desc: "What do I need to do?",
    box2_dropdown_desc: "What do I need to bring along?",
    box3_text: "Evaluation",

    box3_dropdown_desc:
      "Can the actual value differ from the online valuation?",

    dropdown1_desc: "How does the free online evalution work?",
    dropdown1_p1:
      "On www.Sell Any Wheel.com, get an initial estimate for your car by following these steps: Enter the make,",
    dropdown1_p2:
      "On www.Sell Any Wheel.com, get an initial estimate for your car by following these steps: Enter the make,",

    dropdown2_p3:
      "Got your instant evaluation and interested in selling your car?",
    dropdown2_p4:
      "Book your appointment online or call us on on 0304 1111 CAR (227)",

    dropdown1_p5:
      "On www.Sell Any Wheel.com, get an initial estimate for your car by following these steps: Enter the make,",
    dropdown1_p6:
      "On www.Sell Any Wheel.com, get an initial estimate for your car by following these steps: Enter the make,",
    dropdown1_p7:
      "On www.Sell Any Wheel.com, get an initial estimate for your car by following these steps: Enter the make,",

    dropdown2_desc: "Free Inspection",
  },

  // =============reqird===

  requridocuments_page: {
    Bringing_Documents_heading: "  Bringing Documents for Inspection",

    I_own_my_vehicle_heading: "I own my vehicle",
    I_own_my_vehicle_doc_1: "CNIC",
    I_own_my_vehicle_doc_2: "Registration Book",
    I_own_my_vehicle_doc_3: "Complete File",
    I_own_my_vehicle_doc_4: "Transfer Letter",

    some_money_on_my_vehicle_heading: "I still owe some money on my vehicle",
    some_money_on_my_vehicle_doc_1: "CNIC",
    some_money_on_my_vehicle_doc_2: "Registration Book",
    some_money_on_my_vehicle_doc_3: "Complete File",
    some_money_on_my_vehicle_doc_4: "Transfer Letter",

    vehicle_belonging_to_company_heading:
      "I am selling a vehicle belonging to a company.",
    vehicle_belonging_to_company_doc_1: "CNIC",
    vehicle_belonging_to_company_doc_2: "Registration Book",
    vehicle_belonging_to_company_doc_3: "Complete File",
    vehicle_belonging_to_company_doc_4: "Transfer Letter",

    behalf_of_friend_heading:
      " I am selling a vehicle on behalf of a friend of relative.",
    behalf_of_friend_doc_1: "CNIC",
    behalf_of_friend_doc_2: "Registration Book",
    behalf_of_friend_doc_3: "Complete File",
    behalf_of_friend_doc_4: "Transfer Letter",
  },

  term_condition: {
    term_text: "terms of use",
    term_desc:
      "THESE TERMS OF USE WERE UPDATED ON 06-08-2020. PLEASE CAREFULLY READ THESE TERMS OF USE. BY USING THIS PLATFORM OR OUR SERVICES, YOU INDICATE YOUR UNDERSTANDING AND ACCEPTANCE OF THESE TERMS. IF YOU DO NOT AGREE TO THESE TERMS YOU MAY NOT USE THE PLATFORM.",
    acceptance_text: "1. Acceptance",
    acceptance_descp1:
      "1.1 Augmentech Business Solutions (Pvt) Ltd located at 28-A, Block K, 1st Floor, MM Tower, Gulberg, Lahore (“Company”, “we”, “us”, “our”) provides services which facilitate the users of the website at www.Sell Any Wheel.com and the mobile application (collectively to be referred as the “Platform”) to sell or buy Vehicles (“Vehicle”) in a convenient manner (collectively to be referred as the “Services”). Your use of the Platform and the Services are subject to these Terms of Use (these 'Terms'). For the purpose of the Terms and wherever the context so requires, the terms 'you' and 'your' shall mean any person who uses or accesses, whether through manual or automated means, the Platform or the Service in any manner whatsoever including persons browsing the Platform and its content, posting comments or any content or responding to any content on the Platform.",
    acceptance_descp2:
      "1.2 By proceeding to use the Platform, you warrant that you are eligible to contract under applicable laws and you are authorised under law to sell the Vehicle, the details of which you have provided on the Platform. If you are using or accessing the Platform as a representative of any person/entity, you acknowledge that you are legally authorised to represent such person/entity. You affirm that you are eligible and competent as per the applicable laws to enter into the terms, conditions, obligations, affirmations, representations, and warranties set forth in these Terms, and to abide by and comply with these Terms.",
    acceptance_descp3:
      "1.3 These Terms may be updated by us at any time at our sole discretion. You understand and agree that you are solely responsible for reviewing these Terms from time to time. Should you object to any term or condition of these Terms, any guideline, or any subsequent changes thereto or become unhappy with our Platform in any way, your only option is to immediately discontinue using the Platform and the Services.",
    desc_text: "2. Description of Platform and Services",
    descp_1:
      "1.1 Augmentech Business Solutions (Pvt) Ltd located at 28-A, Block K, 1st Floor, MM Tower, Gulberg, Lahore (“Company”, “we”, “us”, “our”) provides services which facilitate the users of the website at www.Sell Any Wheel.com and the mobile application (collectively to be referred as the “Platform”) to sell or buy Vehicles (“Vehicle”) in a convenient manner (collectively to be referred as the “Services”). Your use of the Platform and the Services are subject to these Terms of Use (these 'Terms'). For the purpose of the Terms and wherever the context so requires, the terms 'you' and 'your' shall mean any person who uses or accesses, whether through manual or automated means, the Platform or the Service in any manner whatsoever including persons browsing the Platform and its content, posting comments or any content or responding to any content on the Platform.",
    descp_2:
      "1.2 By proceeding to use the Platform, you warrant that you are eligible to contract under applicable laws and you are authorised under law to sell the Vehicle, the details of which you have provided on the Platform. If you are using or accessing the Platform as a representative of any person/entity, you acknowledge that you are legally authorised to represent such person/entity. You affirm that you are eligible and competent as per the applicable laws to enter into the terms, conditions, obligations, affirmations, representations, and warranties set forth in these Terms, and to abide by and comply with these Terms.",
    descp_3:
      "1.3 These Terms may be updated by us at any time at our sole discretion. You understand and agree that you are solely responsible for reviewing these Terms from time to time. Should you object to any term or condition of these Terms, any guideline, or any subsequent changes thereto or become unhappy with our Platform in any way, your only option is to immediately discontinue using the Platform and the Services.",
  },
  about_us: {
    who_text: "HOW WE ARE",
    who_desc: "We are transforming the way used cars are traded",
    our_text: "Our Services",
    our_desc:
      "Sell Any Wheel is transforming the way used-cars are traded in Pakistan, with a network of over 35+ purchase and sale centers in Lahore, Karachi, Islamabad, Faisalabad, Multan and Hyderabad. Car owners can now easily sell their cars through our secure, convenient, and hassle-free process. Sell Any Wheel also provides car dealers easy access to nationwide inventory through their online sales platform. Sell Any Wheel is a name and brand owned and operated wholly by Augmentech Business Solutions (Pvt) Ltd.",
    a_new_text: "A new standard in used cars sales",
    a_new_desc:
      "You can now get your car inspected and sold at a fair price through our safe and hassle free process. Our nationwide network of purchase centers use an internally developed algorithm, and a professionally trained team to conduct inspections.",
    pakistan_text: "Pakistan's first automated sales platform for car dealers",
    pakistan_desc:
      "You can now get your car inspected and sold at a fair price through our safe and hassle free process. Our nationwide network of purchase centers use an internally developed algorithm, and a professionally trained team to conduct inspections.",
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case `${SHIPING_COST}_REQUEST`:
      return { ...state, loading: true };
    case `${SHIPING_COST}_SUCCESS`:
      // console.log("SHIPING_COST ...........SHIPING_COST",action.payload)

      return { ...state, 
        data:action.payload['Estimated Price'],
        loading:false,
      };
    case `${SHIPING_COST}_FAIL`:
      return { ...state, loading: false };
    case `${SHIPING_COST}_ERROR`:
      return { ...state, loading: false };


    //================car winer list===============

















}
  return state;
};
