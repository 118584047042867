//
export const sellMyCarStepsName = [
  "steps",
  "Car Details",
  "Additional Car Details",
  "Personal Information",
  "Car Images",
  "Car Damage Images",
  "Car Video",
  "Car Sound",
  "Appointment",
  "Greating",
];
//
