import React, { useEffect, useRef } from "react";
// packegs
import { Col, Row } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import $ from "jquery";
import { useHistory, useLocation } from "react-router-dom";
// importing redux related things
import { connect } from "react-redux";
import Labels from "../../services/apiLabels";
import Footer from "../../components/Footer"
import { POST } from "../../constant/apiMethods";
import {
  LOGIN,
  TOASTER_SHOW,
  toaster_s,
  toaster_e,
  toaster_w,
} from "../../constant";
import { DEFAULT_ROUTE, USER } from "../../router";
// components
import "./loginstyle.css";
import carlogo from "../../assets/images/finallogo.png";
import OverLayLoader from "../../components/OverLayLoader";
// function
function Login(props) {
  const { login, auth, toasterShow } = props;
  let history = useHistory();
  let location = useLocation();
  
  
  
  
  const onLoginSuccessHandler = (response) => {
    history.replace(
      location.state ? location.state.from.pathname : DEFAULT_ROUTE
    );
  };
  const loginHandler = () => {
    let username = $("#email").val();
    let password = $("#password").val();
    const remember_me = $("#remember_me").is(":checked");
    if (username && password) {
      login(
        {
          username: $("#email").val(),
          password: $("#password").val(),
        },
        // onLoginSuccessHandler
        ()=>{}
      );
    } else {
      toasterShow({
        show: true,
        autoHide: false,
        type: toaster_w,
        description: `please enter ${!username ? "User Name" : ""} ${
          !password ? "Password" : ""
        }`,
      });
    }
  };
  //

  //
  // useEffect(() => {
  //   if (auth.token) onLoginSuccessHandler();
  //   return;
  // }, [auth]);
  // useEffect(() => {
  //   if (auth.isLogedin && USER) {
  //     let { from } = location.state || { from: { pathname: DEFAULT_ROUTE } };
  //     history.replace(from);
  //     
  //   }
  // }, [auth.isLogedin, USER]);
  if (auth.token) return <OverLayLoader alwaysShow={true} />;
  else
    return (
      <>
   
      <div>
        <div className="loginbody">
          {/* ==============shape 1 ================= */}
          <div className="shape"></div>
          {/* =================shape 2  ================ */}

          <div className="shape2"></div>
          <div className="loginMainview">
            {/* ==========rightside================== */}
            <div className="Leftside">
              <div className="loginfield">
                {/* <div className="mlogincardimage">
                <img src={carlogo} alt="BigCo Inc. logo" />
              </div> */}

                <div className="loginfieldwrapper">
                  <Col className=" mobileform">
                    <div className="formcontainer">
                      <h5 className="logintext">Login</h5>
                      <div className="form-group">
                        <label for="email">User Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter user name"
                          id="email"
                        />
                      </div>

                      <div className="form-group">
                        <label for="password">Password</label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Enter password"
                          id="password"
                        />
                      </div>

                      <div className="form-group">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="remember_me"
                            // name="remember_me"
                          />
                          <label
                            className="custom-control-label"
                            for="remember_me"
                          >
                            Remember me
                          </label>
                        </div>
                      </div>
                
                      <button
                        onClick={loginHandler}
                        // to="/auction"
                        // type="submit"
                        className="btn btn-primary btn-block"
                      >
                        Submit
                      </button>
                      <p className="forgot-password text-right">
                        <Link  to="/forgetpassword" >   Forgot password?</Link>
                     
                      </p>
                      <p className="forgot-password text-center">
                        Don't Have a Account <Link to="/signup">Sign-up?</Link>
                      </p>
                    </div>
                  </Col>
                  <Col className="mobileimagecontainer">
                    <div className="carimage">
                      <div className="logincardimage">
                        <img src={carlogo} alt="BigCo Inc. logo" />
                      </div>
                      <div className="imagetext">
                        <p>
                          Lorem ipsum is placeholder text commonly used in the.
                     
                        </p>
                      </div>
                    </div>
                  </Col>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="fffffffffffffffffff"  > */}
    
      <Footer/>
      {/* </div> */}
    
      </>
    );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch) => ({
  // login action
  login: (data, callBack) =>
    dispatch({
      type: LOGIN,
      payload: {
        type: LOGIN,
        method: POST,
        label: Labels.login,
        data,
        callBack,
      },
    }),
  //
  toasterShow: (data) =>
    dispatch({
      type: TOASTER_SHOW,
      payload: {
        ...data,
      },
    }),
  //
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);

// ================================================

// import React from "react";
// import "./style.css";
// //
// function Login(props) {
//   const { navbarDimensions } = props;
//   return (
//     <div
//       style={{
//         width: "100%",
//         height: window.innerHeight - navbarDimensions.height,
//         background: "yellow",
//       }}
//       className="position-relative"
//     >

//       Login
//     </div>
//   );
// }

// export default Login;
