import React, { Fragment } from "react";
import {
  ShimmerThumbnail,
  ShimmerButton,
  ShimmerTitle,
} from "react-shimmer-effects";
import "./Productview.css";

export default function ProductMainShimmer() {
  return (
    <Fragment>
      <div className="container-fluid  mt-5">
        <div className="row">
          {/* left column */}
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12  ">
            {/* ================ image slider ================== */}
            <div className="slider">
              <ShimmerThumbnail className="w-100" height={400} />
              <div className="  d-flex justify-content-start align-items-center ">
                {[...Array(3)].map((item, indnex) => {
                  return (
                    <div className="m-1">
                      <ShimmerButton size="md" />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {/* right column */}
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12  ">
            {/* =============car detail and bid ================= */}
            <div className="cardetailmain d-flex flex-column mt-5">
              {/* =============== car title and detail ================== */}
              <ShimmerTitle
                className="w-75"
                line={2}
                gap={10}
                variant="primary"
              />

              <div
                className={` d-flex  flex-column justify-content-center align-items-center`}
              >
                {/* counter container */}
                <div className="counter_main_container p-0 mt-1 mb-1">
                  <ShimmerThumbnail className="w-100" height={160} />
                </div>
                <br />
                <div className="w-100">
                  <ShimmerThumbnail height={160} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
