import React, { useState, useEffect } from "react";
import Slider from "react-animated-slider";
import { useLocation, useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import "./style.css";
import { ImCross } from "react-icons/im";
import Step8 from "../Evaluation/Step8";
import { FaEdit } from "react-icons/fa";
import { Button, Collapse } from "react-bootstrap";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { scrollTop } from "../../utils/window";

import { styled } from "@mui/material/styles";

import IconButton from "@mui/material/IconButton";
import { CAR_DATA_UPDATE_STEPS } from "../../constant";
import { useContext } from "react";
import FeedbackContext from "../../context/cmscontext";
import { Avatar } from '@mui/material/Avatar';
import { DiscFullOutlined } from "@mui/icons-material";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;

  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function Myappointmentdetail({
  mycardata,
  appointment_update,
  add_car_data,
  intiate_data,
}) {
  const { feedback, isLoading } = useContext(FeedbackContext);
  var myAppointment_Deatail_page = feedback.myAppointment_Deatail_page;

  const location = useLocation();
  let history = useHistory();
  const [index, setindex] = useState(location.state.index);

  const [userId, setuserId] = useState("");

  console.log("mycardata[index]========>>>>>", mycardata[index]);

  const app_update = (id) => {
    setuserId(id);
    setShowResults(true);
  };

  useEffect(() => {
    app_update_function();
  }, [appointment_update.appointment_create]);

  const imgArr = [
    "https://picsum.photos/200/310",
    "https://picsum.photos/200/320",
    "https://picsum.photos/200/340",
    "https://picsum.photos/200/350",
    "https://picsum.photos/200/360",
    "https://picsum.photos/200/340",
  ];

  // data={[
  //               { title: 1, id: 1 },
  //               { title: 2, id: 2 },
  //               { title: 3, id: 3 },
  //               { title: 4, id: 4 },
  //               { title: "5 or more", id: 5 },
  //               { title: "Don't know", id: 6 },
  //             ]}

  const data_josn = {
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5 or more",
    6: "Don't know",
  };

  const [expandeda, setExpandeda] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [expanded_a, setExpanded_a] = useState(false);
  const [expanded_b, setExpanded_b] = useState(false);
  const [expanded_c, setExpanded_c] = useState(false);

  const handleExpandClickA = () => {
    setExpandeda(!expandeda);
  };
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleExpandClick_a = () => {
    setExpanded_a(!expanded_a);
  };
  const handleExpandClick_b = () => {
    setExpanded_b(!expanded_b);
  };
  const handleExpandClick_c = () => {
    setExpanded_c(!expanded_c);
  };

  useEffect(() => {
    scrollTop();
  }, []);

  const [open, setOpen] = useState(false);

  const [showResults, setShowResults] = useState(false);

  const app_update_function = () => {
    if (Object.keys(appointment_update.appointment_create).length > 1) {
      appointment_update.step4 = {
        office: {
          Area: "",
          Postcode: "",
          address: "",
          id: "",
          timming: {},
        },
        time_selected: {
          id: 0,
          day_index: 0,
          slot: "",
          day: "",
          date: "",
          day_formated: "",
        },
      };

      mycardata[index].appointment = appointment_update.appointment_create;

      setShowResults(false);
      // setTimeout(() => {
      //  history.push("/myappointment")
      // }, 4000);
    }
  };

  const go_to_car_steps = () => {
    add_car_data(mycardata[index]);
    intiate_data(mycardata[index]);

    history.push("/carsteps");
  };

  if (showResults)
    return (
      <div className="Update_Sction mt-5">
        <div className="btn_wrap ">
          <button className="crosbtn " onClick={() => setShowResults(false)}>
            <ImCross />
          </button>
        </div>
        <div className="Udate_form mt-3">
          <Step8 name="update" appointmentId={userId} updateIndex={index} />
        </div>
      </div>
    );
  return (
    <>
      <div>
        <br></br>
        {mycardata.length > 0 ? (
          <div className=" w-100 span-1 my-3">
            <div className="container pt-3">
              <div className="d-flex justify-content-end ">
                <h2>{myAppointment_Deatail_page.Treads_Images_text}</h2>
                <ExpandMore
                  expand={expandeda}
                  onClick={handleExpandClickA}
                  aria-expanded={expandeda}
                  aria-label="show more"
                >
                  <ExpandMoreIcon fontSize="large" />
                </ExpandMore>
              </div>
              <hr></hr>

              <div className="Car_Parts1_pic my-3 ">
                <Collapse in={expandeda}>
                  <div>
                    {React.Children.toArray(
                      mycardata[index].treads_images_.map((item, i) => {
                        return (
                          <img className="car_part_pic " src={item.image} />
                        );
                      })
                    )}
                  </div>
                </Collapse>
              </div>

              <div className="d-flex justify-content-end">
                <h2>{myAppointment_Deatail_page.Exterior_Images_text}</h2>
                <ExpandMore
                  expand={expanded}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon fontSize="large" />
                </ExpandMore>
              </div>
              <hr></hr>

              <div className="Car_Parts1_pic my-3 ">
                <Collapse in={expanded}>
                  <div>
                    {React.Children.toArray(
                      mycardata[index].exterior_images_.map((item, i) => {
                        return (
                          <img className="car_part_pic " src={item.image} />
                        );
                      })
                    )}
                  </div>
                </Collapse>
              </div>

              <div className="d-flex justify-content-end">
                <h2>{myAppointment_Deatail_page.Interior_Images_text}</h2>
                <ExpandMore
                  expand={expanded_a}
                  onClick={handleExpandClick_a}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon fontSize="large" />
                </ExpandMore>
              </div>
              <hr></hr>
              <div className="Car_Parts1_pic my-3 ">
                <Collapse in={expanded_a}>
                  <div>
                    {React.Children.toArray(
                      mycardata[index].interior_images_.map((item, i) => {
                        return (
                          <img className="car_part_pic " src={item.image} />
                        );
                      })
                    )}
                  </div>
                </Collapse>
              </div>
              <div className="d-flex justify-content-end">
                <hr></hr>
                <h2>{myAppointment_Deatail_page.Wheels_Images_text}</h2>
                <ExpandMore
                  expand={expanded_b}
                  onClick={handleExpandClick_b}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon fontSize="large" />
                </ExpandMore>
              </div>
              <hr></hr>

              <div className="Car_Parts1_pic my-3 ">
                <Collapse in={expanded_b}>
                  <div>
                    {React.Children.toArray(
                      mycardata[index].wheels_images_.map((item, i) => {
                        return (
                          <img className="car_part_pic " src={item.image} />
                        );
                      })
                    )}
                  </div>
                </Collapse>
              </div>
              <div className="d-flex justify-content-end">
                <hr></hr>
                <h2>{myAppointment_Deatail_page.Damage_Images_text}</h2>
                <ExpandMore
                  expand={expanded_c}
                  onClick={handleExpandClick_c}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon fontSize="large" />
                </ExpandMore>
              </div>
              <hr></hr>

              <div className="Car_Parts1_pic my-3 ">
                <Collapse in={expanded_c}>
                  <div>
                    {React.Children.toArray(
                      mycardata[index].damage_images_.map((item, i) => {
                        return (
                          <img className="car_part_pic " src={item.image} />
                        );
                      })
                    )}
                  </div>
                </Collapse>
              </div>
            </div>

            <div className="text-center">
              <button
                className="btn12"
                name="CTAButton"
                onClick={go_to_car_steps}
              >
                {myAppointment_Deatail_page.Update_Car_Information}
              </button>
            </div>

            <div className="car_Data">
              {mycardata[index].appointment.length > 0 ? (
                <div className="mt-5 pt-5">
                  <div className=" mt-5">
                    <h1 className="heading1 ">
                      {myAppointment_Deatail_page.My_Appointment_heading}
                    </h1>
                    <div className="edit_btn_wrap d-flex">
                      <button
                        className="edit_button"
                        onClick={() =>
                          app_update(mycardata[index].appointment[0].id)
                        }
                      >
                        <FaEdit size={25} />
                      </button>
                    </div>

                    <hr />
                  </div>
                  <div className="m-2 ">
                    <div className="list_data_wrapper ">
                      <div className="table-responsive">
                        <table className="dptable table table-sm">
                          <thead>
                            <tr>
                              <th width="30%">
                                {
                                  myAppointment_Deatail_page.My_Appointment_heading
                                }
                              </th>
                              <th width="80%">
                                {mycardata[index].appointment[0].Address}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{myAppointment_Deatail_page.Address_text}</td>
                              <td>{mycardata[index].appointment[0].Area}</td>
                            </tr>
                            <tr>
                              <td>
                                {myAppointment_Deatail_page.Time_Selected_text}
                              </td>
                              <td>
                                {mycardata[index].appointment[0].time_selected}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                {myAppointment_Deatail_page.Date_Selected_text}
                              </td>
                              <td>
                                {mycardata[index].appointment[0].date_selected}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      {/* <div className="list_row">
                        <span
                          className="list_row_text"
                          style={{ fontWeight: "bold", flex: 1 }}
                        >
                         {myAppointment_page.My_Appointment_heading}
                        </span>
                        <span className="list_row_text list_text_secound" style={{ flex: 3 }}>
                          {mycardata[index].appointment[0].Address}
                        </span>
                      </div>

                      <div className="list_row">
                        <span
                          className="list_row_text"
                          style={{ fontWeight: "bold", flex: 1 }}
                        >
                          {" "}
                          {myAppointment_page.Address_text}
                        </span>

                        <span className="list_row_text list_text_secound" style={{ flex: 3 }}>
                          {mycardata[index].appointment[0].Area}
                        </span>
                      </div>
                      <div className="list_row">
                        <span
                          className="list_row_text"
                          style={{ fontWeight: "bold", flex: 1 }}
                        >
                          {" "}
                          {myAppointment_page.Time_Selected_text}
                        </span>

                        <span className="list_row_text list_text_secound" style={{ flex: 3 }}>
                          {mycardata[index].appointment[0].time_selected}
                        </span>
                      </div>
                      <div className="list_row">
                        <span
                          className="list_row_text"
                          style={{ fontWeight: "bold", flex: 1 }}
                        >
                          {" "}
                          {myAppointment_page.Date_Selected_text}
                        </span>

                        <span className="list_row_text list_text_secound" style={{ flex: 3 }}>
                          {" "}
                          {mycardata[index].appointment[0].date_selected}
                        </span>
                      </div> */}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="mt-5 pt-5">
                  <div className=" mt-5">
                    <h1 className="heading1 ">
                      {myAppointment_Deatail_page.My_Appointment_heading}
                    </h1>

                    <hr />
                  </div>
                  <span>
                    {myAppointment_Deatail_page.didntBookAppointment}
                    <strong>
                      {myAppointment_Deatail_page.didntProvideInfoStrong}
                    </strong>{" "}
                    {myAppointment_Deatail_page.didntProvideInfoButton}
                  </span>
                </div>
              )}

              <div className="mt-2 pt-3">
                <div className=" mt-">
                  <h1 className="heading1 ">
                    {myAppointment_Deatail_page.Car_Details_heading}
                  </h1>
                  <hr />
                </div>
                <div className="m-2 ">
                  <div className="list_data_wrapper ">
                    <div className="table-responsive">
                      <table className="dptable table table-sm">
                        <thead>
                          <tr>
                            <th width="30%">
                              {myAppointment_Deatail_page.Brand_text}
                            </th>
                            <th width="80%">{mycardata[index].brand_name}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{myAppointment_Deatail_page.Model_text}</td>
                            <td>{mycardata[index].model_name}</td>
                          </tr>
                          <tr>
                            <td>{myAppointment_Deatail_page.Fuel_Type_text}</td>
                            <td>{mycardata[index].fuel_type}</td>
                          </tr>
                          <tr>
                            <td>{myAppointment_Deatail_page.Year_text}</td>
                            <td>{mycardata[index].year_name}</td>
                          </tr>
                          <tr>
                            <td>
                              {myAppointment_Deatail_page.Previou_Owners_text}
                            </td>
                            <td>
                              {data_josn[mycardata[index].Previous_owners]}
                            </td>
                          </tr>

                          <tr>
                            <td>{myAppointment_Deatail_page.Car_text}</td>
                            <td>{mycardata[index].car}</td>
                          </tr>
                          <tr>
                            <td>
                              {myAppointment_Deatail_page.Service_History_text}
                            </td>
                            <td>{mycardata[index].Service_history}</td>
                          </tr>
                          <tr>
                            <td>{myAppointment_Deatail_page.Mileage_text}</td>
                            <td>{mycardata[index].Mileage}</td>
                          </tr>
                          <tr>
                            <td>{myAppointment_Deatail_page.Variant_text}</td>
                            <td>{mycardata[index].variant_name}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {/* <div className="list_row">
                      <span
                        className="list_row_text"
                        style={{ fontWeight: "bold", flex: 1 }}
                      >
                      {myAppointment_page.Brand_text}
                      </span>
                      <span className="list_row_text list_text_secound" style={{ flex: 3 }}>
                        {mycardata[index].brand_name}
                      </span>
                    </div>

                    <div className="list_row">
                      <span
                        className="list_row_text"
                        style={{ fontWeight: "bold", flex: 1 }}
                      >
                        {" "}
                        {myAppointment_page.Model_text}
                      </span>

                      <span className="list_row_text list_text_secound" style={{ flex: 3 }}>
                        {mycardata[index].model_name}
                      </span>
                    </div>
                    <div className="list_row">
                      <span
                        className="list_row_text"
                        style={{ fontWeight: "bold", flex: 1 }}
                      >
                        {" "}
                        {myAppointment_page.Fuel_Type_text}
                      </span>

                      <span className="list_row_text list_text_secound" style={{ flex: 3 }}>
                        {mycardata[index].fuel_type}
                      </span>
                    </div>
                    <div className="list_row">
                      <span
                        className="list_row_text"
                        style={{ fontWeight: "bold", flex: 1 }}
                      >
                        {" "}
                        {myAppointment_page.Year_text}
                      </span>

                      <span className="list_row_text list_text_secound" style={{ flex: 3 }}>
                        {" "}
                        {mycardata[index].year_name}
                      </span>
                    </div>

                    <div className="list_row">
                      <span
                        className="list_row_text"
                        style={{ fontWeight: "bold", flex: 1 }}
                      >
                        {" "}
                      {myAppointment_page.Previou_Owners_text}
                      </span>

                      <span className="list_row_text list_text_secound" style={{ flex: 3 }}>
                        {mycardata[index].Previous_owners}
                      </span>
                    </div> */}
                    {/* <div className="list_row">
                    <span className="list_row_text" style={{ fontWeight: "bold" , flex: 1 }}>
                      {" "}
                      Fuel Type:
                    </span>

                    <span className="list_row_text" style={{ flex: 3 }}> {mycardata[index].fuel_type}</span>
                  </div> */}

                    {/* <div className="list_row">
                      <span
                        className="list_row_text"
                        style={{ fontWeight: "bold", flex: 1 }}
                      >
                        {" "}
                        {myAppointment_page.Car_text}
                      </span>

                      <span className="list_row_text list_text_secound" style={{ flex: 3 }}>
                        {mycardata[index].car}
                      </span>
                    </div>

                    <div className="list_row">
                      <span
                        className="list_row_text"
                        style={{ fontWeight: "bold", flex: 1 }}
                      >
                        {" "}
                        {myAppointment_page.Service_History_text}
                      </span>

                      <span className="list_row_text list_text_secound" style={{ flex: 3 }}>
                        {mycardata[index].Service_history}
                      </span>
                    </div>

                    <div className="list_row">
                      <span
                        className="list_row_text"
                        style={{ fontWeight: "bold", flex: 1 }}
                      >
                        {" "}
                        {myAppointment_page.Mileage_text}
                      </span>

                      <span className="list_row_text list_text_secound" style={{ flex: 3 }}>
                        {mycardata[index].Mileage}
                      </span>
                    </div>
                    <div className="list_row">
                      <span
                        className="list_row_text"
                        style={{ fontWeight: "bold", flex: 1 }}
                      >
                        {" "}
                        {myAppointment_page.Variant_text}
                      </span>

                      <span className="list_row_text list_text_secound" style={{ flex: 3 }}>
                        {mycardata[index].variant_name}
                      </span>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="mt-5 pt-2">
                <div className=" ">
                  <h1 className="heading1 ">
                    {myAppointment_Deatail_page.User_Information_text}
                  </h1>
                  <hr />
                </div>
                <div className="m-2 ">
                  <div className="list_data_wrapper ">
                    <div className="table-responsive">
                      <table className="dptable table table-sm">
                        <thead>
                          <tr>
                            <th width="30%">
                              {myAppointment_Deatail_page.User_Name_text}
                            </th>
                            <th width="80%">
                              {mycardata[index].Website_user.toUpperCase()}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{myAppointment_Deatail_page.Email_text}</td>
                            <td style={{ textTransform: "lowercase" }}>
                              {mycardata[index].Email.toLowerCase()}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {myAppointment_Deatail_page.Mobile_Number_text}
                            </td>
                            <td>{mycardata[index].Mobile_number}</td>
                          </tr>
                          <tr>
                            <td>{myAppointment_Deatail_page.Postcode_text}</td>
                            <td>{mycardata[index].Postcode}</td>
                          </tr>
                          <tr>
                            <td>
                              {myAppointment_Deatail_page.Tracking_Number_text}
                            </td>
                            <td>{mycardata[index].tracking_number}</td>
                          </tr>
                          <tr>
                            <td>{myAppointment_Deatail_page.car_notes}</td>
                            <td>{mycardata[index].car_notes}</td>
                          </tr>
                          <tr>
                            <td>
                              {myAppointment_Deatail_page.car_description}
                            </td>
                            <td>{mycardata[index].car_description}</td>
                          </tr>
                          <tr>
                            <td>{myAppointment_Deatail_page.refel_code}</td>
                            <td>{mycardata[index].refel_code}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {/* <div className="list_row">
                      <span
                        className="list_row_text"
                        style={{ fontWeight: "bold", flex: 1 }}
                      >
                        {" "}
                        {myAppointment_page.User_Name_text}
                      </span>
                      <span className="list_row_text list_text_secound" style={{ flex: 3 }}>
                        {" "}
                        {mycardata[index].Website_user.toUpperCase()}
                      </span>
                    </div>

                    <div className="list_row">
                      <span
                        className="list_row_text"
                        style={{ fontWeight: "bold", flex: 1 }}
                      >
                        {" "}
                        {myAppointment_page.Email_text}
                      </span>

                      <span className="list_row_text list_text_secound" style={{ flex: 3 }}>
                        {mycardata[index].Email}
                      </span>
                    </div>
                    <div className="list_row">
                      <span
                        className="list_row_text "
                        style={{ fontWeight: "bold", flex: 1 }}
                      >
                        {" "}
                        {myAppointment_page.Mobile_Number_text}
                      </span>

                      <span className="list_row_text list_text_secound" style={{ flex: 3 }}>
                        {" "}
                        {mycardata[index].Mobile_number}
                      </span>
                    </div>
                    <div className="list_row">
                      <span
                        className="list_row_text"
                        style={{ fontWeight: "bold", flex: 1 }}
                      >
                        {" "}
                        {myAppointment_page.Postcode_text}
                      </span>

                      <span className="list_row_text list_text_secound" style={{ flex: 3 }}>
                        {" "}
                        {mycardata[index].Postcode}
                      </span>
                    </div>
                    <div className="list_row">
                      <span
                        className="list_row_text"
                        style={{ fontWeight: "bold", flex: 1 }}
                      >
                        {" "}
                        {myAppointment_page.Tracking_Number_text}
                      </span>

                      <span className="list_row_text list_text_secound" style={{ flex: 3 }}>
                        {" "}
                        {mycardata[index].tracking_number}
                      </span>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="mt-5 pt-2">
                <div className=" ">
                  <h1 className="heading1 ">
                    {myAppointment_Deatail_page.vehicle_text}
                  </h1>
                  <hr />
                </div>
                <div className="m-2 ">
                  <div className="list_data_wrapper ">
                    {mycardata[index].vehcile_details.length == 0 ? (
                      <span>
                        You didn't have provided the vehicle details yet. Please
                        provide the vehicle detail by using{" "}
                        <strong>Update Car information</strong> button
                      </span>
                    ) : (
                      <div className="table-responsive">
                        <table className="dptable table table-sm">
                          <thead>
                            <tr>
                              <th width="30%">
                                {myAppointment_Deatail_page.questions}
                              </th>
                              <th width="80%">
                                {myAppointment_Deatail_page.answer.toUpperCase()}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {React.Children.toArray(
                              mycardata[index].vehcile_details.map(
                                (item, index) => (
                                  <tr>
                                    <td>{item.question_}</td>
                                    <td>
                                      {React.Children.toArray(
                                        item.feature.map((item_, index) =>
                                          index == item.feature.length - 1
                                            ? item_.choice
                                            : item_.choice + ", "
                                        )
                                      )}
                                    </td>
                                  </tr>
                                )
                              )
                            )}
                            {mycardata[index].vehicle_address.length > 0 ? (
                              <>
                                <tr>
                                  <td>
                                    {myAppointment_Deatail_page.Vehicle_Address}
                                  </td>
                                  <td>
                                    {
                                      mycardata[index].vehicle_address[0]
                                        .address
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    {
                                      myAppointment_Deatail_page.vechiclePostCodeLabel
                                    }
                                  </td>
                                  <td>
                                    {
                                      mycardata[index].vehicle_address[0]
                                        .post_code
                                    }
                                  </td>
                                </tr>
                              </>
                            ) : null}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          history.push("/myappointment")
          // <div>aaaaaaaaaaaa</div>
        )}
      </div>
      {/*  */}
    </>
  );
}

const mapStateToProps = (state) => ({
  myAppointment_page: state.datafile.myAppointment_Deatail_page,
  mycardata: state.myAppointment.data,
  appointment_update: state.sellMyCar,
});
const mapDispatchToProps = (dispatch) => ({
  add_car_data: (data) =>
    dispatch({
      type: CAR_DATA_UPDATE_STEPS,
      payload: {
        data: data,
        update: true,
      },
    }),
  intiate_data: (data) =>
    dispatch({
      type: 'CAR_DETAIL_SETUP',
      payload: {
        data: data,
        update: true,
      },
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Myappointmentdetail);
