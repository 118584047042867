import React from "react";
import "./Won.css";
import Dropdown from "../Dropdown/index";
import Watch from "./Watchlist";
function AuctionWon() {
  return (
    <div className="wonbody">
      <div className="wonAuctionbody">
        <div className="">
          <label className="wonheading">Acution Won</label>
        </div>
        <span className="wonheading2">View Sheet</span>
      </div>
      <div className="wondetail"> 

{/* -------------------first------------------ */}
      <div className="col-sm-4.col-md-4.col-lg-4.col-xl-4 wfirst">
        {/* ---------data-------- */}
        <div >
        <div className="wontitle">65</div>
        <div className="wontitle">Today</div>
        </div>
        </div>

        {/* --------------second div-------------------*/}
        <div className="col-sm-4.col-md-4.col-lg-4.col-xl-4 wsecond">
        <div >
        <div className="wontitle">115</div>
        <div className="wontitle">Week</div>
        </div>
        </div>

        {/* -----------------third------------------ */}
        <div className="col-sm-4.col-md-4.col-lg-4.col-xl-4 wthird">
        <div >
        <div className="wontitle">153</div>
        <div className="wontitle">Month</div>
        </div>
        </div>
      </div>

      <Dropdown placeholder="In Negotiation">
        <a className="dropdown-item" href="#">
          Action
        </a>
      </Dropdown>
      <Dropdown placeholder="Active Buying">
        <a className="dropdown-item" href="#">
          Action
        </a>
      </Dropdown>
      <Dropdown placeholder="Watchlist">
      <Watch/>
      </Dropdown>
    
   
    </div>
  );
}

export default AuctionWon;
