import {React,useState} from "react";
import { connect } from "react-redux";
import { MdCall, MdTextsms, MdAccessTime } from "react-icons/md";
import { Link } from "react-router-dom";
import Labels from "../../services/apiLabels";
import { POST, GET } from "../../constant/apiMethods";
import { SHOW_INTEREST, CREATE_SUBSCRIPTION } from "../../constant";
import "./style.css";
import { useContext } from "react";
import FeedbackContext from "../../context/cmscontext";
export const Footer = ({create_subscription}) => {

   const { feedback, isLoading } = useContext(FeedbackContext);
   var home_page = feedback.home_page;
const [email, setemail] = useState("")

// console.log("email inp",email)
const subscribe_me=()=>{
    create_subscription({
        email:email
    })
}


  return (
    <>
      <div className="container p-5">
        <h2>{home_page.any_question}</h2>
        <p>{home_page.any_question_desp}</p>
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-12 ">
            <MdCall size={22} /> &nbsp; &nbsp;{home_page.call_number}
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12 my-2"></div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <MdAccessTime size={22} />
            &nbsp;&nbsp; {home_page.call_time}
          </div>
        </div>

        <hr></hr>

        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 list_wrap">
            <ul className="foter_list">
              <Link to="/contactus" className="link">
                <li>{home_page.contact_us} </li>
              </Link>

              <Link to="/privacypolicy" className="link">
                <li>{home_page.Privacy_Policy}</li>
              </Link>

              <Link to="/faqs" className="link">
                <li>{home_page.FAQs} </li>
              </Link>

              <Link to="/requridocuments" className="link">
                <li>{home_page.Required_documents} </li>
              </Link>

              <Link to="/terms" className="link">
                <li>{home_page.Terms_conditions} </li>
              </Link>

              <Link to="/aboutus" className="link">
                <li>{home_page.About_us}</li>
              </Link>
            </ul>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            {/* <h3>{home_page.what_do_you_want}</h3>
            <p>{home_page.range_of_services}</p>

            <div className="row bton_wrap">
              <div className="col-4 buy_">
                <a
                  href="https://dealers.sellanywheel.com/buynow"
                  target="_blank"
                  className="link"
                >
                  <button name="CTAButton" className="button_heder">
                    {home_page.Buy_Now_button_text}
                  </button>
                </a>
              </div>
              <div className="col-4 buy_ ">
                <a
                  href="https://dealers.sellanywheel.com/auction"
                  target="_blank"
                  className="link"
                >
                  <button name="CTAButton" className="button_heder">
                    {home_page.Auction_button_text}
                  </button>
                </a>
              </div>
              <div className="col-4 buy_">
                <a
                  href="https://dealers.sellanywheel.com/showInterest"
                  target="_blank"
                  className="link"
                >
                  <button name="CTAButton" className="button_heder">
                    {home_page.Interest_button_text}
                  </button>
                </a>
              </div>
            </div> */}

            <div className="row p-4 buy_">
              <h3> {home_page.Subscribe_text}</h3>
              <p> {home_page.Subscribe_desp}</p>
              <div className="col-8 buy_l">
                <div className="inp_wrap ">
                  <input
                    onChange={(e) => {
                      setemail(e.target.value);
                    }}
                    className="inp_contact"
                    placeholder={home_page.Subscribe_placeholder}
                  ></input>
                </div>
              </div>
              <div className="col-4">
                <button
                  name="CTAButton"
                  className="button_heder"
                  onClick={subscribe_me}
                >
                  {home_page.Subscribe_button_text}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    create_subscription: (data) =>
    dispatch({
      type: CREATE_SUBSCRIPTION,
      payload: {
        type: CREATE_SUBSCRIPTION,

        label: Labels.createSubscription,
        method: POST,
        data,
        hideOverlayLoader: true,
      },
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
