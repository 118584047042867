import React from "react";
import ImageViewer from "react-simple-image-viewer";
import { useState, useCallback } from "react";
import {
  MdOutlineModeEditOutline,
  MdOutlineDirectionsCar,
} from "react-icons/md";
import "./style.css";
import DeskTopView from "../../components/Desktopview";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
function CarDamage() {
  const [sreen, setsreen] = useState(
    window.orientation == 90 ? "landscap" : "portrait"
  );

  function doOnOrientationChange() {
    
    switch (window.orientation) {
      // case -90:
      case 90:
        setsreen("landscap");

        
        break;
      default:
        setsreen("portrait");
        

        break;
    }
  }

  window.addEventListener("orientationchange", doOnOrientationChange);

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const images = ["http://placeimg.com/1200/800/nature"];

  const [imge, setImge] = useState("http://placeimg.com/1200/800/nature");

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
    // setImge("http://placeimg.com/1200/900/nature")
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  // ==============add car damge pic ============
  const carDamage = () => {};

  return (
    <>
      <BrowserView>
        <DeskTopView />
      </BrowserView>
      <MobileView>
        {sreen == "landscap" ? (
          isViewerOpen ? (
            <>
              <div>
                <button className="retake_btn">take</button>
                <ImageViewer
                  src={images}
                  currentIndex={currentImage}
                  onClose={closeImageViewer}
                  backgroundStyle={{
                    height: "500px",
                    marginTop: "100px",
                    // backgroundColor: "rgba(0,0,0,0.9)"
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <div className="container mt-5">
                <h2 className="text-center"> Review your photos</h2>
                <div className="Dmage_wrap">
                  <h3>Exterior</h3>
                  <div className="Dmage_inside">
                    <div className="p-2 ">
                      <div className="imge_wrap">
                        {images.map((src, index) => (
                          <div>
                            <span
                              className="edit_icon"
                              onClick={() => openImageViewer(index)}
                            >
                              <MdOutlineModeEditOutline />
                            </span>
                            <img
                              src={imge}
                              onClick={() => openImageViewer(index)}
                              className="damage_img"
                              key={index}
                              //   style={{ margin: "2px" }}
                              alt=""
                            />
                          </div>
                        ))}

                        {/* <img className="damage_img" src="https://picsum.photos/200/300" /> */}
                      </div>

                      <p>Front driver Corner </p>
                    </div>
                    <div className="p-2 ">
                      <div className="imge_wrap">
                        {images.map((src, index) => (
                          <div>
                            <span
                              className="edit_icon"
                              onClick={() => openImageViewer(index)}
                            >
                              <MdOutlineModeEditOutline />
                            </span>
                            <img
                              src={images}
                              onClick={() => openImageViewer(index)}
                              className="damage_img"
                              key={index}
                              //   style={{ margin: "2px" }}
                              alt=""
                            />
                          </div>
                        ))}

                        {/* {isViewerOpen && (
                  <div>
                    <button className="retake_btn"
                    >take</button>
                    <ImageViewer
                      src={images}
                      currentIndex={currentImage}
                      onClose={closeImageViewer}
                      backgroundStyle={{
                        height: "500px",
                        marginTop: "100px",
                        // backgroundColor: "rgba(0,0,0,0.9)"
                      }}
                    />
                  </div>
                )} */}

                        {/* <img className="damage_img" src="https://picsum.photos/200/300" /> */}
                      </div>

                      <p>Front driver Corner </p>
                    </div>
                    <div className="p-2 ">
                      <div className="imge_wrap">
                        {images.map((src, index) => (
                          <div>
                            <span
                              className="edit_icon"
                              onClick={() => openImageViewer(index)}
                            >
                              <MdOutlineModeEditOutline />
                            </span>
                            <img
                              src={images}
                              onClick={() => openImageViewer(index)}
                              className="damage_img"
                              key={index}
                              //   style={{ margin: "2px" }}
                              alt=""
                            />
                          </div>
                        ))}

                        {/* {isViewerOpen && (
                  <div>
                    <button className="retake_btn"
                    >take</button>
                    <ImageViewer
                      src={images}
                      currentIndex={currentImage}
                      onClose={closeImageViewer}
                      backgroundStyle={{
                        height: "500px",
                        marginTop: "100px",
                        // backgroundColor: "rgba(0,0,0,0.9)"
                      }}
                    />
                  </div>
                )} */}

                        {/* <img className="damage_img" src="https://picsum.photos/200/300" /> */}
                      </div>

                      <p>Front driver Corner </p>
                    </div>
                    <div className="p-2">
                      <div className=" damage_img_a">
                        <div className="no_pic_div p-5" onClick={carDamage}>
                          <span>
                            <MdOutlineDirectionsCar size={40} />
                          </span>

                          <span className="mt-2 add_text">+ Add Photo</span>
                        </div>
                      </div>

                      <p>Front driver Corner </p>
                    </div>
                  </div>
                </div>
                <div className="Dmage_wrap">
                  <h3>Exterior</h3>
                  <div className="Dmage_inside">
                    <div className="p-2 ">
                      <div className="imge_wrap">
                        {images.map((src, index) => (
                          <div>
                            <span
                              className="edit_icon"
                              onClick={() => openImageViewer(index)}
                            >
                              <MdOutlineModeEditOutline />
                            </span>
                            <img
                              src={imge}
                              onClick={() => openImageViewer(index)}
                              className="damage_img"
                              key={index}
                              //   style={{ margin: "2px" }}
                              alt=""
                            />
                          </div>
                        ))}

                        {/* {isViewerOpen && (
                  <div>
                    <button className="retake_btn"
                    >take</button>
                    <ImageViewer
                      src={images}
                      currentIndex={currentImage}
                      onClose={closeImageViewer}
                      backgroundStyle={{
                        height: "500px",
                        marginTop: "100px",
                        // backgroundColor: "rgba(0,0,0,0.9)"
                      }}
                    />
                  </div>
                )} */}

                        {/* <img className="damage_img" src="https://picsum.photos/200/300" /> */}
                      </div>

                      <p>Front driver Corner </p>
                    </div>
                    <div className="p-2 ">
                      <div className="imge_wrap">
                        {images.map((src, index) => (
                          <div>
                            <span
                              className="edit_icon"
                              onClick={() => openImageViewer(index)}
                            >
                              <MdOutlineModeEditOutline />
                            </span>
                            <img
                              src={images}
                              onClick={() => openImageViewer(index)}
                              className="damage_img"
                              key={index}
                              //   style={{ margin: "2px" }}
                              alt=""
                            />
                          </div>
                        ))}

                        {/* {isViewerOpen && (
                  <div>
                    <button className="retake_btn"
                    >take</button>
                    <ImageViewer
                      src={images}
                      currentIndex={currentImage}
                      onClose={closeImageViewer}
                      backgroundStyle={{
                        height: "500px",
                        marginTop: "100px",
                        // backgroundColor: "rgba(0,0,0,0.9)"
                      }}
                    />
                  </div>
                )} */}

                        {/* <img className="damage_img" src="https://picsum.photos/200/300" /> */}
                      </div>

                      <p>Front driver Corner </p>
                    </div>
                    <div className="p-2 ">
                      <div className="imge_wrap">
                        {images.map((src, index) => (
                          <div>
                            <span
                              className="edit_icon"
                              onClick={() => openImageViewer(index)}
                            >
                              <MdOutlineModeEditOutline />
                            </span>
                            <img
                              src={images}
                              onClick={() => openImageViewer(index)}
                              className="damage_img"
                              key={index}
                              //   style={{ margin: "2px" }}
                              alt=""
                            />
                          </div>
                        ))}

                        {/* {isViewerOpen && (
                  <div>
                    <button className="retake_btn"
                    >take</button>
                    <ImageViewer
                      src={images}
                      currentIndex={currentImage}
                      onClose={closeImageViewer}
                      backgroundStyle={{
                        height: "500px",
                        marginTop: "100px",
                        // backgroundColor: "rgba(0,0,0,0.9)"
                      }}
                    />
                  </div>
                )} */}

                        {/* <img className="damage_img" src="https://picsum.photos/200/300" /> */}
                      </div>

                      <p>Front driver Corner </p>
                    </div>
                    <div className="p-2">
                      <div className=" damage_img_a">
                        <div className="no_pic_div p-5" onClick={carDamage}>
                          <span>
                            <MdOutlineDirectionsCar size={40} />
                          </span>

                          <span className="mt-2 add_text">+ Add Photo</span>
                        </div>
                      </div>

                      <p>Front driver Corner </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
        ) : (
          <div className="Lotie_Main_Wrap">
            <div className="lotie_wrap">
              <lottie-player
                src="https://assets10.lottiefiles.com/private_files/lf30_uqnbeq1u.json"
                background="#48BA7E"
                speed="1"
                style={{ height: "92vh" }}
                loop
                autoplay
              ></lottie-player>
            </div>
          </div>
        )}
      </MobileView>
    </>
  );
}

export default CarDamage;
