/* eslint-disable no-unused-vars */
/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect, Fragment } from "react";
import { AiTwotoneEye, AiOutlineClockCircle } from "react-icons/ai";
import { BsDot } from "react-icons/bs";
import { GoLocation } from "react-icons/go";
import { Card } from "react-bootstrap";
import "./produ.css";
import "./ListCard.css";
import ProductMain from "../Product View/ProductMain";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
} from "react-router-dom";
import WishListButton from "./WishListButton";

export default (props) => {
  const { Products, index } = props;
  // const [timer, setTimer] = useState("");
  const [timer_, setTimer_] = useState([
    { id: "days", value: "00" },
    { id: "hours", value: "00" },
    { id: "minutes", value: "00" },
    { id: "seconds", value: "00" },
  ]);
  const [bidingExpired, setBidingExpired] = useState(true);

  // ----------------------- timer start

  // Set the date we're counting down to
  let countDownDate = new Date(Products.end_at).getTime();
  const timeFormatter = (e) => {
    return e < 10 ? `0${e}` : `${e}`;
  };
  useEffect(() => {
    const interval = setInterval(function () {
      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      // setTimer(
      //   `${
      //     formateTimerInt(days) +
      //     " d : " +
      //     formateTimerInt(hours) +
      //     " h : " +
      //     formateTimerInt(minutes) +
      //     " m : " +
      //     formateTimerInt(seconds) +
      //     " s"
      //   }`
      // );
      setTimer_([
        { id: "days", value: timeFormatter(days) },
        { id: "hours", value: timeFormatter(hours) },
        { id: "minutes", value: timeFormatter(minutes) },
        { id: "seconds", value: timeFormatter(seconds) },
      ]);
      setBidingExpired(false);
      if (distance < 0) {
        clearInterval(interval);
        // setTimer("");
        setBidingExpired(true);
        setTimer_([
          { id: "days", value: "00" },
          { id: "hours", value: "00" },
          { id: "minutes", value: "00" },
          { id: "seconds", value: "00" },
        ]);
        // document.getElementById(productListCardId).innerHTML = "EXPIRED";
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countDownDate]);
  // timer end-----------------------
  // 
  return (
    <div
      className="position-relative  container-fluid product_list_card_main_cont_div"
      style={{ overflow: "hidden" }}
    >
      <WishListButton />
      <Link
        excat
        to={{
          pathname: "/product",
          state: { product_code: Products.id },
        }}
        style={{ color: "inherit", textDecoration: "inherit" }}
        className="container-fluid product_list_card_main_cont imageHover "
      >
        {/* <button className="counter_buy_now_cont  m-1">
        <span className="counter_buy_now_text">{timer ? timer : "SOLD"}</span>
      </button> */}
        <div className="col-5 p-0">
          <div className="product_list_card_image_wrapper">
            <img
              className="product_list_card_image"
              src={Products.main_image}
              alt="banner"
            />
          </div>
        </div>
        <div className="col-7 align-self-start pt-2 product_list_card_col_2 ">
          {/*  */}
          <span className="product_list_card_title">
            {Products.product_name}
          </span>
          <div className="mt-2">
            {Products.product_feature
              ? Products.product_feature.slice(0, 5).map((item, index) => (
                  <span className="product_list_card_features_text">
                    {item.name}: {item.value}
                  </span>
                ))
              : null}
          </div>
          {!bidingExpired ? (
            <Fragment>
              <span className="product_list_card_timer_title">
                Bidding Expires in:
              </span>

              <div className="d-flex justify-content-start align-items-center">
                {timer_.map((item, index) => {
                  return (
                    <Fragment>
                      <span className="  p-1 d-flex flex-column justify-content-center">
                        <div className="list_card_timer_time">{item.value}</div>
                        <span className="list_card_timer_label">{item.id}</span>
                      </span>
                      <span
                        className="counter_text pt-1 align-self-start "
                        style={{ fontSize: "0.8rem" }}
                      >
                        {index === timer_.length - 1 ? null : ":"}
                      </span>
                    </Fragment>
                  );
                })}
              </div>
            </Fragment>
          ) : (
            <span
              className="product_list_card_title"
              style={{ fontSize: "0.9rem" }}
            >
              Sold
            </span>
          )}
        </div>
      </Link>
    </div>
  );
};
