import React from "react";
import { AiTwotoneEye,AiOutlineClockCircle } from "react-icons/ai";
import { BsDot } from "react-icons/bs";
import { GoLocation} from "react-icons/go";
import { Card } from "react-bootstrap";
import './watch.css';
function Watch() {
  const Data = [
    {
      imgsrc:
        "https://th.bing.com/th/id/OIP.6T0X8JDGKvfl9BThAeXeaAHaE6?pid=ImgDet&w=2048&h=1358&rs=1",
      title: "Bugatti 2021",
      detail:
        "Bugatti has added the Pur Sport model to the Chiron lineup for 2021.",
      door: "4dr",
      engine: "1300",
      bid: " Current Bid",
      bidprice: "$ 17000",
      time: "5:46",
      km: "36000",
      wheel: "AWD",
      start:'$ 430'
    },

    {
      title: "Rolls-Royce Phantom",
      imgsrc:
        "https://cdn.bmwblog.com/wp-content/uploads/2020/09/2021-rolls-royce-ghost-30.jpg",
      detail:
        "Rolls-Royce has always excelled at producing luxury vehicles and one of those fancy set of wheels is the Rolls-Royce Phantom.",
        door: "4dr",
        engine: "1300",
        bid: " Current Bid",
        bidprice: "$ 17000",
        time: "5:46",
        km: "36000",
        wheel: "AWD",
        start:'$ 430'
    },
    {
      imgsrc:
        "https://cdn.bmwblog.com/wp-content/uploads/2020/09/2021-rolls-royce-ghost-30.jpg",
      title: " Hummer 2021 Hybrid",
      detail: "Rolls-Royce has always excelled at producing luxury vehicles",
      door: "4dr",
      engine: "1300",
      bid: " Current Bid",
      bidprice: "$ 17000",
      time: "5:46",
      km: "36000",
      wheel: "AWD",
      start:'$ 430'
    },
    {
      imgsrc:
        "https://th.bing.com/th/id/OIP.6T0X8JDGKvfl9BThAeXeaAHaE6?pid=ImgDet&w=2048&h=1358&rs=1",
      title: "Audii A8 2021",
      detail: "Rolls-Royce has always excelled at producing luxury vehicles",
      door: "4dr",
      engine: "1300",
      bid: " Current Bid",
      bidprice: "$ 17000",
      time: "5:46",
      km: "36000",
      wheel: "AWD",
      start:'$ 430'
    },
  ];
  return (
    <div>
      {Data.map((Products) => (
        <div className="watchbody1">
          <Card>
            <div className="watcdisplaylayout">
              {/* -------------image plus icons----------- */}
              <div className="col-sm-4.col-md-4.col-lg-4.col-xl-4 watcfirst">
                <img src={Products.imgsrc} />
                <div className="watciconcontainer">
                    <div className="watciconi">
                      <AiTwotoneEye />
                    </div>
                </div>
              </div>

              {/* --------------product detail----------- */}
              <div className="col-sm-4.col-md-4.col-lg-4.col-xl-4 watcsecond">

                  {/* -----detail --------- */}
                <div className="watcseconddetail">
                  <span className="watctexttitle"> {Products.title}</span>
                  <div className='watcdetailcontainer'>
                  <span className="watctext"> {Products.door}<BsDot/></span>
                  <span className="watctext"> {Products.wheel}<BsDot/></span>
                  <span className="watctext"> {Products.engine}<BsDot/></span>
                  </div>

                  <span className="watctext"> {Products.km} Km</span>
                  
                  </div>
                  {/* --------detail end -----------*/}
                  <div className="watctimelable">
                    <div>
                    <span className='watctext'>< AiOutlineClockCircle/></span>
                      <span className='watctext'>{Products.time}</span> 
                    </div>
                 <div>
                
                      <span className='watctext'>{Products.bidprice}</span> 
                 </div>
                 </div>      
              </div>


            </div>
          </Card>
        </div>
      ))}
    </div>
  );
}

export default Watch;
