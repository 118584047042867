import React from 'react';
import SlideRuler from 'slide-ruler';
 
class SlideRulerPage extends React.Component {
 
  constructor(){
    super();
 
    this.handleValue = this.handleValue.bind(this);
    this._renderSlideRuler = this._renderSlideRuler.bind(this);
  }
 
  componentDidMount(){
    this._renderSlideRuler();
  }
 
  handleValue(value){
     //SlideRuler return value
  }
 
  _renderSlideRuler(){
    return new SlideRuler (
      {
        el: this.refs.slideRuler,
        maxValue: 230,
        minValue: 100,
        currentValue: 180
        // handleValue: handleValue,
        // precision: 1
      }
    );
  }
 
  render(){
    return (
      <div ref='slideRuler'></div>
    )
  }
}
 
export default SlideRulerPage;