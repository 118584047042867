import { CAR_DETAIL_UPDATE,CITY_DETAIL_LIST,CHAKBOX_LIST,ADDRESS_FORM, DOST_PROGRAM} from "../constant/index";

const INITIAL_STATE = {
  loading: true,
  data:[],
  productlist:[],
  dost_loading: false,

  step2complete:false,
  cityAddress:{},
  vehicle_data:{},

  divindex:{},

  addressform:false,
  addressformCreate:false

};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${DOST_PROGRAM}_REQUEST`:
      return { dost_loading: true };
    case `${DOST_PROGRAM}_SUCCESS`:
      // console.log("DOST_PROGRAM ...........DOST_PROGRAM", action.payload)
      return { dost_loading: false };
    case `${DOST_PROGRAM}_FAIL`:
      return { dost_loading: false };
    case `${DOST_PROGRAM}_ERROR`:
      return { dost_loading: false };
    case 'CAR_DETAIL_SETUP':
      // console.log("i am in car update detai-->> ",action.payload.data_vehicle)
      var d = action.payload.data;
       var f_ = {};
    
       if (d.vehcile_details) {
         for (var i = 0; i < d.vehcile_details.length; i++) {
           f_[i] = d.vehcile_details[i].feature.map((item, index) => item.id);
         }
       }
      return { ...state, vehicle_data: action.payload.data_vehicle,divindex:f_ };
    case `${CAR_DETAIL_UPDATE}`:
      // console.log("i am in car update detai-->> ",action.payload.data_vehicle)
      return { ...state, vehicle_data: action.payload.data_vehicle };
    case `${CAR_DETAIL_UPDATE}_REQUEST`:
      return { ...state, loading: true };
    case `${CAR_DETAIL_UPDATE}_SUCCESS`:
      console.log(
        "CAR_DETAIL_UPDATE ...........CAR_DETAIL_UPDATE",
        action.payload
      );
      var f_ = {};
      console.log(
        "vehicle details in moduue0---------------->> ",
        state.vehicle_data
      );
      if (state.vehicle_data.vehcile_details) {
        for (var i = 0; i < state.vehicle_data.vehcile_details.length; i++) {
          f_[i] = state.vehicle_data.vehcile_details[i].feature.map(
            (item, index) => item.id
          );
        }
      }

      // console.log("div setting --> ",f_)
      return {
        ...state,
        data: action.payload,
        loading: false,
        divindex: f_,

        // step2complete:true,
      };
    case `${CAR_DETAIL_UPDATE}_FAIL`:
      return { ...state, loading: false };
    case `${CAR_DETAIL_UPDATE}_ERROR`:
      return { ...state, loading: false };
    //
    // =======================addrss form====================

    case `${ADDRESS_FORM}_REQUEST`:
      return { ...state };
    case `${ADDRESS_FORM}_SUCCESS`:
      // console.log("CAR_DETAIL_UPDATE ...........CAR_DETAIL_UPDATE",action.payload)

      return {
        ...state,
        addressform: true,
        step2complete: true,
        addressformCreate:true,
        loading: false,
      };
    case `${ADDRESS_FORM}_FAIL`:
      return { ...state, loading: false };
    case `${ADDRESS_FORM}_ERROR`:
      return { ...state, loading: false };
    //
    // =======================product sale list====================

    case CITY_DETAIL_LIST:
      // console.log("CAR_DETAIL_UPDATE ...........CAR_DETAIL_UPDATE",action.payload)

      return {
        cityAddress: action.payload.data,
      };
    case CHAKBOX_LIST:
      // console.log("checkbox_list ...........checkbox_list",action.payload," state ====> ",state)
      const f = { ...state.divindex };
      f[action.payload.data.index] = action.payload.data.div;
      // return f
      return {
        ...state,

        divindex: f,
      };
  }




//   switch (action.type) {
//     case `${CAR_DETAIL_UPDATE}`:
//       // console.log("i am in car update detai-->> ",action.payload.data_vehicle)
//       return { ...state, vehicle_data: action.payload.data_vehicle };
//     case `${CAR_DETAIL_UPDATE}_REQUEST`:
//       return { ...state, loading: true };
//     case `${CAR_DETAIL_UPDATE}_SUCCESS`:
//       // console.log("CAR_DETAIL_UPDATE ...........CAR_DETAIL_UPDATE in succcess",action.payload)
//       var f={}
//       // console.log("vehicle details in moduue0---------------->> ",state.vehicle_data)
//       for(var i=0;i<state.vehicle_data.vehcile_details.length;i++){
//         f[i]=state.vehicle_data.vehcile_details[i].feature.map((item,index)=>item.id)
//       }
//       // console.log("div setting --> ",f)
//       return { ...state, 
//         data:action.payload,
//         loading:false,
//         divindex:f
//       };
//     case `${CAR_DETAIL_UPDATE}_FAIL`:
//       return { ...state, loading: false };
//     case `${CAR_DETAIL_UPDATE}_ERROR`:
//       return { ...state, loading: false };
//     //
//   // =======================product sale list====================
  







// }







  return state;
};
