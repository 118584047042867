import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Dropdown from "../Form/Dropdown";
import Form from "../Form";
//
import { connect } from "react-redux";
import Labels from "../../services/apiLabels";
import { POST, GET } from "../../constant/apiMethods";
import { BRANDS } from "../../constant/index";
import { API_ENDPOINT } from "../../services/api";
import { useContext } from "react";
import FeedbackContext from "../../context/cmscontext";
//
const getdata = async (label) => {
  
  const parameters = {
    GET,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await fetch(API_ENDPOINT(label), parameters)
    .then((response) => {
      
      return response.json();
    })
    .then((json) => {
      
      return json;
    });
};
let reduxType_ = "SELL_MY_CAR_STEPS";
//
function CarDetailForm(props) {
  const {
    formWidth,
    title,
    fetchingBrands,
    getbrands,
    brands,
    onSubmit,
    buttonText,
    //
    setbrand,
    setmodel,
    setfuelType,
    setyear,
    setvariant,
    //
    reduxType,
    reducer,
    loading,
    loadingText,
  } = props;

   const { feedback, isLoading } = useContext(FeedbackContext);
   var evaluation_page = feedback.evaluation_page;



  const brand = props[reducer].step1.brand.current;
  const model = props[reducer].step1.model;
  const fuel_type = props[reducer].step1.fuel_type;
  const year = props[reducer].step1.year;
  const variant = props[reducer].step1.variant;
  useEffect(() => {
    reduxType_ = reduxType;
    if (brands.length < 1) getbrands();
  }, []);
  //
  const history = useHistory();
  const onSubmitHandler = () => {
    
    onSubmit();
  };
  // values states
  // defaule state variable
  const defaultState = {
    fetching: false,
    data: [],
    isSelected: false,
    current: {},
  };
  // call a function when brand is selected
  const brandOnSelectHandler = async (item = {}) => {
    // console.log("brandOnSelectHandler============>item",item)
    if (item.id) {
      setbrand({ ...brand, isSelected: true, current: item });
      setmodel({ ...defaultState, fetching: true });
      const data = await getdata(`${Labels.car_models}${item.id}/`);
      if (data.length > 0) {
        setmodel({
          data,
          fetching: false,
          isSelected: false,
          current: {},
        });
      } else {
        setmodel({ ...defaultState, fetching: false });
      }
      
    }
  };
  // call a function when model is selected
  const modelsOnSelectHandler = async (item) => {
    // console.log("modelsOnSelectHandler=======22222=====>item",item)
    if (item.value) {
      setmodel({ ...model, isSelected: true, current: item });
      setfuelType({ ...defaultState, fetching: true });
      const data = await getdata(`${Labels.car_fuel_types}${item.value}/`);
      if (data.length > 0) {
        
        setfuelType({
          data,
          fetching: false,
          isSelected: false,
          current: {},
        });
      } else {
        
        setfuelType({ ...defaultState, fetching: false });
      }
      
    }
  };
  // call a function when model is selected
  const fuelTypeOnSelectHandler = async (item) => {
    // console.log("fuelTypeOnSelectHandler=======33333=====>item",item)
    if (item.value) {
      setfuelType({ ...fuel_type, isSelected: true, current: item });
      setyear({ ...defaultState, fetching: true });
      const data = await getdata(`${Labels.car_years}${item.value}/`);
      if (data.length > 0) {
        
        setyear({
          data,
          fetching: false,
          isSelected: false,
          current: {},
        });
      } else {
        
        setyear({ ...defaultState, fetching: false });
      }
      
    }
  };
  // call a function when year is selected
  const yearsOnSelectHandler = async (item) => {
    // console.log("yearsOnSelectHandler=======44444444444=====>item",item)
    if (item.value) {
      setyear({ ...year, isSelected: true, current: item });
      setvariant({ ...defaultState, fetching: true });
      const data = await getdata(`${Labels.car_variants}${item.value}/`);
      if (data.length > 0) {
        setvariant({
          data,
          fetching: false,
          isSelected: false,
          current: {},
        });
      } else {
        setvariant({ ...defaultState, fetching: false });
    
      }
    }
  };
  //  // call a function when variant is selected
  const variantsOnSelectHandler = async (item) => {
    // console.log("variantsOnSelectHandler=======555555=====>item",item)
    // console.log("c",item)
    if (item.value) {
      setvariant({ ...variant, isSelected: true, current: item });
    }
  };
  return (
    <Form
      width={formWidth}
      loading={loading}
      loadingText="Submitting Interest ..."
      title={title}
      onSubmit={onSubmitHandler}
      buttonText={buttonText}
    >
      {/* brands */}
      <Dropdown
        label="Brand"
        onSelect={brandOnSelectHandler}
        titleKey="brand_name"
        selectedValue={brand}
        loading={fetchingBrands}
        disabled={fetchingBrands || loading}
        data={brands}
      />
      {/* modal */}
      <Dropdown
        label="Model"
        onSelect={modelsOnSelectHandler}
        titleKey="display"
        idKey="value"
        selectedValue={model.current}
        loading={model.fetching}
        disabled={model.fetching || !brand.id || loading}
        data={model.data}
      />
      {/* fuel type */}
      <Dropdown
        label="Fuel Type"
        onSelect={fuelTypeOnSelectHandler}
        titleKey="display"
        idKey="value"
        selectedValue={fuel_type.current}
        loading={fuel_type.fetching}
        disabled={fuel_type.fetching || !model.isSelected || loading}
        data={fuel_type.data}
      />
      {/* year */}
      <Dropdown
        label="Year"
        onSelect={yearsOnSelectHandler}
        titleKey="display"
        idKey="value"
        selectedValue={year.current}
        loading={year.fetching}
        disabled={year.fetching || !fuel_type.isSelected || loading}
        data={year.data}
      />
      {/* variant */}
      <Dropdown
      
        label="Variant"
        onSelect={variantsOnSelectHandler}
        titleKey="display"
        idKey="value"
        selectedValue={variant.current}
        loading={variant.fetching}
        disabled={variant.fetching || !year.isSelected || loading}
        data={variant.data}
      />
    </Form>
  );
}
const mapStateToProps = (state) => ({
  fetchingBrands: state.sellMyCar.fetchingBrands,
  brands: state.sellMyCar.brands,
  //
  showInterest: state.showInterest,
  sellMyCar: state.sellMyCar,
});
const mapDispatchToProps = (dispatch) => ({
  // get product list
  getbrands: (data) => {
    return dispatch({
      type: BRANDS,
      payload: {
        type: BRANDS,
        method: POST,
        label: `${Labels.brands}`,
        hideOverlayLoader: true,
      },
    });
  },
  setbrand: (data) =>
    dispatch({
      type: reduxType_,
      payload: {
        type: "brand",
        data,
      },
    }),
  setmodel: (data) =>
    dispatch({
      type: reduxType_,
      payload: {
        type: "model",
        data,
      },
    }),
  setfuelType: (data) =>
    dispatch({
      type: reduxType_,
      payload: {
        type: "fuel_type",
        data,
      },
    }),
  setyear: (data) =>
    dispatch({
      type: reduxType_,
      payload: {
        type: "year",
        data,
      },
    }),
  setvariant: (data) =>
    dispatch({
      type: reduxType_,
      payload: {
        type: "variant",
        data,
      },
    }),
});
export default connect(mapStateToProps, mapDispatchToProps)(CarDetailForm);
