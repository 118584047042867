import React, { useState, useEffect } from "react";
import "./style.css";
import { ShimmerThumbnail } from "react-shimmer-effects";
import Labels from "../../services/apiLabels";
import { POST, GET } from "../../constant/apiMethods";
import {
  SHOW_INTEREST,
  MY_APPOINMENT,
  MY_APPOINMENT_CLEAR,
} from "../../constant";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import { connect, useSelector } from "react-redux";

import { Carousel } from "3d-react-carousal";
import { MdEmail } from "react-icons/md";
import { FaMapMarkerAlt } from "react-icons/fa";
import Carcard from "../../components/Car_Card/index";
import { useHistory } from "react-router-dom";
import { scrollTop } from "../../utils/window";
import { useContext } from "react";
import FeedbackContext from "../../context/cmscontext";

function Myappointment(props) {
   const { feedback, isLoading } = useContext(FeedbackContext);
  var myAppointment_page = feedback.myAppointment_page;

  const { myappointment, mycardata, loading, myappointment_Clear } = props;
  const history = useHistory();

  const getappointmt = (e) => {
    e.preventDefault();
    // if (postcode && email){
    myappointment({
      // code: "acf54545",
      // email: "abc@gmail.com",

      code: postcode,
      email: email,
    });
    // }
  };

  useEffect(() => {
    // console.log("History------------->>>> ",history)
    if (history.action == "PUSH") {
      myappointment_Clear();
    }
    scrollTop();
  }, []);

  let slides = [
    <img src="https://picsum.photos/800/300/?random" alt="1" />,
    <img src="https://picsum.photos/800/301/?random" alt="2" />,
    <img src="https://picsum.photos/800/302/?random" alt="3" />,
    <img src="https://picsum.photos/800/303/?random" alt="4" />,
    <img src="https://picsum.photos/800/304/?random" alt="5" />,
  ];

  const [email, setemai] = useState("");

  const [postcode, setpostcod] = useState("");

  return (
    <div>
      {/* <div className="mainDiv1_ p-"> */}
      <div className="showInterest_headingCont">
        <div className="showInterest_heading">
          {myAppointment_page.My_Appointment_headin}
        </div>
        <div className="showInterest_description">
          {myAppointment_page.My_Appointment_des}
        </div>
      </div>
      {/* </div> */}
      <div className="container d-flex justify-content-center flex-column align-items-md-center mt-5">
        <div className="mainDiv">
          <form onSubmit={getappointmt} >
            <h3 className="text-center mt-3">
              {myAppointment_page.Please_Enter_Your_Detail}
            </h3>

            <div className="inpdiva  ">
              <div className="inp1  py-2">
                <span className="ml-2">
                  <MdEmail size={21} color="var(--primary)" />
                </span>

                <input
                  required
                  className="ml-2 inptext"
                  placeholder={myAppointment_page.Emil_placeholde}
                  onChange={(e) => setemai(e.target.value)}
                />
              </div>

              <div className="inp1 mt-2 py-2">
                <span className="ml-2">
                  <FaMapMarkerAlt size={21} color="var(--primary)" />
                </span>

                <input
                  required
                  className="ml-2 inptext"
                  placeholder={myAppointment_page.post_code_placeholde}
                  onChange={(e) => setpostcod(e.target.value)}
                />
              </div>
            </div>

            <div className="inpdiva my-3">
              <button className="btn12" name="CTAButton">
                {myAppointment_page.Get_Appointments_button_tex}
              </button>
            </div>
          </form>
        </div>
      </div>

      {loading ? (
        <div className="d-flex justify-content-center align-content-center my-5">
          <div className="row mdiv1shimer my-5  ">
            <div className="col-lg-4 col-md- col-sm-4 col-xs-12  pt-3 pl-3 ">
              <div className="shimerpic">
                <ShimmerThumbnail rounded />
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12   pt-5 ">
              <ShimmerThumbnail height={30} width={200} rounded />
              <ShimmerThumbnail height={30} width={300} rounded />

              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <span>
                    <ShimmerThumbnail height={30} width={300} rounded />
                    <ShimmerThumbnail height={30} width={300} rounded />
                  </span>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <span>
                    <ShimmerThumbnail height={30} width={200} rounded />
                    <ShimmerThumbnail height={30} width={200} rounded />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : mycardata.length > 0 ? (
        React.Children.toArray(
          mycardata.map(function (item, index) {
            return (
              <div className="Card_Div">
                <Carcard
                  item={item}
                  index={index}
                  mycar={mycardata}
                  onClick={() => {
                    history.push({
                      pathname: "/myappointmentdetail",
                      state: { index },
                    });
                    scrollTop();
                  }}
                />
              </div>
            );
          })
        )
      ) : (
        <div></div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  userId: state.auth.id,
  data: state.myInterest.data,
  mycardata: state.myAppointment.data,
  loading: state.myAppointment.loading,
});
const mapDispatchToProps = (dispatch) => ({
  myappointment: (data) =>
    dispatch({
      type: MY_APPOINMENT,
      payload: {
        type: MY_APPOINMENT,

        label: Labels.myappointment,
        method: POST,
        data,
        hideOverlayLoader: true,
      },
    }),
  myappointment_Clear: () =>
    dispatch({
      type: MY_APPOINMENT_CLEAR,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Myappointment);
