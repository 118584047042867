import React from "react";
import "./style.css";
import ScaleLoader from "react-spinners/ScaleLoader";
//
export default function (props) {
  const {
    loading,
    loadingText,
    title,
    onSubmit,
    buttonText,
    children,
    width,
    className,
    hideButton,
    hideButtonOnPrint,
    buttonName,
    buttonClassName,
    childClassName,
    onBack,
  } = props;
  const getCarPriceHandler = () => {
    
    onSubmit();
  };
  return (
    <div
      className={`form_main_wrapper ${
        width === "fluid"
          ? "form_main_wrapper_width_fluid "
          : "form_main_wrapper_width"
      } cardShadow ${className || ""}`}
      style={{ margin: width === "fluid" ? "auto" : "1rem" }}
    >
      <div className="form_header">
        {title && <span className="form_header_text">{title}</span>}
      </div>
      <div className={childClassName}>{children}</div>
      <div className="dropdown_wrapper d-flex align-items-center">
        {onBack && (
          <button
            style={{
              padding: "0.5rem",
              fontSize: "1rem",
              fontWeight: "bold",
              textTransform: "uppercase",
              letterSpacing: "0.1rem",
            }}
            onClick={onBack}
          >
            Back
          </button>
        )}
        {hideButton ? null : (
          <button
            className={`${
              hideButtonOnPrint && "hideButtonOnPrint"
            } ${buttonClassName}`}
            onClick={!loading ? getCarPriceHandler : () => {}}
            name={buttonName || "CTAButton"}
          >
            {loading ? loadingText || "Loading..." : buttonText || "Submit"}
          </button>
        )}
      </div>
    </div>
  );
}
