import React, { useEffect, createRef, useRef } from "react";
import "./styles.css";
export default function (props) {
  const { disabled, onEnd } = props;
  let inputRef = useRef("");
  let inputRange = inputRef.current;
  // useEffect(() => {
  //   const callHandler = handler();
  //   return () => {};
  // }, [inputRange.current]);
  // const handler = () => {
  if (inputRange) {
    inputRange.ontouchend = function () {
      var theRange = this.value;
      if (theRange == 100) {
        unlock();
      } else {
        document.init = setInterval(function () {
          if (inputRange.value != 0) {
            inputRange.value = theRange--;
          }
        }, 1);
      }
    };
    inputRange.ontouchstart = function () {
      clearInterval(document.init);
    };

    inputRange.onmouseup = function () {
      var theRange = this.value;
      if (theRange == 100) {
        unlock();
      } else {
        document.init = setInterval(function () {
          if (inputRange.value != 0) {
            inputRange.value = theRange--;
          }
        }, 1);
      }
    };

    inputRange.onmousedown = function () {
      clearInterval(document.init);
    };

    async function unlock() {
      const onEndFun = await onEnd();
      if (onEndFun) {
        inputRange.value = 0;
        
      }
    }
  }
  // };
  return (
    <div>
      <input
        disabled={disabled}
        ref={inputRef}
        type="range"
        className="slideToUnlock"
        defaultValue={0}
        max={100}
      />
    </div>
  );
}

// import React, { createRef, useRef } from "react";
// import "./styles.css";
// export default () => {
//   // var inputRange = document.getElementById("pullee"),
//   let inputRef = useRef("");
//   let inputRange = inputRef.current,
//     maxValue = 150, // the higher the smoother when dragging
//     speed = 12, // thanks to @pixelass for this
//     currValue,
//     rafID;
//   if (inputRange) {
//     // set min/max value
//     inputRange.min = 0;
//     inputRange.max = maxValue;

//     // listen for unlock
//     function unlockStartHandler() {
//       // clear raf if trying again
//       window.cancelAnimationFrame(rafID);

//       // set to desired value
//       currValue = +this.value;
//     }

//     function unlockEndHandler() {
//       // store current value
//       currValue = +this.value;

//       // determine if we have reached success or not
//       if (currValue >= maxValue) {
//         successHandler();
//       } else {
//         rafID = window.requestAnimationFrame(animateHandler);
//       }
//     }

//     // handle range animation
//     function animateHandler() {
//       // update input range
//       inputRange.value = currValue;

//       // determine if we need to continue
//       if (currValue > -1) {
//         window.requestAnimationFrame(animateHandler);
//       }

//       // decrement value
//       currValue = currValue - speed;
//     }

//     // handle successful unlock
//     function successHandler() {
//       alert("unlocked");

//       // reset input range
//       inputRange.value = 0;
//     }

//     // bind events
//     inputRange.addEventListener("mousedown", unlockStartHandler, false);
//     inputRange.addEventListener("mousestart", unlockStartHandler, false);
//     inputRange.addEventListener("mouseup", unlockEndHandler, false);
//     inputRange.addEventListener("touchend", unlockEndHandler, false);
//   }
//   return (
//     <div className="center-xy">
//       <input
//         ref={inputRef}
//         type="range"
//         defaultValue={0}
//         max={maxValue}
//         className="pullee"
//       />
//     </div>
//   );
// };
