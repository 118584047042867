import React from "react";
import "./style.css";
// import HashLoader from "react-spinners/HashLoader";
import ScaleLoader from "react-spinners/ScaleLoader";
// importing redux related things
import { connect } from "react-redux";
import { TOASTER_HIDE } from "../../constant";

function OverLayLoader(props) {
  const { show, alwaysShow } = props;
  return (
    <>
      <div
        className="OverLayLoaderCont"
        style={{ display: show || alwaysShow ? "flex" : "none" }}
      >
        <ScaleLoader
          size={150}
          сolor="var(--secondary)"
          speedMultiplier={0.6}
        />
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({
  show: state.loader.show,
});
const mapDispatchToProps = (dispatch) => ({
  // login action
  hideToaster: (data) =>
    dispatch({
      type: TOASTER_HIDE,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(OverLayLoader);
