import React from "react";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";

function WishListButton() {
  const [state, setState] = React.useState(false);
  return (
    <button onClick={() => setState(!state)} className="wish_list_main_cont">
      {state ? (
        <AiFillHeart className="wish_list_icon" />
      ) : (
        <AiOutlineHeart className="wish_list_icon" />
      )}
    </button>
  );
}

export default WishListButton;
