import { React, useState, useEffect } from "react";
import "./stlye.css";
import Labels from "../../services/apiLabels";
import { POST, GET } from "../../constant/apiMethods";
import { SHOW_INTEREST, CREATE_CONTACT_US } from "../../constant";
import { MdSettingsCell } from "react-icons/md";
import mobile from "../../assets/svg/mobile-svgrepo-com.svg";
import chat from "../../assets/svg/chat-sms-svgrepo-com.svg";
import ques from "../../assets/svg/question-svgrepo-com.svg";
import Footer from "../../components/Footer";
import { scrollTop } from "../../utils/window";
import map_pic from "../../assets/images/map.PNG";
import Map from "./map";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useContext } from "react";
import FeedbackContext from "../../context/cmscontext";
function ContactUs({ createContactUs }) {
   const { feedback, isLoading } = useContext(FeedbackContext);
  var contact_us = feedback.contact_us;

  useEffect(() => {
    scrollTop();
  }, []);

  const [email, setemail] = useState("");
  const [fistname, setfistname] = useState("");
  const [lastname, setlastname] = useState("");
  const [coment, setcoment] = useState("");

  const ContactUs = () => {

    if (email) {
      createContactUs({
        email: email,
        fistname: fistname,
        lastname: lastname,
        coment: coment,
      });
    }
   

    setTimeout(() => {
      setemail("");
      setfistname("");
      setlastname("");
      setcoment("");
    }, 3000);
  };

  return (
    <>
      {}
      <div className="showInterest_headingCont ">
        <div className="showInterest_heading">{contact_us.contact_text}</div>
        <div className="showInterest_description">
          {contact_us.contactus_description}
        </div>
      </div>
      <div className="container-fluied ">
        <div className="card_wrap_contactus_">
          <div className="row card_row_ p-5">
            <div className="col-lg-3 col-md-3 col-sm-12    mb-sm-5 mb-4 mb-sm-0 mb-lg-0  ">
              <div className="card_contact p-3">
                <div className="text-center pt-4">
                  <img
                    className="mobile_svga"
                    src={contact_us.sell_image}
                    alt="mobile"
                  />
                </div>
                <h3 className="text-center  sell_car">
                  {contact_us.sell_text}
                </h3>
                <p className="text-center text_tow">
                  {contact_us.sell_description}
                </p>
              </div>

              <button name="CTAButton" className="btn_contact">
                {" "}
                {contact_us.sell_bottomtab}
              </button>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12  mb-sm-5   mb-4 mb-sm-0 mb-lg-0 ">
              <div className="card_contact p-3">
                <div className="text-center pt-4">
                  <img
                    className="mobile_svga"
                    src={contact_us.questions_image}
                    alt="question"
                  />
                </div>
                <h3 className="text-center  sell_car">
                  {" "}
                  {contact_us.questions_text}
                </h3>
                <p className="text-center text_tow">
                  {contact_us.questions_description}
                </p>
              </div>

              <button name="CTAButton" className=" btn_contact">
                <Link to="/faqs" className="text-white">
                  {contact_us.questions_bottomtab}
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row p-5">
          <div className="col-lg-5 col-md-5 col-sm-12">
            <h2>{contact_us.sellanywheel_heading}</h2>
            <p>{contact_us.sellanywheel_description}</p>
            <h3>{contact_us.address_text}</h3>
            <p>{contact_us.address_description}</p>
            <h3>{contact_us.phoneno_text}</h3>
            <p>{contact_us.phone_no}</p>
          </div>
          <div className="col-lg-7 col-md-7 col-sm-12 mt-5">
       
       <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9532.389343043818!2d-6.3475899!3d53.3236105!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48670de6dc9f8a97%3A0xc4718511220353e8!2sJK%20AUTOZONE%20LTD!5e0!3m2!1sen!2s!4v1675256270496!5m2!1sen!2s" 
              width="100%"
              height="450"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              title="Map"
            ></iframe>
          </div>
        </div>
      </div>

      <div className="containerfluid contact_wrap p-5">
        <h2 className="text-center">{contact_us.contactus_form}</h2>
        <div className="row text-center d-flex justify-content-center ">
          <div className="col-lg-4 col-md-5 col-sm-12 ">
            <div className="inp_wrap my-3">
              <input
                value={fistname}
                onChange={(e) => {
                  setfistname(e.target.value);
                }}
                className="inp_contact"
                placeholder={contact_us.firstname_placeholder}
              ></input>
            </div>
            <div className="inp_wrap">
              <input
                value={lastname}
                onChange={(e) => {
                  setlastname(e.target.value);
                }}
                className="inp_contact"
                placeholder={contact_us.lastname_placeholder}
              ></input>
            </div>
            <div className="inp_wrap my-3">
              <input
                type="email"
                onChange={(e) => {
                  setemail(e.target.value);
                }}
                value={email}
                className="inp_contact"
                placeholder={contact_us.email_placeholder}
              ></input>
            </div>
            <div className="inp_wrap_coment ">
              <input
                value={coment}
                onChange={(e) => {
                  setcoment(e.target.value);
                }}
                className="inp_contact "
                placeholder={contact_us.leave_placeholder}
              ></input>
            </div>

            <button
              className=" btn_contact__ my-3"
              name="CTAButton"
              onClick={ContactUs}
            >
              {contact_us.contactus_submit}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  createContactUs: (data) =>
    dispatch({
      type: CREATE_CONTACT_US,
      payload: {
        type: CREATE_CONTACT_US,

        label: Labels.createContactUs,
        method: POST,
        data,
        hideOverlayLoader: true,
      },
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
